export function getItemCosts(job) {
	let { getItemCostsRequest, getItemCostsError, getItemCostsResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_item_costs',
		this.createBundle(
			'api/get_item_costs',
			{ job },
			this.GET,
			getItemCostsRequest,
			getItemCostsResult,
			getItemCostsError
		)
	);
}
