export function getCostList(job) {
	let { getCostListRequest, getCostListError, getCostListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_cost_list',
		this.createBundle(
			'api/get_cost_list',
			{ job },
			this.GET,
			getCostListRequest,
			getCostListResult,
			getCostListError
		)
	);
}
