export function getBabypressaData(start, end) {
	let { getBabypressaDataRequest, getBabypressaDataError, getBabypressaDataResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_babypressa_data',
		this.createBundle(
			'api/get_babypressa_data',
			{ start, end },
			this.POST,
			getBabypressaDataRequest,
			getBabypressaDataResult,
			getBabypressaDataError
		)
	);
}
