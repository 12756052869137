export function getBabypressaDataFilter(data) {
	let { getBabypressaDataFilterRequest, getBabypressaDataFilterError, getBabypressaDataFilterResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_babypressa_data_filter',
		this.createBundle(
			'api/get_babypressa_data_filter',
			{ search: data },
			this.GET,
			getBabypressaDataFilterRequest,
			getBabypressaDataFilterResult,
			getBabypressaDataFilterError
		)
	);
}
