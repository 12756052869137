export function createWorker(data) {
	let { createWorkerRequest, createWorkerError, createWorkerResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/create_worker',
		this.createBundle(
			'api/create_worker',
			{ worker: data },
			this.POST,
			createWorkerRequest,
			createWorkerResult,
			createWorkerError
		)
	);
}
