export function getRecentMachineList(search, workerId, isEmployee) {
	let { getRecentMachineListRequest, getRecentMachineListError, getRecentMachineListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_recent_machine_list',
		this.createBundle(
			'api/get_recent_machine_list',
			{ search, workerId },
			this.GET,
			getRecentMachineListRequest,
			getRecentMachineListResult,
			getRecentMachineListError,
			isEmployee
		)
	);
}
