import React, { Component } from 'react'
import Radium from 'radium'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'

import { Icon, Button, Image, Card, Table, Input } from 'semantic-ui-react'
import View from 'lib/View'
import { timeString, floatTimeToString } from '../lib/util'
import { Confirmation, ImageDefault } from './'

import Moment from 'react-moment'
import moment from 'moment-with-locales-es6'

import { route_from, routes } from 'lib/routes'
const { app, employees, swa300dataEdit } = routes

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

class SWA300Data extends Component {
	constructor(props) {
		super(props)
		let { onCostChange, swa300data } = props

		this.state = {
			colors: [
				'red',
				'orange',
				'yellow',
				'olive',
				'green',
				'teal',
				'blue',
				'violet',
				'purple',
				'pink',
				'brown',
				'grey',
				'black'
			],
			...swa300data
		}

		if (onCostChange && swa300data) {
			onCostChange(this.totalCost(), swa300data.idImpiegato)
		}
	}

	totalCost() {
		let { prezzoOrarioImpiegato, oreOperaio, minutiOperaio } = this.state
		let total = (oreOperaio + minutiOperaio / 60) * prezzoOrarioImpiegato
		if (isNaN(total)) {
			total = 0
		}
		return total
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { onCostChange } = this.props
		let { idImpiegato, prezzoOrarioImpiegato } = this.state
		if (prezzoOrarioImpiegato !== prevState.prezzoOrarioImpiegato) {
			if (onCostChange) {
				onCostChange(this.totalCost(), idImpiegato)
			}
		}
	}

	renderBalanceFooter() {
		let { hourtotal, total } = this.props

		let averageHourlyCost = Math.round(total / hourtotal * 100) / 100
		if (isNaN(averageHourlyCost)) {
			averageHourlyCost = '--'
		}

		return (
			<Table.Row>
				<Table.Cell width='5'>Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
				<Table.Cell style={{ textAlign: 'right' }} width='4'>
					Totale Ore:
				</Table.Cell>
				<Table.Cell width='4'>{floatTimeToString(hourtotal)}</Table.Cell>
				<Table.Cell width='1'>Totale:</Table.Cell>
				<Table.Cell width='2'>{total}€</Table.Cell>
			</Table.Row>
		)
	}

	renderBalanceHeader() {
		return (
			<Table.Header fullWidth>
				<Table.Row>
					<Table.HeaderCell width='5'>Nome Impiegato</Table.HeaderCell>
					<Table.HeaderCell width='4'>Prezzo €/h</Table.HeaderCell>
					<Table.HeaderCell width='4'>Ore di lavoro</Table.HeaderCell>
					<Table.HeaderCell width='1' />
					<Table.HeaderCell width='2'>Totale</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
		)
	}

	renderForBalance() {
		let { nomeImpiegato, prezzoOrarioImpiegato, oreOperaio, minutiOperaio } = this.state

		return (
			<Table.Row>
				<Table.Cell>{nomeImpiegato}</Table.Cell>
				<Table.Cell>
					<span className='no-print'>
						<Input
							type='number'
							step='0.01'
							value={prezzoOrarioImpiegato}
							onChange={(e, data) => {
								let value = data.value
								if (value < 0) value = 0
								this.setState({
									prezzoOrarioImpiegato: value
								})
							}}
						/>€/h
					</span>
					<span className='only-print'>{prezzoOrarioImpiegato}€/h</span>
				</Table.Cell>
				<Table.Cell>{timeString(oreOperaio, minutiOperaio)}</Table.Cell>
				<Table.Cell>=</Table.Cell>
				<Table.Cell>{this.totalCost()}€</Table.Cell>
			</Table.Row>
		)
	}

	renderTableFooter() {
		let { isBalance } = this.props
		if (isBalance) return this.renderBalanceFooter()
		else return null
	}

	/*w
a300 data:  {
	power_main: 24,
	power_second: 0,
	power_third: 0,
	pulse_type: 0,
	pulse_duration_main: 69,
	pulse_duration_second: 0,
	pulse_duration_third: 0,
	pulse_repetition: 17,
	spot_diameter: undefined,
	current_recipe_number: 0,
	recipe_number_to_save: 0,
	max_frequency: 145,
	laser_in_alarm: 0,
	delayed_mode_or_first_pulse_suppress: 8
}
*/
	renderTableHeader() {
		let { isBalance } = this.props
		if (isBalance) {
			return this.renderBalanceHeader()
		} else {
			return (
				<Table.Header fullWidth>
					<Table.Row>
						<Table.HeaderCell>Data</Table.HeaderCell>
						<Table.HeaderCell>Ora</Table.HeaderCell>
						<Table.HeaderCell>Primo impulso</Table.HeaderCell>
						<Table.HeaderCell>Secondo impulso</Table.HeaderCell>
						<Table.HeaderCell>Terzo impulso</Table.HeaderCell>
						<Table.HeaderCell>Tipo impulso</Table.HeaderCell>
						<Table.HeaderCell>Durata 1</Table.HeaderCell>
						<Table.HeaderCell>Durata 2</Table.HeaderCell>
						<Table.HeaderCell>Durata 3</Table.HeaderCell>
						<Table.HeaderCell>Ripetizioni</Table.HeaderCell>
						<Table.HeaderCell>Diametro</Table.HeaderCell>
						<Table.HeaderCell>Frequenza massima</Table.HeaderCell>
						<Table.HeaderCell>Allarme</Table.HeaderCell>
						<Table.HeaderCell>Soppressione primo impulso</Table.HeaderCell>
						<Table.HeaderCell>Programma</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
			)
		}
	}

	renderForTable() {
		let { isBalance } = this.props
		if (isBalance) {
			return this.renderForBalance()
		} else {
			let {
				timestamp,
				power_main,
				power_second,
				power_third,
				pulse_type,
				pulse_duration_main,
				pulse_duration_second,
				pulse_duration_third,
				pulse_repetition,
				spot_diameter,
				current_recipe_number,
				recipe_number_to_save,
				max_frequency,
				laser_in_alarm,
				delayed_mode_or_first_pulse_suppress
			} = this.props.swa300data
			return (
				<Table.Row>
					<Table.Cell>{moment(timestamp).format('DD/MM/YYYY')}</Table.Cell>
					<Table.Cell>{moment(timestamp).format('HH:mm:ss')}</Table.Cell>
					<Table.Cell>{power_main}</Table.Cell>
					<Table.Cell>{power_second}</Table.Cell>
					<Table.Cell>{power_third}</Table.Cell>
					<Table.Cell>{pulse_type}</Table.Cell>
					<Table.Cell>{pulse_duration_main}</Table.Cell>
					<Table.Cell>{pulse_duration_second}</Table.Cell>
					<Table.Cell>{pulse_duration_third}</Table.Cell>
					<Table.Cell>{pulse_repetition}</Table.Cell>
					<Table.Cell>{spot_diameter}</Table.Cell>
					<Table.Cell>{max_frequency}</Table.Cell>
					<Table.Cell>{laser_in_alarm}</Table.Cell>
					<Table.Cell>{delayed_mode_or_first_pulse_suppress}</Table.Cell>
					<Table.Cell>{current_recipe_number}</Table.Cell>
				</Table.Row>
			)
		}
	}

	openRemoveDialog() {
		this.setState({ deleting: true })
	}

	remove() {
		let { network, swa300data } = this.props

		network.deleteSWA300Data(swa300data)

		this.setState({ deleting: false })
	}

	render() {
		let { type, role } = this.props
		let {
			deleting,
			idImpiegato,
			nomeImpiegato,
			pin,
			immagineImpiegato,
			prezzoOrarioImpiegato,
			oreOperaio,
			minutiOperaio
		} = this.state
		let extraprops = {
			fluid: type === 'full' ? true : false
		}

		let col = parseInt(Math.random() * this.state.colors.length)

		switch (type) {
			case 'footer':
				return this.renderTableFooter()
			case 'header':
				return this.renderTableHeader()
			case 'table':
				return this.renderForTable()
			default:
				return (
					<View fullw column between>
						<Card style={styles.jobCard} {...extraprops} color={this.state.colors[col]}>
							<Confirmation
								message='Vuoi davvero eliminare questo impiegato?'
								showModal={deleting}
								onConfirm={() => {
									this.remove()
								}}
								onCancel={() => {
									this.setState({ deleting: false })
								}}
							/>
							<Card.Content style={styles.jobContent}>
								<View fullw row>
									<div style={styles.imageContainer}>
										<ImageDefault
											fromRoot
											src={immagineImpiegato}
											ui={false}
											style={styles.jobImage}
										/>
									</div>
									<View fullw column>
										<span style={styles.header}>{nomeImpiegato}</span>
										{(!role || role === 'admin') && (
											<Card.Description>
												Prezzo orario: {prezzoOrarioImpiegato}€/h
											</Card.Description>
										)}
										<Card.Meta style={{ textTransform: 'capitalize' }}>{pin}</Card.Meta>
									</View>
								</View>
							</Card.Content>
							<Card.Content extra>
								<Button
									color='blue'
									onClick={() => {
										this.props.history.push(
											route_from(app, employees, swa300dataEdit, `${idImpiegato}`)
										)
									}}
								>
									<Icon name='edit' />
									Modifica
								</Button>
								<Button
									color='red'
									onClick={() => {
										this.openRemoveDialog()
									}}
								>
									<Icon name='delete' />
									Elimina
								</Button>
							</Card.Content>
						</Card>
					</View>
				)
		}
	}
}

const styles = {
	header: {
		fontSize: '40pt',
		lineHeight: 'normal'
	},
	imageContainer: {
		width: 200,
		height: 150,
		marginRight: 28
	},
	jobImage: {
		width: 200,
		height: 150,
		objectFit: 'contain'
	},
	jobContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	jobCard: {
		marginTop: 8,
		marginBottom: 8
	}
}

const mapStateToProps = (state, ownProps) => {
	let { login } = state
	let ruolo = null
	if (login && login.data && login.data.user) ruolo = login.data.user.ruolo

	return { role: ruolo }
}

export default withRouter(connect(mapStateToProps)(Radium(connectNetwork(SWA300Data))))
