export function getMachineTypeList(data) {
	let { getMachineTypeListRequest, getMachineTypeListError, getMachineTypeListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_machine_type_list',
		this.createBundle(
			'api/get_machine_type_list',
			{ data: data },
			this.GET,
			getMachineTypeListRequest,
			getMachineTypeListResult,
			getMachineTypeListError
		)
	);
}
