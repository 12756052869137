import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { Segment, Icon, Header, Label } from 'semantic-ui-react';
import {} from 'containers';
import moment from 'moment-with-locales-es6';

moment.locale('it');

class Empty extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let { type } = this.props;

		switch (type) {
			case 'small':
				return (
					<Segment placeholder style={styles.small}>
						<span style={styles.smallHeader}>
							Nessun elemento da visualizzare
						</span>
					</Segment>
				);
			case 'ultrasmall':
				return (
					<Segment style={{ padding: 4, margin: 0 }} placeholder style={styles.compact}>
						<span style={styles.compactHeader}>
							Nessun elemento da visualizzare
						</span>
					</Segment>
				);
			case 'compact':
				return null;
			default:
				return (
					<Segment placeholder>
						<Header icon>
							<Icon name="dont" />
							Nessun elemento da visualizzare
						</Header>
					</Segment>
				);
		}
	}
}

const styles = {
	small: {
		minHeight: 60
	},
	compact: {
		minHeight: 30
	},
	compactHeader: {
		marginBottom: 0,
		fontSize: '11pt',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	smallHeader: {
		marginBottom: 0,
		fontSize: '18pt',
		fontWeight: 'bold',
		textAlign: 'center'
	}
};

const mapStateToProps = (state, ownProps) => {
	return {};
};

export default connect(mapStateToProps)(Radium(connectNetwork(Empty)));
