import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { Form, Input, Select, Button, Message, TextArea } from 'semantic-ui-react';

import View from 'lib/View';

import { Job, Empty, PickImage } from '../components';
import { rainbow } from '../lib/util';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import loadingImage from '../assets/loadingParagraph.png';

class WorkerEditor extends Component {
	constructor(props) {
		super(props);
		let id = props.id;
		let { worker } = this.props.match.params;

		if(!id) {
			id = worker;
		}

		this.state = {
			idImpiegato: id,
			pin: '',
			nomeImpiegato: '',
			prezzoOrarioImpiegato: 0.0,
			oreGiornaliere: 8,
			immagineImpiegato: ''
		};
	}

	componentDidMount() {
		let { idImpiegato } = this.state;

		if(idImpiegato) {
			this.state.network.getWorker(idImpiegato);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Maybe need this if it stores state between different edits (it shouldn't)
		if (this.props.creating !== prevProps.creating) {
			if (!this.props.creating) {
				console.log(
					'Create result is: ',
					this.props.createResult,
					this.props.createStatus,
					this.props.createStatus.success ? 'Success!' : 'Failure!'
				);
				if (this.props.createStatus.success) {
					// Navigate back, success!
					this.setState({
						status: 'success'
					});
					this.props.history.goBack();
				} else {
					this.setState({
						status: 'error'
					});
				}
			}
		}

		let { getWorker } = this.props;
		if (getWorker && getWorker.fetching !== prevProps.getWorker.fetching && !getWorker.fetching) {
			if (getWorker.status.success) {
				let worker = getWorker.data.data;
				console.log("Worker editor setting state with", worker);
				this.setState(worker);
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return nextProps;
	}

	save() {
		// TODO value checking
		let { idImpiegato, pin, nomeImpiegato, prezzoOrarioImpiegato, oreGiornaliere, immagineImpiegato } = this.state;

		if (nomeImpiegato === '' || pin === '') {
			this.setState({
				status: 'error'
			});
			return;
		}

		this.props.network.createWorker({
			idImpiegato,
			pin,
			nomeImpiegato,
			prezzoOrarioImpiegato,
			oreGiornaliere,
			immagineImpiegato
		});
	}

	handleInput(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		console.log('Handling input', name, target, event);

		this.setState({
			[name]: value
		});
	}

	render() {
		let {
			creating,
			pin,
			nomeImpiegato,
			prezzoOrarioImpiegato,
			oreGiornaliere,
			immagineImpiegato
		} = this.state;

		console.log('WorkerEditor state:', this.state);
		return (
			<View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
				<Form
					loading={creating}
					success={this.state.status === 'success'}
					error={this.state.status === 'error'}
					warning={this.state.status === 'warning'}
				>
					<Form.Group widths="equal">
						<Form.Field
							id="form-input-control-employee-name"
							control={Input}
							label="Nome Impiegato"
							name="nomeImpiegato"
							value={nomeImpiegato}
							onChange={(e) => this.handleInput(e)}
							placeholder="Giorgio"
						/>
						<Form.Field
							id="form-input-control-employee-pin"
							control={Input}
							label="Pin di accesso"
							placeholder="0000"
							name="pin"
							value={pin}
							onChange={(e) => this.handleInput(e)}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							id="form-input-control-employee-cost"
							control={Input}
							type="number"
							label="Prezzo orario"
							placeholder="0.0"
							step="0.1"
							name="prezzoOrarioImpiegato"
							value={prezzoOrarioImpiegato}
							onChange={(e) => this.handleInput(e)}
						/>
						<Form.Field
							id="form-input-control-employee-hours"
							control={Input}
							type="number"
							label="Ore giornaliere"
							placeholder="8"
							name="oreGiornaliere"
							value={oreGiornaliere}
							onChange={(e) => this.handleInput(e)}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							id="form-textarea-control-employee-image"
							control={Input}
							label="Immagine"
							placeholder="Immagine"
							name="immagineImpiegato"
							value={immagineImpiegato}
							disabled
							onChange={(e) => this.handleInput(e)}
						/>
						<PickImage
							onChange={(image) => {
								this.setState({ immagineImpiegato: image });
							}}
							value={this.state.immagineImpiegato}
						/>
					</Form.Group>
					<Form.Field
						id="form-button-control-save"
						control={Button}
						content="Salva"
						onClick={() => {
							this.save();
						}}
					/>
					<Message success header="Form Completed" content="Lavoratore inserito con successo!" />
					<Message error header="Errore" content="Errore del server, riprova piu' tardi." />
					<Message warning header="Errore inserimento" list={[ 'Ricontrolla i dati inseriti' ]} />
				</Form>
			</View>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let {
		createWorker: { data: createResult, fetching: creating, status: createStatus },
		getWorker
	} = state;

	return {
		createResult,
		creating,
		createStatus,
		getWorker
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(WorkerEditor))));
