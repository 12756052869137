export function switchJob(data) {
	let { switchJobRequest, switchJobError, switchJobResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/update_job',
		this.createBundle(
			'api/update_job',
			{ job: data },
			this.POST,
			switchJobRequest,
			switchJobResult,
			switchJobError
		)
	);
}
