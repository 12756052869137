export function deleteItem(data) {
	let { deleteItemRequest, deleteItemError, deleteItemResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/delete_item',
		this.createBundle(
			'api/delete_item',
			{ item: data },
			this.POST,
			deleteItemRequest,
			deleteItemResult,
			deleteItemError
		)
	);
}
