import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { connectNetwork } from 'lib/NetworkProvider';
import { route_from, routes } from 'lib/routes';

import { Steps, Calendar } from '../components';
import { Works } from './';

import View from 'lib/View';
import { Button, Form } from 'semantic-ui-react';
import { TOOLBAR_ACTIONS } from '../actions/toolbar';

const { vfconsole, app, employee, insertion, jobs } = routes;

class CalendarView extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidUpdate() {}

	componentDidMount() {
	}

	render() {
		let { workday, worker } = this.props;
		
		return (
			<View row fullh fullh>
				<Calendar worker={worker} />
			</View>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch)
	};
};

const mapStateToProps = (state) => {
	let { workday: { data: { date: workday } }, loginEmployee: { data: { user: worker } } } = state;

	return {
		workday,
		worker
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(CalendarView))));

const styles = {
	work_container: {
		borderRight: '1px solid grey',
		width: '30%',
		minWidth: '30%',
		maxWidth: '30%'
	}
};
