import { TOOLBAR_TYPES } from '../actions/toolbar';
import reducer from './requests';
const Reducer = (
	state = {
		fetching: false,
		status: {
			success: false,
			error: undefined
		},
		data: []
	},
	action
) => {
	let requestReducer = reducer(TOOLBAR_TYPES)(state, action);
	switch (action.type) {
		case TOOLBAR_TYPES.request: {
			let { result } = action;
			if (result === undefined) result = {};

			return {
				...state,
				request_data: result
			};
		}
		case TOOLBAR_TYPES.result: {
			let { result } = action;
			return {
				...state,
				data: result
			};
		}
		case TOOLBAR_TYPES.error: {
			let { result } = action;
			if (result === undefined) result = {};

			return {
				...state,
				data: result
			};
		}
		default:
			return state;
	}
};
export default Reducer;
