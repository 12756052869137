import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { ImageDefault, Confirmation } from './';
import { Button, Icon, Card, Table, Input } from 'semantic-ui-react';
import View from 'lib/View';
import { timeString, floatTimeToString } from '../lib/util';

import {} from 'containers';
import Moment from 'react-moment';
import moment from 'moment-with-locales-es6';

import { route_from, routes } from 'lib/routes';
const { app, items, itemEdit } = routes;

moment.locale('it');
Moment.globalMoment = moment;
Moment.globalLocale = 'it';

class Item extends Component {
	constructor(props) {
		super(props);

		let { onCostChange, item } = props;

		this.state = {
			colors: [
				'red',
				'orange',
				'yellow',
				'olive',
				'green',
				'teal',
				'blue',
				'violet',
				'purple',
				'pink',
				'brown',
				'grey',
				'black'
			],
			...item
		};

		if (onCostChange && item) {
			onCostChange(this.totalCost(), item.OPERATIONID);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { onCostChange } = this.props;
		let { idCosto, prezzoCosto } = this.state;
		if (prezzoCosto !== prevState.prezzoCosto) {
			if (onCostChange) {
				onCostChange(prezzoCosto, idCosto);
			}
		}
	}

	openRemoveDialog() {
		this.setState({ deleting: true });
	}

	remove() {
		let { network, item } = this.props;

		network.deleteItem(item);

		this.setState({ deleting: false });
	}

	totalCost() {
		let { cost, QTY, PACKETSIZE } = this.state;
		return cost * QTY;
	}

	renderBalanceFooter() {
		let { total, grouped } = this.props;

		if (grouped) {
			return (
				<Table.Row>
					<Table.Cell width="5" />
					<Table.Cell width="4" />
					<Table.Cell width="4" />
					<Table.Cell width="1">Totale:</Table.Cell>
					<Table.Cell width="2">{!isNaN(total) ? Math.round(total*100)/100 : '--'}€</Table.Cell>
				</Table.Row>
			);
		} else {
			return (
				<Table.Row>
					<Table.Cell width="2" />
					<Table.Cell width="2" />
					<Table.Cell width="2" />
					<Table.Cell width="2" />
					<Table.Cell width="1" />
					<Table.Cell width="1" />
					<Table.Cell width="1" />
					<Table.Cell width="1">Totale:</Table.Cell>
					<Table.Cell width="2">{total}€</Table.Cell>
				</Table.Row>
			);
		}
	}

	renderTableFooter() {
		let { isBalance } = this.props;
		if (isBalance) return this.renderBalanceFooter();
		else return null;
	}

	renderBalanceHeader() {
		let { grouped } = this.props;

		if (grouped) {
			return (
				<Table.Header fullWidth>
					<Table.Row>
						<Table.HeaderCell width="5">Categoria</Table.HeaderCell>
						<Table.HeaderCell width="4">Quantita'</Table.HeaderCell>
						<Table.HeaderCell width="4">Prezzo medio per pezzo</Table.HeaderCell>
						<Table.HeaderCell width="1" />
						<Table.HeaderCell width="2">Totale</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
			);
		} else {
			return (
				<Table.Header fullWidth>
					<Table.Row>
						<Table.HeaderCell width="2">Codice articolo</Table.HeaderCell>
						<Table.HeaderCell width="2">Descrizione</Table.HeaderCell>
						<Table.HeaderCell width="2">Categoria</Table.HeaderCell>
						<Table.HeaderCell width="2">Fornitore</Table.HeaderCell>
						<Table.HeaderCell width="1">Macchina</Table.HeaderCell>
						<Table.HeaderCell width="1">Quantita'</Table.HeaderCell>
						<Table.HeaderCell width="1">Prezzo</Table.HeaderCell>
						<Table.HeaderCell width="1" />
						<Table.HeaderCell width="2">Totale</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
			);
		}
	}

	renderTableHeader() {
		let { isBalance } = this.props;
		if (isBalance) {
			return this.renderBalanceHeader();
		} else {
			return (
				<Table.Header fullWidth>
					<Table.Row>
						<Table.HeaderCell width="2">Codice articolo</Table.HeaderCell>
						<Table.HeaderCell width="2">Descrizione</Table.HeaderCell>
						<Table.HeaderCell width="2">Categoria</Table.HeaderCell>
						<Table.HeaderCell width="2">Fornitore</Table.HeaderCell>
						<Table.HeaderCell width="1">Macchina</Table.HeaderCell>
						<Table.HeaderCell width="1">Quantita'</Table.HeaderCell>
						<Table.HeaderCell width="1">Prezzo</Table.HeaderCell>
						<Table.HeaderCell width="1" />
						<Table.HeaderCell width="2">Totale</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
			);
		}
	}

	/*
	<Table.HeaderCell>Cassetto</Table.HeaderCell>
	<Table.Cell>{DRAWERNUMBER}</Table.Cell>
	<Table.HeaderCell>Quantita' utilizzata</Table.HeaderCell>
	<Table.Cell>{QTYUSED}</Table.Cell>
	*/

	renderForBalance() {
		let { isBalance, grouped } = this.props;
		let {
			deleting,
			cost,
			supplier,
			groupcode,
			itemcode,
			itemaliascode,
			DESCR,
			DRAWERNUMBER,
			FUNCTIONCODE,
			IMAGEFILE,
			ITEMSUBGROUP,
			MACHINENUMBER,
			OPERATIONID,
			PACKETSIZE,
			QTY,
			QTYACTIVE,
			QTYCOUNTED,
			QTYGENERAL,
			QTYINASSY,
			QTYNEW,
			QTYONORDER,
			QTYREWORK,
			QTYUSED,
			totalcost,
			TRANSCODE
		} = this.state;

		// TODO totalcost ONLY UNTIL THERE IS AN EDITABLE FIELD
		
		if (grouped) {
			return (
				<Table.Row>
					<Table.Cell>{groupcode}</Table.Cell>
					<Table.Cell>{QTY}</Table.Cell>
					<Table.Cell>{cost}€</Table.Cell>
					<Table.Cell>=</Table.Cell>
					<Table.Cell>{totalcost}€</Table.Cell>
				</Table.Row>
			);
		} else {
			return (
				<Table.Row>
					<Table.Cell>{itemcode}</Table.Cell>
					<Table.Cell>{DESCR}</Table.Cell>
					<Table.Cell>{ITEMSUBGROUP}</Table.Cell>
					<Table.Cell>{supplier}</Table.Cell>
					<Table.Cell>{MACHINENUMBER}</Table.Cell>
					<Table.Cell>{QTY}</Table.Cell>
					<Table.Cell>{cost}€</Table.Cell>
					<Table.Cell>=</Table.Cell>
					<Table.Cell>{this.totalCost()}€</Table.Cell>
				</Table.Row>
			);
		}
	}

	renderForTable() {
		let { isBalance } = this.props;
		let {
			deleting,
			cost,
			supplier,
			groupcode,
			itemcode,
			itemaliascode,
			DESCR,
			DRAWERNUMBER,
			FUNCTIONCODE,
			IMAGEFILE,
			ITEMSUBGROUP,
			MACHINENUMBER,
			OPERATIONID,
			PACKETSIZE,
			QTY,
			QTYACTIVE,
			QTYCOUNTED,
			QTYGENERAL,
			QTYINASSY,
			QTYNEW,
			QTYONORDER,
			QTYREWORK,
			QTYUSED,
			TRANSCODE
		} = this.state;

		if (isBalance) {
			return this.renderForBalance();
		} else {
			return (
				<Table.Row>
					<Table.Cell>{itemcode}</Table.Cell>
					<Table.Cell>{DESCR}</Table.Cell>
					<Table.Cell>{ITEMSUBGROUP}</Table.Cell>
					<Table.Cell>{supplier}</Table.Cell>
					<Table.Cell>{MACHINENUMBER}</Table.Cell>
					<Table.Cell>{QTY}</Table.Cell>
					<Table.Cell>{cost}€</Table.Cell>
					<Table.Cell>=</Table.Cell>
					<Table.Cell>{this.totalCost()}€</Table.Cell>
				</Table.Row>
			);
		}
	}

	render() {
		let { type, item, onClick, selected, noActions, showNoItem, grouped } = this.props;
		let {
			deleting,
			cost,
			supplier,
			groupcode,
			itemcode,
			itemaliascode,
			DESCR,
			DRAWERNUMBER,
			FUNCTIONCODE,
			IMAGEFILE,
			ITEMSUBGROUP,
			MACHINENUMBER,
			OPERATIONID,
			PACKETSIZE,
			QTY,
			QTYACTIVE,
			QTYCOUNTED,
			QTYGENERAL,
			QTYINASSY,
			QTYNEW,
			QTYONORDER,
			QTYREWORK,
			QTYUSED,
			MINSSINCELASTWITHDRAW,
			DATECREATED,
			CREATEDBY,
			LASTMODIFIEDBY,
			DATELASTMODIFIED,
			TRANSCODE
		} = this.state;

		let extraprops = {
			fluid: type === 'full' ? true : false
		};

		if (onClick) {
			extraprops.onClick = () => {
				onClick(item);
			};
		}

		let col = parseInt(Math.random() * this.state.colors.length);

		if (item && item.idMacchina === 1 && !showNoItem) {
			return null;
		}

		switch (type) {
			case 'footer':
				return this.renderTableFooter();
			case 'header':
				return this.renderTableHeader();
			case 'table':
				return this.renderForTable();
			default:
				return (
					<View fullw column between>
						<Card
							className={selected ? 'rowSelected' : ''}
							style={styles.itemCard}
							{...extraprops}
							color={this.state.colors[col]}
						>
							<Confirmation
								message="Vuoi davvero eliminare questa macchina?"
								showModal={deleting}
								onConfirm={() => {
									this.remove();
								}}
								onCancel={() => {
									this.setState({ deleting: false });
								}}
							/>
							<Card.Content style={styles.itemContent}>
								<View fullw row>
									<View fullw column>
										<span style={styles.header}>{itemcode}</span>
										{supplier && <Card.Description>Fornitore: {supplier}</Card.Description>}
										{itemaliascode && <Card.Description>Alias: {itemaliascode}</Card.Description>}
										{DESCR && <Card.Description>Descrizione: {DESCR}</Card.Description>}
										{DRAWERNUMBER && <Card.Description>Cassetto: {DRAWERNUMBER}</Card.Description>}
										{FUNCTIONCODE && <Card.Description>Azione: {FUNCTIONCODE}</Card.Description>}
										{ITEMSUBGROUP && <Card.Description>Gruppo: {ITEMSUBGROUP}</Card.Description>}
										{MACHINENUMBER && <Card.Description>Macchina: {MACHINENUMBER}</Card.Description>}
										{PACKETSIZE && <Card.Description>Dimensioni pacchetto: {PACKETSIZE}</Card.Description>}
										{QTY && <Card.Description>Quantita': {QTY}</Card.Description>}
										{QTYACTIVE && <Card.Description>qtyactive: {QTYACTIVE}</Card.Description>}
										{QTYCOUNTED && <Card.Description>qtycounted: {QTYCOUNTED}</Card.Description>}
										{QTYGENERAL && <Card.Description>qtygeneral: {QTYGENERAL}</Card.Description>}
										{QTYINASSY && <Card.Description>qtyinassy: {QTYINASSY}</Card.Description>}
										{QTYNEW && <Card.Description>qtynew: {QTYNEW}</Card.Description>}
										{QTYONORDER && <Card.Description>qtyonorder: {QTYONORDER}</Card.Description>}
										{QTYREWORK && <Card.Description>qtyrework: {QTYREWORK}</Card.Description>}
										{QTYUSED && <Card.Description>qtyused: {QTYUSED}</Card.Description>}
										{cost && <Card.Description>Prezzo: {cost}</Card.Description>}

										<Card.Meta style={{ textTransform: 'capitalize' }}>{TRANSCODE}</Card.Meta>
									</View>
								</View>
							</Card.Content>
						</Card>
					</View>
				);
		}
	}
}

const styles = {
	header: {
		fontSize: '32pt',
		lineHeight: 'normal'
	},
	imageContainer: {
		width: 200,
		height: 150,
		marginRight: 28
	},
	itemImage: {
		width: 200,
		height: 150,
		objectFit: 'contain'
	},
	itemContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	itemCard: {
		marginTop: 8,
		marginBottom: 8
	}
};

const mapStateToProps = (state, ownProps) => {
	return {};
};

export default withRouter(connect(mapStateToProps)(Radium(connectNetwork(Item))));
