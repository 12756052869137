export function getWork(data) {
	let { getWorkRequest, getWorkError, getWorkResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_work',
		this.createBundle(
			'api/get_work',
			{ work: data },
			this.GET,
			getWorkRequest,
			getWorkResult,
			getWorkError
		)
	);
}
