export function updateJob(data) {
	let { updateJobRequest, updateJobError, updateJobResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/update_job',
		this.createBundle(
			'api/update_job',
			{ job: data },
			this.POST,
			updateJobRequest,
			updateJobResult,
			updateJobError
		)
	);
}
