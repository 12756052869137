export function getJobList(data) {
	let { getJobListRequest, getJobListError, getJobListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_job_list',
		this.createBundle(
			'api/get_job_list',
			{ data: data },
			this.GET,
			getJobListRequest,
			getJobListResult,
			getJobListError
		)
	);
}
