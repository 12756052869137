import React, { Component } from 'react'
import Radium from 'radium'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'
import { Route, Switch } from 'react-router-dom'
import { route_from, routes } from 'lib/routes'

import View from 'lib/View'
import { ToolBar, SideBar } from 'components'
import {
	Jobs,
	WorkAssignment,
	Workers,
	Machines,
	Monitor,
	SWA300,
	BabyPressa,
	Admin,
	Warehouse,
	SWA300Editor,
	JobMerge,
	JobEditor,
	WorkerEditor,
	MachineEditor,
	FinalBalance,
	DetailView,
	CalendarView,
	CostEditor,
	SingleJob
} from 'containers'
import { Menu } from 'semantic-ui-react'

import { withCookies } from 'react-cookie'

import { SEARCH_ACTIONS } from '../actions/search'
import GT2000 from './GT2000'

const {
	app,
	jobs,
	jobEdit,
	jobMerge,
	workerEdit,
	machineEdit,
	works,
	employees,
	machines,
	babypressa,
	saldatrice,
	gt2000,
	warehouse,
	monitor,
	admin,
	employee,
	insertion,
	finalBalance,
	costEdit,
	saldatriceeditor,
	costUpdate,
	calendarView,
	job,
	tempJobs,
	newEl
} = routes

class Main extends Component {
	constructor(props) {
		super(props)
		this.state = {
			generalItem: false,
			fetching: false
		}
	}

	componentDidMount() {
		let { history } = this.props

		this.unlisten = history.listen((location, action) => {
			let { pathname } = location
			let position = this.state[pathname] || 0

			let main = document.getElementsByClassName('main')[0]
			if (main && position !== 0) {
				//console.log(position, main);
				main.scrollTo({ top: position, left: 0, behavior: 'smooth' })
			}

			console.log('History listen ', location, action)
			this.props.searchResult('')
		})
	}

	componentWillUnmount() {
		this.unlisten()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { fetching } = this.props
		//console.log("Main did update checking fetching:", fetching, prevProps.fetching);
		if (fetching !== prevProps.fetching) {
			this.setState({ fetching })
		}
	}

	render() {
		let { generalItem, fetching } = this.state
		let { isEmployee } = this.props
		let { location: { pathname } } = this.props.history

		return (
			<View fullh row>
				<SideBar isEmployee={isEmployee} />

				<View fullh fullw column>
					<ToolBar isEmployee={isEmployee} />
					<View
						column
						fullw
						style={{ height: 'calc(100% - 50px)', borderLeft: '1px solid rgba(34, 36, 38, .15)' }}
					>
						<Menu horizontal style={{ borderRadius: '0px', marginBottom: 0 }}>
							{this.props.toolbar.data.length > 0 &&
								this.props.toolbar.data.map((tool, index) => (
									<Menu.Item
										key={`menu_item_${index}`}
										as={!tool.type || tool.type === 'button' ? 'a' : 'span'}
										{...{
											disabled: fetching || (tool.disabled ? tool.disabled() : false)
										}}
										icon={tool.icon}
										name={tool.name}
										onClick={tool.action}
										position={tool.position ? tool.position : 'left'}
										className={tool.selected && tool.selected() ? 'rowSelected' : ''}
									/>
								))}
						</Menu>
						<View
							className='main'
							fullh
							fullw
							column
							style={{
								overflowY: 'auto',
								textAlign: 'center'
							}}
							monitorPos={(pos) => this.setState({ [pathname]: pos })}
						>
							<View
								fullh
								fullw
								column
								style={{ maxWidth: isEmployee ? '100%' : 1200, textAlign: 'left', margin: '0px auto' }}
							>
								{isEmployee && (
									<Switch>
										<Route
											exact
											path={route_from(employee, insertion)}
											render={() => <DetailView />}
										/>
										<Route
											exact
											path={route_from(employee, insertion, calendarView)}
											render={() => <CalendarView />}
										/>
									</Switch>
								)}
								{!isEmployee && (
									<Switch>
										<Route
											exact
											path={route_from(app, jobs)}
											render={() => <Jobs direction='column' type='full' />}
										/>
										<Route
											exact
											path={route_from(app, tempJobs)}
											render={() => <Jobs createdExternal direction='column' type='full' />}
										/>
										<Route exact path={route_from(app, jobs, ':job')} component={SingleJob} />
										<Route
											path={route_from(app, jobs, finalBalance, ':job')}
											component={FinalBalance}
										/>
										<Route
											exact
											path={route_from(app, jobs, ':job', finalBalance)}
											component={FinalBalance}
										/>
										<Route path={route_from(app, jobs, costEdit, ':job')} component={CostEditor} />
										<Route
											path={route_from(app, jobs, costUpdate, ':cost')}
											component={CostEditor}
										/>
										<Route path={route_from(app, jobs, jobEdit, ':job')} component={JobEditor} />
										<Route
											exact
											path={route_from(app, jobs, newEl, jobEdit)}
											component={JobEditor}
										/>
										<Route
											exact
											path={route_from(app, tempJobs, jobMerge, ':job')}
											component={JobMerge}
										/>
										<Route
											path={route_from(app, employees, workerEdit, ':worker')}
											component={WorkerEditor}
										/>
										<Route
											exact
											path={route_from(app, employees, workerEdit)}
											component={WorkerEditor}
										/>
										<Route
											path={route_from(app, machines, machineEdit, ':machine')}
											component={MachineEditor}
										/>
										<Route
											exact
											path={route_from(app, machines, machineEdit)}
											component={MachineEditor}
										/>
										<Route exact path={route_from(app, works)} render={() => <WorkAssignment />} />
										<Route exact path={route_from(app, employees)} component={Workers} />
										<Route exact path={route_from(app, babypressa)} component={BabyPressa} />
										<Route exact path={route_from(app, saldatrice, saldatriceeditor)} component={SWA300Editor} />
										<Route exact path={route_from(app, saldatrice)} component={SWA300} />
										<Route exact path={route_from(app, gt2000)} component={GT2000} />
										<Route exact path={route_from(app, machines)} component={Machines} />
										<Route exact path={route_from(app, warehouse)} component={Warehouse} />
										<Route exact path={route_from(app, monitor)} component={Monitor} />
										<Route exact path={route_from(app, admin)} component={Admin} />
									</Switch>
								)}
							</View>
						</View>
					</View>
				</View>
			</View>
		)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	}
}

const mapStateToProps = (state, ownProps) => {
	let { login, loginEmployee, user, toolbar } = state

	let fetching = false

	for (let key in state) {
		let redobj = state[key]
		if (redobj && redobj.fetching) {
			fetching = true
			break
		}
	}

	return {
		login,
		loginEmployee,
		user,
		toolbar,
		fetching
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(withCookies(Main)))))
