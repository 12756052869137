export function uploadFile(file) {
	let { uploadFileRequest, uploadFileError, uploadFileResult } = this.props;

	console.log('Uploading file:', file);
	const formData = new FormData();
	formData.append('file', file);

    for (var key of formData.entries()) {
        console.log(key[0] + ', ' + key[1]);
    }

	let bundle = this.createBundle('api/upload', {}, this.POST, uploadFileRequest, uploadFileResult, uploadFileError);
	bundle.data = formData;
	bundle.data.ACTION = 'api/upload';
	bundle.noStringify = true;
	//bundle.noAuth = true;
	bundle.contenttype = "multipart/form-data";

	this.doAction(this.WEB_REQUEST_URL + 'api/upload', bundle);
}

/*
export function uploadFile(file, callback) {
	console.log('Uploading file:', file);
	const formData = new FormData();
	formData.append('file', file); //event.target.files[0] data going here
	let { uploadFileRequest, uploadFileError, uploadFileResult } = this.props;

	if (formData.entries) {
		for (var key of formData.entries()) {
			console.dir(key[0]);
			console.dir(key[1]);
		}
	}
	
	fetch(this.WEB_REQUEST_URL + 'api/upload', {
		// Your POST endpoint
		headers: {
			'Content-Type': "multipart/form-data"
		},
		method: 'POST',
		body: formData // This is your file object
	})
		.then(
			(response) => response.json() // if the response is a JSON object
		)
		.then(
			(success) => uploadFileResult(success) // Handle the success response object
		)
		.catch(
			(error) => console.log(error) // Handle the error response object
		);
}*/