export function getPagedCostList(data) {
	let { getPagedCostListRequest, getPagedCostListError, getPagedCostListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_paged_cost_list',
		this.createBundle(
			'api/get_paged_cost_list',
			{ data: data },
			this.GET,
			getPagedCostListRequest,
			getPagedCostListResult,
			getPagedCostListError
		)
	);
}
