import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import {} from 'semantic-ui-react';

import View from 'lib/View';
import { Worker } from '../components';

import { Placeholder, Card } from 'semantic-ui-react';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import { route_from, routes } from 'lib/routes';

const { app, employees, workerEdit } = routes;

class Workers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			items: 10
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return nextProps;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { deleteWorker } = this.state;
		if (
			this.state.page != prevState.page ||
			this.state.items != this.state.items ||
			(deleteWorker.fetching !== prevState.deleteWorker.fetching && !deleteWorker.fetching)
		) {
			this.state.network.getWorkerList(this.state.search.data);
		} else if (this.state.search != prevState.search) {
			this.state.network.getWorkerList(this.state.search.data);
		}
	}

	next() {
		this.setState({
			page: this.state.page + 1
		});
	}

	prev() {
		if (this.state.page === 0) return;

		this.setState({
			page: this.state.page - 1
		});
	}

	componentDidMount() {
		this.props.toolbarResult([
			{
				name: 'Crea nuovo impiegato',
				icon: 'add',
				action: () => {
					this.props.history.push(route_from(app, employees, workerEdit));
				}
			},
			{
				name: '',
				icon: 'arrow left',
				position: 'right',
				action: () => {
					this.prev();
				}
			},
			{
				name: '',
				icon: 'arrow right',
				position: 'none',
				action: () => {
					this.next();
				}
			}
		]);

		this.state.network.getWorkerList(this.state.search.data);
	}

	renderWorker(key, worker) {
		return <Worker type="full" key={`worker_${worker.idImpiegato}`} worker={worker} />;
	}

	render() {
		let content = <div />;

		if (this.state.fetching) {
			let placeholders = [];
			for (let i = 0; i < 10; i++) {
				placeholders.push(
					<Card style={{ width: '100%' }}>
						<Card.Content>
							<Placeholder>
								<Placeholder.Header image>
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Header>
								<Placeholder.Paragraph>
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Paragraph>
							</Placeholder>
						</Card.Content>
					</Card>
				);
			}
			content = (
				<View fullw column>
					{placeholders}
				</View>
			);
		} else if (this.state.workers.data) {
			content = this.state.workers.data.map((value, index) => {
				return this.renderWorker(index, value);
			});
		}

		return (
			<View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
				{content}
			</View>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let { getWorkerList, search, deleteWorker } = state;

	return {
		workers: getWorkerList.data,
		fetching: getWorkerList.fetching,
		deleteWorker,
		search
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(Workers))));
