import { DELETEWORKER_TYPES } from '../actions/deleteWorker';
import reducer, { defaultState } from './requests';
const Reducer = (state = defaultState, action) => {
	let requestReducer = reducer(DELETEWORKER_TYPES)(state, action);
	if (requestReducer !== null) return requestReducer;
	switch (action.type) {
		default:
			return state;
	}
};
export default Reducer;
