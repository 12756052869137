export function deleteCost(cost) {
	let { deleteCostRequest, deleteCostError, deleteCostResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/delete_cost',
		this.createBundle(
			'api/delete_cost',
			{ cost },
			this.POST,
			deleteCostRequest,
			deleteCostResult,
			deleteCostError
		)
	);
}
