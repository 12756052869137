export function updateWork(data) {
	let { updateWorkRequest, updateWorkError, updateWorkResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/update_work',
		this.createBundle(
			'api/update_work',
			{ work: data },
			this.POST,
			updateWorkRequest,
			updateWorkResult,
			updateWorkError
		)
	);
}
