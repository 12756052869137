export function getRecentJobList(page, items, search, workerId, isEmployee, external) {
	let { getRecentJobListRequest, getRecentJobListError, getRecentJobListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_recent_job_list',
		this.createBundle(
			'api/get_recent_job_list',
			{
				page,
				items,
				search,
				workerId,
				external: external ? 1 : 0
			},
			this.GET,
			getRecentJobListRequest,
			getRecentJobListResult,
			getRecentJobListError,
			isEmployee
		)
	);
}
