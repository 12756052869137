import React, { Component } from 'react'
import Radium from 'radium'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'

import { ImageDefault, Work, Worker, Machine, Cost, Empty, CustomTable, Confirmation, GT2000Data } from './'

import { timeString, floatTimeToString, timeObject } from '../lib/util'

import {
	Step,
	Icon,
	Button,
	Card,
	Transition,
	Placeholder,
	Segment,
	Header,
	Statistic,
	Label,
	Table,
	Checkbox,
	Modal,
	Form,
	Input
} from 'semantic-ui-react'
import View from 'lib/View'

import { Items } from 'containers'

import { route_from, routes } from 'lib/routes'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import itIT from 'date-fns/locale/it'
import BabypressaData from './BabypressaData'

const moment = require('moment-timezone')

const { app, job: jobRoute, jobs, tempJobs, jobMerge, finalBalance, jobEdit, costEdit, newEl } = routes

class Job extends Component {
	constructor(props) {
		super(props)
		this.state = this.compute()

		let { showDetails, forcedOpen } = this.props
		if (showDetails !== undefined) {
			this.state.showDetails = showDetails
		}

		if (forcedOpen) {
			this.state.showDetails = true
		}
		this.state.preventivato = 0
		this.state.fatturato = 0
		if (this.state.job) {
			this.state.dataUltimaFatturazione = this.state.job.dataUltimaFatturazione
		}
		this.state.budgetedModal = false
		this.state.billedModal = false
		this.state.dateModal = false
	}

	compute(forcedJob, forcedWorks) {
		let { fixedJob } = this.props
		if (fixedJob) forcedJob = fixedJob

		let { works: fromProps } = this.props
		if (!fromProps) fromProps = []
		let works = fromProps.slice(0)
		if (forcedWorks) works = forcedWorks.slice(0)

		/*
		console.log('Computing with this work list');
		console.dir(works);
		console.dir(forcedWorks);
		console.dir(fromProps);
		*/
		let workers = []
		let workerobj = {}
		let machines = []
		let machineobj = {}
		let totalWorkerTime = [ 0, 0 ]
		let totalMachineTime = [ 0, 0 ]

		let workdict = {}
		let costdict = {}
		let costs = []
		let notWorks = [] // Cost entries don't have a work attached to them

		let { costs: costsFromProps } = this.props
		if (costsFromProps) {
			costs = costsFromProps
			for (let i in costs) {
				costs[i].prezzoCosto = parseFloat(costs[i].prezzoCosto)
			}
		}

		//console.log('Job costs from props:', costsFromProps);
		for (let i in works) {
			let work = works[i]
			let {
				codiceCommissione,
				idImpiegato,
				idMacchina,
				nomeImpiegato,
				nomeMacchina,
				oreOperaio,
				oreMacchina,
				minutiOperaio,
				minutiMacchina,
				prezzoOrarioImpiegato,
				prezzoOrarioMacchina,
				idCosto,
				dataCosto,
				descrizioneCosto,
				fornitore,
				prezzoCosto,
				dataRegistrazione
			} = work

			//console.log("idCosto", idCosto, "in", costdict);
			if (!costsFromProps && idCosto !== null && idCosto && !costdict[idCosto]) {
				//console.log('Pushing work', work, 'as cost');
				costdict[idCosto] = {
					idCosto,
					dataCosto,
					codiceCommissione,
					descrizioneCosto,
					fornitore,
					prezzoCosto
				}
				costs.push(costdict[idCosto])
			}

			if (!workdict[`${codiceCommissione}_${idImpiegato}_${dataRegistrazione}`]) {
				workdict[`${codiceCommissione}_${idImpiegato}_${dataRegistrazione}`] = true
			} else {
				notWorks.push(parseInt(i))
				continue
			}

			oreOperaio = parseFloat(oreOperaio)
			minutiOperaio = parseFloat(minutiOperaio)
			oreMacchina = parseFloat(oreMacchina)
			minutiMacchina = parseFloat(minutiMacchina)
			totalWorkerTime[0] += oreOperaio
			totalWorkerTime[1] += minutiOperaio
			totalMachineTime[0] += oreMacchina
			totalMachineTime[1] += minutiMacchina

			if (idImpiegato !== null) {
				if (!workerobj[idImpiegato]) {
					workerobj[idImpiegato] = []
				}

				workerobj[idImpiegato].push({
					idImpiegato,
					nomeImpiegato,
					oreOperaio,
					minutiOperaio,
					prezzoOrarioImpiegato
				})
			}

			if (idMacchina !== null) {
				if (!machineobj[idMacchina]) {
					machineobj[idMacchina] = []
				}

				machineobj[idMacchina].push({
					idMacchina,
					nomeMacchina,
					oreMacchina,
					minutiMacchina,
					prezzoOrarioMacchina
				})
			}
		}

		for (let i = notWorks.length - 1; i >= 0; i--) {
			let toRemove = notWorks[i]
			works.splice(toRemove, 1)
		}

		for (let i in workerobj) {
			let worklist = workerobj[i]
			let { idImpiegato, nomeImpiegato } = worklist[0]
			let oreOperaio = 0
			let minutiOperaio = 0
			let prezzoOrarioImpiegato = 0

			for (let j in worklist) {
				let work = worklist[j]
				oreOperaio += work.oreOperaio
				minutiOperaio += work.minutiOperaio
				prezzoOrarioImpiegato = parseFloat(work.prezzoOrarioImpiegato)
			}

			oreOperaio += Math.floor(minutiOperaio / 60)
			minutiOperaio = minutiOperaio % 60

			workers.push({
				idImpiegato,
				nomeImpiegato,
				oreOperaio,
				minutiOperaio,
				prezzoOrarioImpiegato
			})
		}

		for (let i in machineobj) {
			let worklist = machineobj[i]
			let { idMacchina, nomeMacchina } = worklist[0]
			let oreMacchina = 0
			let minutiMacchina = 0
			let prezzoOrarioMacchina = 0

			for (let j in worklist) {
				let work = worklist[j]
				oreMacchina += work.oreMacchina
				minutiMacchina += work.minutiMacchina
				prezzoOrarioMacchina = parseFloat(work.prezzoOrarioMacchina)
			}

			oreMacchina += Math.floor(minutiMacchina / 60)
			minutiMacchina = minutiMacchina % 60

			machines.push({
				idMacchina,
				nomeMacchina,
				oreMacchina,
				minutiMacchina,
				prezzoOrarioMacchina
			})
		}

		let colors = [
			'red',
			'orange',
			'yellow',
			'olive',
			'green',
			'teal',
			'blue',
			'violet',
			'purple',
			'pink',
			'brown',
			'grey',
			'black'
		]
		let color = this.props.color
		if (!color) {
			color = colors[parseInt(Math.random() * colors.length)]
		}

		let { job, isBalance } = this.props

		totalWorkerTime[0] += Math.floor(totalWorkerTime[1] / 60)
		totalWorkerTime[1] = totalWorkerTime[1] % 60
		totalMachineTime[0] += Math.floor(totalMachineTime[1] / 60)
		totalMachineTime[1] = totalMachineTime[1] % 60

		//console.log("Final works list", works);
		console.log('Job forcedJob is', forcedJob)

		return {
			costs,
			workers,
			works,
			machines,
			totalWorkerTime,
			totalMachineTime,
			color,
			showDetails: isBalance ? true : false,
			deleting: false,
			job: forcedJob ? forcedJob : job,
			costDict: {},
			total: 0,
			external: job ? job.creazioneEsterna === 1 : false,
			totalWithItems: true
		}
	}

	componentDidMount() {
		let { job, jobId, network } = this.props

		if ((!job || !job.codiceCommissione) && jobId) {
			network.getJob(jobId)
			network.getBabypressaDataFilter(jobId)
			network.getGT2000DataFilter(jobId)
		} else {
			// network.getBabypressaDataFilter(job.codiceCommissione)
			// network.getGT2000DataFilter(job.codiceCommissione)
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { getJob, costs, showDetails, jobId, works: worksFromProps } = this.props
		if (getJob && getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching) {
			if (getJob.status.success && getJob.data[jobId]) {
				//console.log(getJob.data);
				let job = getJob.data[jobId][0]
				let works = getJob.data[jobId]
				if (worksFromProps) {
					works = worksFromProps
				}
				let newState = this.compute(job, works)
				this.setState({ job, works, ...newState })
				return
			}
		}

		if (costs !== prevProps.costs) {
			this.setState(this.compute())
			return
		}

		if (!!showDetails !== !!prevProps.showDetails) {
			this.setState({ showDetails: !!showDetails })
		}
	}

	renderCompact() {
		let { type, onClick, selected, fetching } = this.props
		let { color, deleting, job } = this.state

		let extraProps = {
			onClick: onClick
				? () => {
						onClick(job)
					}
				: null
		}

		return (
			<Card
				className={selected ? 'rowSelected' : ''}
				{...extraProps}
				style={compact_styles.jobCard}
				color={color}
			>
				<Card.Content style={compact_styles.jobContent}>
					<View fullw column>
						<div style={compact_styles.imageContainer}>
							<ImageDefault fromRoot src={job.immagine} ui={false} style={compact_styles.jobImage} />
						</div>
						<View fullw column>
							<span style={compact_styles.header}>{job.codiceCommissione}</span>
							<Card.Description>
								{job.descrizioneCommessa ? job.descrizioneCommessa : job.descrizione}
							</Card.Description>
							<Card.Description>Programma: {job.programmaCommessa}</Card.Description>
							<Card.Meta style={{ textTransform: 'capitalize' }}>
								{moment(job.dataCreazione).format('LLLL')}
							</Card.Meta>
						</View>
					</View>
				</Card.Content>
			</Card>
		)
	}

	onCostChange(newCost, shorthand) {
		let { costDict, totalWithItems } = this.state
		let total = 0
		costDict[shorthand] = newCost

		for (let i in costDict) {
			console.log('Cost dict key: ', i)
			if (i === 'cost' && !totalWithItems) continue
			if (i === 'item' && !totalWithItems) continue
			total += costDict[i]
		}

		this.setState({
			costDict,
			total
		})
	}

	renderGT2000Table() {
		let { isBalance, getGT2000DataFilter } = this.props
		let extraProps = {}
		if (isBalance) {
			extraProps = {
				isBalance,
				onCostChange: (total, shorthand) => this.onCostChange(total, shorthand)
			}
		}

		console.log('Rendering GT2000 table with data', getGT2000DataFilter)
		if (!getGT2000DataFilter || !getGT2000DataFilter.data) return null

		let gt2000datalist = getGT2000DataFilter.data

		return (
			<CustomTable
				name='Dati GT2000 relativi alla commessa'
				shorthand='gt2000data'
				values={gt2000datalist.data}
				child={GT2000Data}
			/>
		)
	}

	renderBabypressaTable() {
		let { isBalance, getBabypressaDataFilter } = this.props
		let extraProps = {}
		if (isBalance) {
			extraProps = {
				isBalance,
				onCostChange: (total, shorthand) => this.onCostChange(total, shorthand)
			}
		}

		console.log('Rendering BabyPressa table with data', getBabypressaDataFilter)
		if (!getBabypressaDataFilter || !getBabypressaDataFilter.data) return null

		let babypressadatalist = getBabypressaDataFilter.data

		return (
			<CustomTable
				name='Dati Babypressa relativi alla commessa'
				shorthand='babypressadata'
				values={babypressadatalist.data}
				child={BabypressaData}
			/>
		)
	}

	renderMachineTable() {
		let { machines } = this.state
		let { isBalance } = this.props
		let extraProps = {}
		if (isBalance) {
			extraProps = {
				isBalance,
				onCostChange: (total, shorthand) => this.onCostChange(total, shorthand)
			}
		}
		return <CustomTable {...extraProps} name='Macchine' shorthand='machine' values={machines} child={Machine} />
	}

	renderWorkerTable() {
		let { workers } = this.state
		let { isBalance } = this.props
		let extraProps = {}
		if (isBalance) {
			extraProps = {
				isBalance,
				onCostChange: (total, shorthand) => this.onCostChange(total, shorthand)
			}
		}
		return <CustomTable {...extraProps} name='Impiegati' shorthand='worker' values={workers} child={Worker} />
	}

	renderWorkTable() {
		let { works } = this.state
		let { isBalance } = this.props
		let extraProps = {}

		if (isBalance) {
			extraProps = {
				isBalance,
				onCostChange: (total, shorthand) => this.onCostChange(total, shorthand)
			}
		}
		return (
			<CustomTable
				{...extraProps}
				name='Lavori'
				shorthand='work'
				values={works}
				child={Work}
				required='dataRegistrazione'
			/>
		)
	}

	renderCostTable() {
		let { costs } = this.state
		let { isBalance } = this.props
		let extraProps = {}
		if (isBalance) {
			extraProps = {
				isBalance,
				onCostChange: (total, shorthand) => this.onCostChange(total, shorthand)
			}
		}
		return <CustomTable {...extraProps} name='Costi' shorthand='cost' values={costs} child={Cost} />
	}

	openRemoveDialog() {
		this.setState({ deleting: true })
	}

	remove() {
		let { network, job } = this.props

		network.deleteJob(job)

		this.setState({ deleting: false })
	}

	handleInput(event) {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		this.setState({
			[name]: value
		})
	}

	render() {
		let { mappingData, type, noActions, onClick, selected, isBalance, onOpened, forcedOpen, role } = this.props
		let {
			preventivato,
			fatturato,
			dataUltimaFatturazione,
			color,
			job,
			deleting,
			total,
			totalWorkerTime,
			totalMachineTime,
			totalWithItems,
			costDict,
			budgetedModal,
			billedModal,
			dateModal,
			external
		} = this.state

		if (!job) {
			return (
				<Card style={{ width: '100%', margin: 8 }}>
					<Card.Content>
						<Placeholder>
							<Placeholder.Header image>
								<Placeholder.Line />
								<Placeholder.Line />
							</Placeholder.Header>
							<Placeholder.Paragraph>
								<Placeholder.Line />
								<Placeholder.Line />
								<Placeholder.Line />
								<Placeholder.Line />
							</Placeholder.Paragraph>
						</Placeholder>
					</Card.Content>
				</Card>
			)
		}

		let partial = (costDict['worker'] ? costDict['worker'] : 0) + (costDict['machine'] ? costDict['machine'] : 0)

		let totalhours = totalWorkerTime[0] + totalMachineTime[0] + (totalWorkerTime[1] + totalMachineTime[1]) / 60
		let averageHourlyCost = Math.round(partial / totalhours * 100) / 100

		let extraprops = {
			fluid: type === 'full' ? true : false
		}

		if (onClick) {
			extraprops.onClick = () => {
				onClick(job)
			}
		}

		if (type === 'compact') {
			return this.renderCompact()
		}

		let workerTimeObject
		let machineTimeObject
		if (totalWorkerTime) {
			workerTimeObject = timeObject(totalWorkerTime[0], totalWorkerTime[1])
		}
		if (totalMachineTime) {
			machineTimeObject = timeObject(totalMachineTime[0], totalMachineTime[1])
		}

		console.log(
			'mappingData for job',
			job.codiceCommissione,
			' is:',
			mappingData.data ? mappingData.data[job.codiceCommissione] : null,
			mappingData
		)

		return (
			<View fullw column between>
				<Card
					id={job.codiceCommissione}
					className={selected ? 'rowSelected' : ''}
					style={styles.jobCard}
					{...extraprops}
					color={color}
				>
					<Confirmation
						message='Vuoi davvero eliminare questa commessa? Tutte le lavorazioni e i costi saranno perduti!'
						showModal={deleting}
						onConfirm={() => {
							this.remove()
						}}
						onCancel={() => {
							this.setState({ deleting: false })
						}}
					/>
					<Card.Content style={styles.jobContent}>
						<View fullw {...{ row: type === 'full', column: type !== 'full' }}>
							<div style={styles.imageContainer}>
								<ImageDefault fromRoot src={job.immagine} ui={false} style={styles.jobImage} />
							</div>
							<View fullw row>
								<View fullw column>
									<span style={styles.header}>{job.codiceCommissione}</span>
									<Card.Description>
										{job.descrizioneCommessa ? job.descrizioneCommessa : job.descrizione}
									</Card.Description>
									<Card.Description>Programma: {job.programmaCommessa}</Card.Description>
									<Card.Meta style={{ textTransform: 'capitalize' }}>
										Creata {moment(job.dataCreazione).format('LLLL')}
									</Card.Meta>
									{mappingData &&
									mappingData.data &&
									job &&
									job.codiceCommissione &&
									mappingData.data[job.codiceCommissione] && (
										<Card.Meta>
											Posizione dello stampo nell'armadio: RIGA{' '}
											{mappingData.data[job.codiceCommissione].riga} COLONNA{' '}
											{mappingData.data[job.codiceCommissione].colonna}
										</Card.Meta>
									)}
									{!noActions && (
										<Card.Meta>
											Ultima fatturazione:{' '}
											{job.dataUltimaFatturazione ? (
												moment(job.dataUltimaFatturazione).format('LL')
											) : (
												'----'
											)}{' '}
											<Icon
												onClick={() => {
													// Format: YYYY-MM-DD
													let momobj = moment(job.dataUltimaFatturazione)

													let data = new Date()
													if (momobj.isValid()) {
														data = momobj.toDate()
													}

													this.setState({
														dataUltimaFatturazione: data,
														dateModal: true
													})
												}}
												name='edit'
											/>
											<Modal open={dateModal}>
												<Modal.Header>
													Modifica cifra preventivata commessa {job.codiceCommissione}
												</Modal.Header>
												<Modal.Content>
													<Form>
														<Form.Field
															control={DatePicker}
															label='Data ultima fatturazione'
															name='dataUltimaFatturazione'
															value={dataUltimaFatturazione}
															dateFormat='dd/MM/yyyy'
															selected={dataUltimaFatturazione}
															onChange={(data) => {
																this.setState({
																	dataUltimaFatturazione: data
																})
															}}
														/>
													</Form>
												</Modal.Content>
												<Modal.Actions>
													<Button
														primary
														onClick={() => {
															this.setState({ dateModal: false })
														}}
													>
														Annulla <Icon name='check' />
													</Button>
													<Button
														primary
														onClick={() => {
															let { job, dataUltimaFatturazione } = this.state
															let { network } = this.props
															job.dataUltimaFatturazione = dataUltimaFatturazione
															this.setState({ job, dateModal: false })

															let { codiceCommissione } = job
															network.createJob(codiceCommissione, {
																codiceCommissione,
																dataUltimaFatturazione
															})
														}}
													>
														Conferma <Icon name='check' />
													</Button>
												</Modal.Actions>
											</Modal>
										</Card.Meta>
									)}
								</View>
								<View fullw column>
									<View fullw column style={{ justifyContent: 'center', minHeight: 60 }}>
										<View fullw row style={{ justifyContent: 'center', textAlign: 'center' }}>
											<span style={{ marginTop: 2, marginRight: 16 }}>Cliente:</span>
											<Header style={{ marginTop: 0 }}>{job.cliente}</Header>
										</View>
									</View>
									{!noActions && (
										<View style={{ minHeight: 103 }} fullw row around>
											<Statistic style={{ marginBottom: 0, alignSelf: 'center' }}>
												<Statistic.Label>Ore operaio</Statistic.Label>
												<Statistic.Value className='smallStatistic'>
													{workerTimeObject.hstring}
												</Statistic.Value>
												<Statistic.Value className='smallStatistic'>
													{workerTimeObject.mstring}
												</Statistic.Value>
											</Statistic>
											<Statistic style={{ alignSelf: 'center' }}>
												<Statistic.Label>Ore macchina</Statistic.Label>
												<Statistic.Value className='smallStatistic'>
													{machineTimeObject.hstring}
												</Statistic.Value>
												<Statistic.Value className='smallStatistic'>
													{machineTimeObject.mstring}
												</Statistic.Value>
											</Statistic>
										</View>
									)}
									{!noActions &&
									(!role || role === 'admin') && (
										<View fullw row around>
											<Statistic style={{ marginBottom: 0, alignSelf: 'center' }}>
												<Statistic.Label>
													Preventivato{' '}
													<Icon
														onClick={() => {
															this.setState({
																preventivato: this.state.job.preventivato,
																budgetedModal: true
															})
														}}
														name='edit'
													/>
													<Modal open={budgetedModal}>
														<Modal.Header>
															Modifica cifra preventivata commessa {job.codiceCommissione}
														</Modal.Header>
														<Modal.Content>
															<Form>
																<Form.Field
																	control={Input}
																	type='number'
																	label='Preventivato'
																	placeholder='0.0'
																	step='0.1'
																	name='preventivato'
																	value={preventivato}
																	className='rightTextField'
																	onChange={(e) => this.handleInput(e)}
																/>
															</Form>
														</Modal.Content>
														<Modal.Actions>
															<Button
																primary
																onClick={() => {
																	this.setState({ budgetedModal: false })
																}}
															>
																Annulla <Icon name='check' />
															</Button>
															<Button
																primary
																onClick={() => {
																	let { job, preventivato } = this.state
																	let { network } = this.props
																	job.preventivato = preventivato
																	this.setState({ job, budgetedModal: false })

																	let { codiceCommissione } = job
																	network.createJob(codiceCommissione, {
																		codiceCommissione,
																		preventivato
																	})
																}}
															>
																Conferma <Icon name='check' />
															</Button>
														</Modal.Actions>
													</Modal>
												</Statistic.Label>
												<Statistic.Value className='smallestStatistic'>
													{job.preventivato ? job.preventivato : '----'}€
												</Statistic.Value>
											</Statistic>
											<Statistic style={{ alignSelf: 'center' }}>
												<Statistic.Label>
													Fatturato{' '}
													<Icon
														onClick={() => {
															this.setState({
																fatturato: this.state.job.fatturato,
																billedModal: true
															})
														}}
														name='edit'
													/>
													<Modal open={billedModal}>
														<Modal.Header>
															Modifica cifra preventivata commessa {job.codiceCommissione}
														</Modal.Header>
														<Modal.Content>
															<Form>
																<Form.Group widths='equal'>
																	<Form.Field
																		control={Input}
																		type='number'
																		label='Fatturato'
																		placeholder='0.0'
																		step='0.1'
																		name='fatturato'
																		value={fatturato}
																		className='rightTextField'
																		onChange={(e) => this.handleInput(e)}
																	/>
																</Form.Group>
															</Form>
														</Modal.Content>
														<Modal.Actions>
															<Button
																primary
																onClick={() => {
																	this.setState({ billedModal: false })
																}}
															>
																Annulla <Icon name='check' />
															</Button>
															<Button
																primary
																onClick={() => {
																	let { job, fatturato } = this.state
																	let { network } = this.props
																	job.fatturato = fatturato
																	this.setState({ job, billedModal: false })

																	let { codiceCommissione } = job
																	network.createJob(codiceCommissione, {
																		codiceCommissione,
																		fatturato
																	})
																}}
															>
																Conferma <Icon name='check' />
															</Button>
														</Modal.Actions>
													</Modal>
												</Statistic.Label>
												<Statistic.Value className='smallestStatistic'>
													{job.fatturato ? job.fatturato : '----'}€
												</Statistic.Value>
											</Statistic>
										</View>
									)}
								</View>
							</View>
						</View>
					</Card.Content>
					{type === 'full' &&
					!noActions && (
						<Card.Content extra>
							{!forcedOpen &&
							!external && (
								<Button
									onClick={() => {
										if (onOpened) {
											onOpened(job, true, () => {
												this.props.history.push(
													route_from(app, jobs, `${job.codiceCommissione}`)
												)
											})
										} else {
											this.props.history.push(route_from(app, jobs, `${job.codiceCommissione}`))
										}
									}}
								>
									<Icon name='folder open' />
									Apri
								</Button>
							)}
							{!forcedOpen &&
							!external &&
							false && (
								<Button
									onClick={() => {
										if (onOpened) {
											onOpened(job, !this.state.showDetails)
										} else {
											this.setState({ showDetails: !this.state.showDetails })
										}
									}}
								>
									<Icon name='cogs' />
									Dettagli
								</Button>
							)}
							{!external && (
								<Button
									onClick={() => {
										this.props.history.push(
											route_from(app, jobs, costEdit, `${job.codiceCommissione}`)
										)
									}}
								>
									<Icon name='credit card outline' />
									Aggiungi costo
								</Button>
							)}
							{external && (
								<Button
									color='green'
									onClick={() => {
										let { network } = this.props
										let { codiceCommissione } = job
										network.createJob(codiceCommissione, {
											codiceCommissione,
											creazioneEsterna: 0
										})
									}}
								>
									<Icon name='check square outline' />
									Accetta
								</Button>
							)}
							{external && (
								<Button
									color='yellow'
									onClick={() => {
										let { network } = this.props
										let { codiceCommissione } = job
										console.log('Trying to merge', codiceCommissione)
										this.props.history.push(
											route_from(app, tempJobs, jobMerge, `${job.codiceCommissione}`)
										)
									}}
								>
									<Icon name='random' />
									Unisci a commessa esistente
								</Button>
							)}
							<Button
								color='blue'
								onClick={() => {
									this.props.history.push(route_from(app, jobs, jobEdit, `${job.codiceCommissione}`))
								}}
							>
								<Icon name='edit' />
								Modifica
							</Button>
							{(!role || role === 'admin') && (
								<Button
									color='red'
									onClick={() => {
										this.openRemoveDialog()
									}}
								>
									<Icon name='delete' />
									Elimina
								</Button>
							)}
							{forcedOpen &&
							!external &&
							(!role || role === 'admin') && (
								<Button
									color='blue'
									onClick={() => {
										if (onOpened) {
											onOpened(job, true, () => {
												this.props.history.push(
													route_from(app, jobs, job.codiceCommissione, finalBalance)
												)
											})
										} else {
											this.props.history.push(
												route_from(app, jobs, job.codiceCommissione, finalBalance)
											)
										}
									}}
								>
									<Icon name='book' />
									Consuntivo
								</Button>
							)}
						</Card.Content>
					)}
					{type === 'full' && (
						<Transition
							visible={forcedOpen ? true : this.state.showDetails}
							animation='slide down'
							duration={200}
						>
							<Card.Content>
								<View fullw column>
									{this.renderMachineTable()}
									{this.renderWorkerTable()}
									{this.renderCostTable()}
									{!isBalance && this.renderWorkTable()}
									{/* {this.renderGT2000Table()} */}
									{/* {this.renderBabypressaTable()} */}
									{isBalance && [
										<Items
											type='grouped'
											onCostChange={(total, shorthand) => this.onCostChange(total, shorthand)}
											isBalance
											jobId={job.codiceCommissione}
										/>,
										<View className='no-print' around fullw row style={{ marginBottom: 16 }}>
											<Checkbox
												toggle
												value={totalWithItems}
												checked={totalWithItems}
												label='Includi materiale nel calcolo totale'
												onChange={(e, data) => {
													this.setState({ totalWithItems: data.checked }, () => {
														this.onCostChange(0, 0)
													})
												}}
											/>
										</View>,
										<View className='only-print' around fullw row style={{ marginBottom: 16 }}>
											<span>
												{totalWithItems ? (
													'Materiale incluso nel totale'
												) : (
													'Materiale non incluso nel totale'
												)}
											</span>
										</View>
									]}
									{isBalance && (
										<Table>
											<Table.Header fullWidth>
												<Table.Row>
													<Table.HeaderCell style={{ textAlign: 'right' }} width='4'>
														Totale ore
													</Table.HeaderCell>
													<Table.HeaderCell width='1' />
													<Table.HeaderCell style={{ textAlign: 'center' }} width='2'>
														Prezzo orario medio
													</Table.HeaderCell>
													{totalWithItems && <Table.HeaderCell width='1' />}
													{totalWithItems && (
														<Table.HeaderCell style={{ textAlign: 'center' }} width='2'>
															Costi aggiuntivi
														</Table.HeaderCell>
													)}
													{totalWithItems && <Table.HeaderCell width='1' />}
													{totalWithItems && (
														<Table.HeaderCell style={{ textAlign: 'center' }} width='2'>
															Costi materiale
														</Table.HeaderCell>
													)}
													<Table.HeaderCell width='1' />
													<Table.HeaderCell width='4'>Totale</Table.HeaderCell>
												</Table.Row>

												<Table.Row>
													<Table.Cell style={{ textAlign: 'right' }}>
														{floatTimeToString(totalhours)}
													</Table.Cell>
													<Table.Cell>x</Table.Cell>
													<Table.Cell style={{ textAlign: 'center' }}>
														{isNaN(averageHourlyCost) ? '--' : averageHourlyCost}€/h
													</Table.Cell>
													{totalWithItems && <Table.Cell>+</Table.Cell>}
													{totalWithItems && (
														<Table.Cell style={{ textAlign: 'center' }}>
															{!costDict['cost'] || isNaN(costDict['cost']) ? (
																'--'
															) : (
																costDict['cost']
															)}€
														</Table.Cell>
													)}
													{totalWithItems && <Table.Cell>+</Table.Cell>}
													{totalWithItems && (
														<Table.Cell style={{ textAlign: 'center' }}>
															{!costDict['item'] || isNaN(costDict['item']) ? (
																'--'
															) : (
																Math.round(costDict['item'] * 100) / 100
															)}€
														</Table.Cell>
													)}
													<Table.Cell>=</Table.Cell>
													<Table.Cell>{total}€</Table.Cell>
												</Table.Row>
											</Table.Header>
										</Table>
									)}
								</View>
							</Card.Content>
						</Transition>
					)}
				</Card>
			</View>
		)
	}
}

const compact_styles = {
	header: {
		fontSize: '20pt',
		lineHeight: 'normal'
	},
	imageContainer: {
		width: 50,
		height: 50
	},
	jobImage: {
		width: 50,
		height: 50,
		objectFit: 'contain'
	},
	jobContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	jobCard: {
		width: 200,
		minWidth: 200,
		height: 200,
		margin: 8
	}
}

const styles = {
	header: {
		fontSize: '40pt',
		lineHeight: 'normal'
	},
	imageContainer: {
		width: 200,
		height: 150,
		marginRight: 28
	},
	jobImage: {
		width: 200,
		height: 150,
		objectFit: 'contain'
	},
	jobContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	jobCard: {
		marginTop: 8,
		marginBottom: 8
	}
}

const mapStateToProps = (state, ownProps) => {
	let {
		getMappingData: { data: mappingData, fetching: fetchingMap, status: mapStatus },
		getBabypressaDataFilter,
		getGT2000DataFilter,
		getJob,
		login
	} = state
	let ruolo = null
	if (login && login.data && login.data.user) ruolo = login.data.user.ruolo

	return {
		mappingData,
		fetchingMap,
		mapStatus,
		getBabypressaDataFilter,
		getGT2000DataFilter,
		getJob,
		role: ruolo
	}
}

export default withRouter(connect(mapStateToProps)(Radium(connectNetwork(Job))))
