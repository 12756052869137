import { JOBLISTSTATE_TYPES } from '../actions/jobListState';
import reducer from './requests';
const Reducer = (
	state = {
		stored: false,
		state: {}
	},
	action
) => {
	switch (action.type) {
		case JOBLISTSTATE_TYPES.request: {
			let { result } = action;
			if (result === undefined) result = {};

			return {
				...state,
				stored: false,
				state: result
			};
		}
		case JOBLISTSTATE_TYPES.result: {
			let { result } = action;
			return {
				...state,
				stored: true,
				state: result
			};
		}
		case JOBLISTSTATE_TYPES.error: {
			let { result } = action;
			if (result === undefined) result = {};

			return {
				...state,
				stored: false,
				state: result
			};
		}
		default:
			return state;
	}
};
export default Reducer;
