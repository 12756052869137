export function getWorker(data) {
	let { getWorkerRequest, getWorkerError, getWorkerResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_worker',
		this.createBundle(
			'api/get_worker',
			{ id: data },
			this.GET,
			getWorkerRequest,
			getWorkerResult,
			getWorkerError
		)
	);
}
