export function getMachineList(search, isEmployee) {
	let { getMachineListRequest, getMachineListError, getMachineListResult } = this.props;
	
	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_machine_list',
		this.createBundle(
			'api/get_machine_list',
			{ search },
			this.GET,
			getMachineListRequest,
			getMachineListResult,
			getMachineListError,
			isEmployee
		)
	);
}
