import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { connectNetwork } from 'lib/NetworkProvider';
import { route_from, routes } from 'lib/routes';

import { Job } from '../components';

import View from 'lib/View';
import { Button, Form } from 'semantic-ui-react';
import { TOOLBAR_ACTIONS } from '../actions/toolbar';

const { vfconsole, app, employee, insertion, jobs } = routes;

class SingleJob extends Component {
	constructor(props) {
		super(props);
		let { job } = this.props.match.params;

		this.state = {
			job
		};
	}

	componentDidUpdate() {}

	componentDidMount() {}

	render() {
		let { job } = this.state;
		console.log('Loading job', job);
		return (
			<View column fullw fullh>
				<Job forcedOpen={true} showDetails={true} jobId={job} type="full" />
			</View>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch)
	};
};

const mapStateToProps = (state) => {
	return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(SingleJob))));

const styles = {};
