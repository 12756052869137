import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';
import { withRouter } from 'react-router';

import { Step, Icon, Button, Image, Card, Table, Input } from 'semantic-ui-react';
import View from 'lib/View';

import { Confirmation } from './';
import {} from 'containers';
import moment from 'moment-with-locales-es6';

import { timeString } from '../lib/util';

import { route_from, routes } from 'lib/routes';

const { app, jobs, finalBalance, jobEdit, costEdit, costUpdate } = routes;
moment.locale('it');

class Cost extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deleting: false,
			colors: [
				'red',
				'orange',
				'yellow',
				'olive',
				'green',
				'teal',
				'blue',
				'violet',
				'purple',
				'pink',
				'brown',
				'grey',
				'black'
			],
			...props.cost
		};

		let { onCostChange, cost } = props;

		if (onCostChange && cost) {
			onCostChange(cost.prezzoCosto, cost.idCosto);
		}
	}

	totalCost() {
		let { prezzoCosto } = this.state;
		return prezzoCosto;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { onCostChange } = this.props;
		let { idCosto, prezzoCosto } = this.state;
		if (prezzoCosto !== prevState.prezzoCosto) {
			if (onCostChange) {
				onCostChange(prezzoCosto, idCosto);
			}
		}
	}

	renderBalanceFooter() {
		let { total } = this.props;

		return (
			<Table.Row>
				<Table.Cell width="3" />
				<Table.Cell width="3" />
				<Table.Cell width="3" />
				<Table.Cell width="4" />
				<Table.Cell width="1">Totale</Table.Cell>
				<Table.Cell width="2">{total}€</Table.Cell>
			</Table.Row>
		);
	}

	renderBalanceHeader() {
		return (
			<Table.Header fullWidth>
				<Table.Row>
					<Table.HeaderCell width="3">Data</Table.HeaderCell>
					<Table.HeaderCell width="3">Descrizione</Table.HeaderCell>
					<Table.HeaderCell width="3">Fornitore</Table.HeaderCell>
					<Table.HeaderCell width="4">Prezzo</Table.HeaderCell>
					<Table.HeaderCell width="1" />
					<Table.HeaderCell width="2">Totale</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
		);
	}

	renderForBalance() {
		let { descrizioneCosto, prezzoCosto, dataCosto, fornitore } = this.state;
		return (
			<Table.Row>
				<Table.Cell>{moment(dataCosto).format('ll')}</Table.Cell>
				<Table.Cell>{descrizioneCosto}</Table.Cell>
				<Table.Cell>{fornitore}</Table.Cell>
				<Table.Cell>
					<span className="no-print">
						<Input
							type="number"
							step="0.01"
							value={prezzoCosto}
							onChange={(e, data) => {
								let value = data.value;
								if (value < 0) value = 0;
								this.setState({
									prezzoCosto: value
								});
							}}
						/>€
					</span>
					<span className="only-print">{prezzoCosto}€</span>
				</Table.Cell>
				<Table.Cell>=</Table.Cell>
				<Table.Cell>{this.totalCost()}€</Table.Cell>
			</Table.Row>
		);
	}

	renderTableFooter() {
		let { isBalance } = this.props;
		if (isBalance) return this.renderBalanceFooter();
		else return null;
	}

	renderTableHeader() {
		let { isBalance } = this.props;
		if (isBalance) {
			return this.renderBalanceHeader();
		} else {
			return (
				<Table.Header fullWidth>
					<Table.Row>
						<Table.HeaderCell>Data</Table.HeaderCell>
						<Table.HeaderCell>Descrizione</Table.HeaderCell>
						<Table.HeaderCell>Fornitore</Table.HeaderCell>
						<Table.HeaderCell>Prezzo</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
			);
		}
	}

	renderForTable() {
		let { deleting, idCosto, descrizioneCosto, prezzoCosto, dataCosto, fornitore } = this.state;
		let { type, isBalance } = this.props;

		if (isBalance) {
			return this.renderForBalance();
		} else {
			return (
				<Table.Row>
					<Confirmation
						message="Vuoi davvero eliminare questo costo?"
						showModal={deleting}
						onConfirm={() => {
							this.remove();
						}}
						onCancel={() => {
							this.setState({ deleting: false });
						}}
					/>
					<Table.Cell>{moment(dataCosto).format('ll')}</Table.Cell>
					<Table.Cell>{descrizioneCosto}</Table.Cell>
					<Table.Cell>{fornitore}</Table.Cell>
					<Table.Cell>{prezzoCosto}</Table.Cell>
					<Table.Cell width="4">
						<Button
							color="blue"
							onClick={() => {
								this.props.history.push(route_from(app, jobs, costUpdate, `${idCosto}`));
							}}
						>
							<Icon name="edit" />
							Modifica
						</Button>
						<Button
							color="red"
							onClick={() => {
								this.openRemoveDialog();
							}}
						>
							<Icon name="delete" />
							Elimina
						</Button>
					</Table.Cell>
				</Table.Row>
			);
		}
	}

	openRemoveDialog() {
		this.setState({ deleting: true });
	}

	remove() {
		let { idCosto } = this.state;
		let { network } = this.props;

		network.deleteCost({
			idCosto
		});

		this.setState({ deleting: false });
	}

	render() {
		let { deleting, idCosto, descrizioneCosto, prezzoCosto, dataCosto, fornitore } = this.state;
		let { type } = this.props;
		let extraprops = {
			fluid: type === 'full' ? true : false
		};

		let col = parseInt(Math.random() * this.state.colors.length);

		switch (type) {
			case 'footer':
				return this.renderTableFooter();
			case 'header':
				return this.renderTableHeader();
			case 'table':
				return this.renderForTable();
			default:
				return (
					<View fullw column between>
						<Card style={styles.costCard} {...extraprops} color={this.state.colors[col]}>
							<Confirmation
								message="Vuoi davvero eliminare questo costo?"
								showModal={deleting}
								onConfirm={() => {
									this.remove();
								}}
								onCancel={() => {
									this.setState({ deleting: false });
								}}
							/>
							<Card.Content style={styles.costContent}>
								<View fullw row>
									<View fullw column>
										<span style={styles.header}>{prezzoCosto}</span>
										<Card.Description>
											{descrizioneCosto}, {fornitore}
										</Card.Description>
										<Card.Meta style={{ textTransform: 'capitalize' }}>
											{moment(dataCosto).format('LLLL')}
										</Card.Meta>
									</View>
								</View>
							</Card.Content>
							<Card.Content extra>
								<a>
									<Icon name="cogs" />
									Dettagli
								</a>
							</Card.Content>
							<Card.Content extra>
								<Button
									color="blue"
									onClick={() => {
										this.props.history.push(route_from(app, jobs, costUpdate, `${idCosto}`));
									}}
								>
									<Icon name="edit" />
									Modifica
								</Button>
								<Button
									color="red"
									onClick={() => {
										this.openRemoveDialog();
									}}
								>
									<Icon name="delete" />
									Elimina
								</Button>
							</Card.Content>
						</Card>
					</View>
				);
		}
	}
}

const styles = {
	header: {
		fontSize: '40pt',
		lineHeight: 'normal'
	},
	imageContainer: {
		height: 128,
		width: 128,
		marginRight: 28
	},
	costImage: {
		height: 128,
		width: 128,
		objectFit: 'contain'
	},
	costContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	costCard: {
		marginTop: 8,
		marginBottom: 8
	}
};

const mapStateToProps = (state, ownProps) => {
	return {};
};
export default withRouter(connect(mapStateToProps)(Radium(connectNetwork(Cost))));
