export function getWorkListFilter(data) {
	let { getWorkListFilterRequest, getWorkListFilterError, getWorkListFilterResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_work_list_filter',
		this.createBundle(
			'api/get_work_list_filter',
			{ data: data },
			this.GET,
			getWorkListFilterRequest,
			getWorkListFilterResult,
			getWorkListFilterError
		)
	);
}
