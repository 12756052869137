export function createMachine(data) {
	let { createMachineRequest, createMachineError, createMachineResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/create_machine',
		this.createBundle(
			'api/create_machine',
			{ machine: data },
			this.POST,
			createMachineRequest,
			createMachineResult,
			createMachineError
		)
	);
}
