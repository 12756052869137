import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';
import { withRouter } from 'react-router';

import { Statistic, Label, Popup, Segment, Icon } from 'semantic-ui-react';
import View from 'lib/View';

import { Works } from '../containers';

import { floatTimeToString } from '../lib/util';
import { route_from, routes, get_section } from '../lib/routes';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS, WORKDAY_ACTIONS } from '../actions/workday';

import moment from 'moment-with-locales-es6';
moment.locale('it');

const { vfconsole, app, employee, insertion, jobs } = routes;

class Calendar extends Component {
	constructor(props) {
		super(props);
		let { location: { pathname } } = this.props.history;
		let { isEmployee } = this.props;

		this.state = {
			sidemenu: get_section(pathname),
			monthStart: moment().startOf('month'),
			monthEnd: moment().endOf('month'),
			selected: {}
		};
	}

	today() {
		let mom = moment();
		this.setState({
			monthStart: moment(mom).startOf('month'),
			monthEnd: moment(mom).endOf('month')
		});
	}

	nextMonth() {
		let mom = moment(this.state.monthStart).endOf('week').add(1, 'months');
		this.setState({
			monthStart: moment(mom).startOf('month'),
			monthEnd: moment(mom).endOf('month')
		});
	}

	prevMonth() {
		let mom = moment(this.state.monthStart).endOf('week').subtract(1, 'months');
		this.setState({
			monthStart: moment(mom).startOf('month'),
			monthEnd: moment(mom).endOf('month')
		});
	}

	componentDidMount() {
		let { network, worker, sub } = this.props;

		network.getWorkListSpan(
			this.state.monthStart.format('YYYY-MM-DD'),
			this.state.monthEnd.format('YYYY-MM-DD'),
			worker.id,
			true
		);

		if (!sub) {
			this.props.toolbarResult([
				{
					name: 'Oggi',
					icon: 'calendar outline',
					position: 'left',
					action: () => {
						this.today();
					}
				},
				{
					name: '',
					icon: 'arrow left',
					position: 'right',
					action: () => {
						this.prevMonth();
					}
				},
				{
					name: '',
					icon: 'arrow right',
					position: 'none',
					action: () => {
						this.nextMonth();
					}
				}
			]);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { monthStart, monthEnd } = this.state;
		let { network, worker } = this.props;

		if (!monthStart.isSame(prevState.monthStart) || !monthEnd.isSame(prevState.monthEnd)) {
			network.getWorkListSpan(
				this.state.monthStart.format('YYYY-MM-DD'),
				this.state.monthEnd.format('YYYY-MM-DD'),
				worker.id,
				true
			);
		}
	}

	pressDay(day) {
		this.props.workdayResult(day);
		this.props.history.replace(route_from(employee, insertion));
	}

	renderDays(days) {
		let { workday, worker, worksByDay, totalPerDay } = this.props;

		return days.map((value, index) => {
			let datestring = value.date.format('YYYY-MM-DD');
			let totalTime = 0;
			if (totalPerDay && totalPerDay[datestring]) {
				totalTime =
					totalPerDay[datestring].totalWorkerTime[0] + totalPerDay[datestring].totalWorkerTime[1] / 60;
			}
			/*
			console.log(
				'Rendering day',
				datestring,
				'hours:',
				totalTime,
				'minimum:',
				worker.dailyHours,
				totalTime < worker.dailyHours
			);*/

			return (
				<Segment
					style={{
						margin: 0,
						padding: 0,
						flex: 1,
						height: '100%'
					}}
					color={value.color}
					onClick={() => {
						this.pressDay(value);
					}}
				>
					<View column fullw fullh>
						<View row fullw style={{ justifyContent: 'flex-start' }}>
							<Statistic style={{ margin: 0 }} color={value.color}>
								<Statistic.Value>{value.date.format('DD')}</Statistic.Value>
								<Statistic.Label>
									{value.date.format('MM')}/{value.date.format('YYYY')}
								</Statistic.Label>
							</Statistic>
							<Statistic.Label>{value.date.format('dddd')}</Statistic.Label>
							{totalTime < worker.dailyHours && (
								<Popup trigger={<Icon color="yellow" style={{ marginLeft: 4 }} name="warning sign" />}>
									<Popup.Header>Registrate: {floatTimeToString(totalTime)}</Popup.Header>
									<Popup.Content>
										Registra almeno {worker.dailyHours} ore per rimuovere questo avviso.
									</Popup.Content>
								</Popup>
							)}
						</View>
						<View column fullw fullh style={{ overflow: 'hidden' }}>
							<Works
								isEmployee
								sub
								fullh
								compact
								childProps={{ hideDate: true, hideWorker: true, hideDescription: true }}
								style={{ width: '100%' }}
								worker={worker.id}
								fixedWorks={worksByDay && worksByDay[datestring] ? worksByDay[datestring] : []}
							/>
						</View>
					</View>
				</Segment>
			);
		});
	}

	renderWeek(days, number) {
		let digrow = days.slice(number * 7, number * 7 + 7);

		return (
			<View fullw fullh row>
				{this.renderDays(digrow)}
			</View>
		);
	}

	render() {
		let { onChange, value, label, placeholder } = this.props;
		let { monthStart, monthEnd } = this.state;

		let left = monthStart.startOf('week');
		let right = monthEnd.endOf('week');
		let diff = right.diff(left, 'days');
		let weeks = Math.ceil(diff / 7);
		let days = [];
		for (let m = moment(left); m.isBefore(right); m.add(1, 'days')) {
			days.push({
				date: moment(m),
				color: m.day() === 0 || m.day() === 6 ? 'red' : 'grey',
				key: moment(m).format('YYYY-MM-DD')
			});
		}
		let calendar = days;

		let elements = [];
		for (let i = 0; i < weeks; i++) {
			let w = this.renderWeek(days, i);
			elements.push(w);
		}

		return (
			<View fullw column around style={{ padding: 16 }}>
				<View fullw around column>
					<Label
						style={{ textTransform: 'capitalize', fontSize: '24pt', textAlign: 'center', borderRadius: 0 }}
					>
						{moment(monthEnd).startOf('week').format('MMMM')}
					</Label>
				</View>
				<View style={styles.container} column fullw fullh>
					{elements}
				</View>
			</View>
		);
	}
}

const styles = {
	container: {
		margin: '0px auto'
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		workdayRequest: (result) => WORKDAY_ACTIONS.request(result, dispatch),
		workdayResult: (result) => WORKDAY_ACTIONS.result(result, dispatch),
		workdayError: (result) => WORKDAY_ACTIONS.error(result, dispatch)
	};
};

const mapStateToProps = (state, ownProps) => {
	let { getWorkListSpan: { data: { data: worksByDay } } } = state;

	let totalPerDay = {};
	for (let date in worksByDay) {
		totalPerDay[date] = {
			totalWorkerTime: [ 0, 0 ],
			totalMachineTime: [ 0, 0 ]
		};
		let works = worksByDay[date];

		for (let work of works) {
			totalPerDay[date].totalWorkerTime[0] += work.oreOperaio;
			totalPerDay[date].totalWorkerTime[1] += work.minutiOperaio;
			totalPerDay[date].totalMachineTime[0] += work.oreMacchina;
			totalPerDay[date].totalMachineTime[1] += work.minutiMacchina;
		}

		totalPerDay[date].totalWorkerTime[0] += Math.floor(totalPerDay[date].totalWorkerTime[1] / 60);
		totalPerDay[date].totalWorkerTime[1] = totalPerDay[date].totalWorkerTime[1] % 60;
		totalPerDay[date].totalMachineTime[0] += Math.floor(totalPerDay[date].totalMachineTime[1] / 60);
		totalPerDay[date].totalMachineTime[1] = totalPerDay[date].totalMachineTime[1] % 60;
	}

	//console.log('Calendar getWorkListSpan:', totalPerDay);

	return {
		worksByDay,
		totalPerDay
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(Calendar))));
