
import { CREATEWORKER_TYPES } from '../actions/createWorker'
import reducer, { defaultState } from './requests'
const Reducer = (state = defaultState, action) => {
    let requestReducer = reducer(CREATEWORKER_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}
export default Reducer