export function updateMachineType(data) {
	let { updateMachineTypeRequest, updateMachineTypeError, updateMachineTypeResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/update_machine_type',
		this.createBundle(
			'api/update_machine_type',
			{ machinetype: data },
			this.POST,
			updateMachineTypeRequest,
			updateMachineTypeResult,
			updateMachineTypeError
		)
	);
}
