export function getMachine(data) {
	let { getMachineRequest, getMachineError, getMachineResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_machine',
		this.createBundle(
			'api/get_machine',
			{ id: data },
			this.GET,
			getMachineRequest,
			getMachineResult,
			getMachineError
		)
	);
}
