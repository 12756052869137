export function createMapping(data) {
	let { createMappingRequest, createMappingError, createMappingResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/create_mapping_list',
		this.createBundle(
			'api/create_mapping_list',
			{ mapping: data },
			this.POST,
			createMappingRequest,
			createMappingResult,
			createMappingError
		)
	);
}
