import React, { Component } from 'react'
import Radium from 'radium'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'

import {} from 'semantic-ui-react'

import View from 'lib/View'
import { SWA300Data, CustomTable } from '../components'

import { Placeholder, Card } from 'semantic-ui-react'

import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { SEARCH_ACTIONS } from '../actions/search'

import Iframe from 'react-iframe'

import moment from 'moment-with-locales-es6'
import { route_from, routes } from 'lib/routes'
const { app, saldatrice, saldatriceeditor } = routes
moment.locale('it')

class SWA300 extends Component {
	constructor(props) {
		super(props)

		this.state = {
			start: moment().subtract(1, 'day'),
			end: moment()
		}
	}

	prev() {
		// todo moment subtract
	}

	next() {
		// todo moment add
	}

	componentDidMount() {
		let { sub, network } = this.props
		if (!sub) {
			this.props.toolbarResult([
				{
					name: 'Invia dati a SWA300',
					icon: 'add',
					action: () => {
						this.props.history.push(route_from(app, saldatrice, saldatriceeditor))
					}
				},
				{
					name: '',
					icon: 'arrow left',
					position: 'right',
					action: () => {
						this.prev()
					}
				},
				{
					name: '',
					icon: 'arrow right',
					position: 'none',
					action: () => {
						this.next()
					}
				}
			])
		}

		let start = this.state.start.format('YYYY-MM-DD HH:mm:ss')
		let end = this.state.end.format('YYYY-MM-DD HH:mm:ss')

		console.log('Start and end are:', start, end)

		network.getLastSWA300Data()
		network.getSWA300Data(start, end)
	}

	renderData(key, swa300data) {
		return <SWA300Data type='full' key={`swa300data_${key}`} swa300data={swa300data} />
	}

	render() {
		let { fetching, swa300datalist, getLastSWA300Data, ...rest } = this.props
		let content = <div />

		if (fetching) {
			let placeholders = []
			for (let i = 0; i < 10; i++) {
				placeholders.push(
					<Card key={i} style={{ width: '100%', margin: 8 }}>
						<Card.Content>
							<Placeholder>
								<Placeholder.Header image>
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Header>
								<Placeholder.Paragraph>
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Paragraph>
							</Placeholder>
						</Card.Content>
					</Card>
				)
			}
			content = (
				<View fullw column>
					{placeholders}
				</View>
			)
		} else if (swa300datalist.data) {
			console.log('swa300datalist is', swa300datalist.data)

			content = (
				<CustomTable name='SWA300Data' shorthand='swa300data' values={swa300datalist.data} child={SWA300Data} />
			)
		}

		return (
			<View fullw fullh column>
				{content}
			</View>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let { getLastSWA300Data, getSWA300Data } = state
	return {
		fetching: getSWA300Data.fetching,
		swa300datalist: getSWA300Data.data,
		getSWA300Data
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(SWA300))))
