import React, { Component } from 'react'
import Radium from 'radium'

//router
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { route_from, routes } from 'lib/routes'
import { withRouter } from 'react-router'
// store
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from 'reducers/reducers'

import { connect } from 'react-redux'

// network provider
import Network from 'lib/NetworkProvider'
import { connectNetwork } from 'lib/NetworkProvider'
import { Main, Login } from 'containers'

import { CookiesProvider } from 'react-cookie'

import MetaTags from 'react-meta-tags'

const { vfconsole, app, jobs, login, loginalt, employee, pin, insertion } = routes
const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

class PrivateRoute extends Component {
	constructor(props) {
		super(props)
		let { location: { pathname } } = this.props
		this.state = {
			pathToRestore: pathname
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { isEmployee, location: { pathname }, login, loginEmployee, history } = this.props
		let { pathToRestore } = this.state

		// http://localhost:3000/VFStampi/Commesse/
		if (
			(login.authenticated !== prevProps.login.authenticated && login.authenticated && !isEmployee) ||
			(loginEmployee.authenticated !== prevProps.loginEmployee.authenticated &&
				loginEmployee.authenticated &&
				isEmployee)
		) {
			if (pathToRestore !== '' && pathToRestore !== '/' && pathToRestore !== '/Impiegato') {
				history.replace(this.state.pathToRestore)
				this.setState({ pathToRestore: '' })
			} else {
				if (isEmployee) {
					history.replace(route_from(employee, insertion))
				} else {
					history.replace(route_from(app, jobs))
				}
			}
		}
	}

	render() {
		let {
			component,
			isEmployee,
			render,
			login: loginStatus,
			loginEmployee: loginEmployeeStatus,
			...rest
		} = this.props
		let Target = component

		return (
			<Route
				{...rest}
				render={(props) => {
					if (!isEmployee) {
						if (loginStatus.authenticated) {
							return component ? <Target {...props} /> : render()
						} else {
							return <Redirect to={route_from(login)} />
						}
					} else {
						if (loginEmployeeStatus.authenticated) {
							return component ? <Target isEmployee {...props} /> : render()
						} else {
							return <Redirect to={route_from(employee)} />
						}
					}
				}}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	let { login, loginEmployee } = state

	return {
		login,
		loginEmployee
	}
}

const PrivateRouteConnected = withRouter(connect(mapStateToProps)(Radium(connectNetwork(PrivateRoute))))

class App extends Component {
	render() {
		let hostname = window.location.hostname
		let location = window.location.pathname
		//console.log('routes:', route_from(app));
		//console.log('routes:', route_from(employee));
		let adminHost = true
		let employeeHost = true
		if (hostname === 'amministrazione.vfstampi.syncode.it') {
			employeeHost = false
		}

		if (hostname === 'impiegati.vfstampi.syncode.it') {
			adminHost = false
		}

		if (!employeeHost && location.startsWith(route_from(employee))) {
			window.location = route_from(app)
		}

		if (!adminHost && !location.startsWith(route_from(employee))) {
			window.location = route_from(employee)
		}

		let title = 'Amministrazione | Gestionale V.F. Stampi'
		if (location.startsWith(route_from(employee))) {
			title = 'Inserimento Ore | Gestionale V.F. Stampi'
		}

		/*
		{!employeeHost && <Redirect exact from={route_from(employee)} to={route_from(login)} />}
		{!adminHost && <Redirect from={route_from(app)} to={route_from(employee)} />}

		{!employeeHost && location.startsWith(route_from(employee)) && <Redirect exact to={route_from(login)} />}
		{!adminHost && location.startsWith(route_from(app)) && <Redirect to={route_from(employee)} />}
		*/
		return (
			<CookiesProvider>
				<Provider store={store}>
					<Network>
						<Router>
							<MetaTags>
								<title>{title}</title>
								<meta name='description' content='Gestionale V.F. Stampi' />
								<meta property='og:title' content='Gestionale V.F. Stampi' />
								<meta property='og:image' content='path/to/image.jpg' />
							</MetaTags>

							{adminHost && <Route exact path={route_from(login)} component={Login} />}
							{employeeHost && (
								<Route exact path={route_from(employee)} render={() => <Login isEmployee />} />
							)}
							{adminHost && <PrivateRouteConnected path={route_from(app)} component={Main} />}
							{employeeHost && (
								<PrivateRouteConnected
									isEmployee
									path={route_from(employee, insertion)}
									render={() => <Main isEmployee />}
								/>
							)}
						</Router>
					</Network>
				</Provider>
			</CookiesProvider>
		)
	}
}

export default Radium(App)
