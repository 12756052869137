import React, { Component } from 'react'
import Radium from 'radium'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'

import { Placeholder, Card, Form, Select } from 'semantic-ui-react'

import View from 'lib/View'

import { Job, Empty } from '../components'
import { rainbow } from '../lib/util'
import { debounce } from 'throttle-debounce'

import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { SEARCH_ACTIONS } from '../actions/search'
import { JOBLISTSTATE_ACTIONS } from '../actions/jobListState'

import loadingImage from '../assets/loadingParagraph.png'

import { route_from, routes } from 'lib/routes'
const { app, jobs, jobEdit, newEl } = routes

class Jobs extends Component {
	constructor(props) {
		super(props)
		let type = this.props.type ? this.props.type : 'full'
		let direction = this.props.direction ? this.props.direction : 'column'
		let select = !!this.props.select
		let searchable = !!this.props.searchable
		let sub = !!this.props.sub

		let updateSearch = debounce(200, false, (page, items, selectSearch, isEmployee) => {
			console.log('Updating with page', page)
			this.state.network.getDetailedJobList(page, items, selectSearch, isEmployee)
		})

		console.log('Jobs constructor')
		this.state = {
			page: 0,
			items: 10,
			type,
			direction,
			select,
			searchable,
			selectedJob: {},
			jobOptions: [],
			selectSearch: '',
			selectOpen: false,
			firstTime: true,
			opened: {},
			scrollTop: 0,
			sub,
			onLoaded: null,
			selected: this.props.selected ? this.props.selected : {},
			updateSearch
		}
	}

	store() {
		let {
			page,
			items,
			type,
			direction,
			select,
			searchable,
			selectedJob,
			jobOptions,
			selectSearch,
			selectOpen,
			firstTime,
			sub,
			opened,
			selected,
			scrollTop
		} = this.state

		let toStore = {
			page,
			items,
			type,
			direction,
			select,
			searchable,
			selectedJob,
			jobOptions,
			selectSearch,
			selectOpen,
			firstTime,
			sub,
			opened,
			selected,
			scrollTop,
			createdExternal: !!this.props.createdExternal
		}

		this.props.storeJobListState(toStore)
	}

	restore(state) {
		console.log(
			'job list scrolling to Restoring state',
			state,
			this.props.createdExternal,
			state.createdExternal === !!this.props.createdExternal
		)
		let restored = false
		if (state.createdExternal === !!this.props.createdExternal) {
			console.log('Restoring the STATE')
			this.setState(state)
			restored = true
		}
		this.props.clearJobListState({})
		return restored
	}

	componentWillUnmount() {
		let { select } = this.props
		if (!select) {
			this.store()
		}
		this.state.updateSearch.cancel()
	}

	componentDidMount() {
		let { sub, select, searchable, selectSearch, search: { data: searchText }, scrollTop, page, items } = this.state
		let { isEmployee, jobListState, workerId, createdExternal, network } = this.props

		console.log('componentDidMount page is', page)
		network.getMappingData(isEmployee);

		if (jobListState && jobListState.stored) {
			if (this.restore(jobListState.state)) {
				;({ sub, select, searchable, selectSearch, page, items, scrollTop } = jobListState.state)

				if (scrollTop > 0) {
					let scrollTarget = scrollTop //Math.ceil(scrollTop / 311) * 311;
					this.setState({
						scrollTarget,
						onLoaded: () => {
							let { scrollTarget } = this.state
							let container = document.getElementsByClassName('jobListContainer')[0]

							setTimeout(() => {
								console.log('job list scrolling to', scrollTarget)
								container.scrollTo({ top: scrollTarget, left: 0, behavior: 'smooth' })
							}, 1000)
						}
					})
				}
			}
		}

		if (!searchable) {
			searchText = ''
		}

		if (select) {
			searchText = selectSearch
		}

		this.setToolbar()

		console.log('Updating with page', page)
		if (isEmployee) {
			this.state.network.getRecentJobList(page, items, searchText, workerId, !!isEmployee, createdExternal)
		}
		else {
			this.state.network.getDetailedJobList(page, items, searchText, !!isEmployee, createdExternal)
		}
	}

	setToolbar() {
		let { sub, pageCount } = this.props
		let { page: currentPage } = this.state
		if (!sub) {
			let pageItems = [
				{
					name: `${parseInt(currentPage) + 1}`,
					position: 'none',
					type: 'text'
				} /*,
				{
					name: `${pageCount}`,
					position: 'none',
					type: 'text'
				}*/
			]

			this.props.toolbarResult([
				{
					name: 'Crea nuova commessa',
					icon: 'add',
					action: () => {
						this.props.history.push(route_from(app, jobs, newEl, jobEdit))
					}
				},
				{
					name: '',
					icon: 'arrow left',
					position: 'right',
					action: () => {
						this.prev()
					}
				},
				...pageItems,
				{
					name: '',
					icon: 'arrow right',
					position: 'none',
					action: () => {
						this.next()
					}
				}
			])
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let {
			page,
			items,
			search,
			select,
			selectSearch,
			network,
			getDetailedJobList,
			deleteJob,
			firstTime,
			onLoaded
		} = this.state
		let { isEmployee, jobListState, workerId, createdExternal } = this.props

		if (page !== prevState.page) {
			this.setToolbar()
		}

		if (this.props.createdExternal !== prevProps.createdExternal) {
			this.setState({ page: 0 })
		}

		if (
			!select &&
			(page != prevState.page ||
				items != prevState.items ||
				(deleteJob.fetching !== prevState.deleteJob.fetching && !deleteJob.fetching) ||
				(this.props.deletingCost !== prevProps.deletingCost &&
					this.props.deleteCostResult !== prevProps.deleteCostResult &&
					this.props.deleteCostStatus.success) ||
				(this.props.creatingJob !== prevProps.creatingJob &&
					this.props.createResult !== prevProps.createResult &&
					this.props.createStatus.success))
		) {
			console.log('Updating with page', page)
			if (isEmployee) {
				this.state.network.getRecentJobList(page, items, search.data, workerId, isEmployee, createdExternal)
			}
			else {
				network.getDetailedJobList(page, items, search.data, isEmployee, createdExternal)
			}
		}
		else if (!select && search != prevState.search) {
			console.log('Updating with page', page)
			if (isEmployee) {
				this.state.network.getRecentJobList(page, items, search.data, workerId, isEmployee, !!createdExternal)
			}
			else {
				network.getDetailedJobList(page, items, search.data, isEmployee, createdExternal)
			}
		}
		else if (select && getDetailedJobList !== prevState.getDetailedJobList) {
			if (getDetailedJobList.status.success) {
				let jobOptions = []
				let data = getDetailedJobList.data.data
				for (let i in data) {
					let job = data[i][0]
					jobOptions.push({
						text: job.codiceCommissione,
						key: job.codiceCommissione,
						value: job
					})
				}

				this.setState({ jobOptions, selectedJob: jobOptions[0], selectOpen: false })
			}
		}
		else if (select && selectSearch != prevState.selectSearch) {
			this.state.updateSearch(page, items, selectSearch, isEmployee)
		}

		if (!getDetailedJobList.fetching && prevState.getDetailedJobList.fetching) {
			if (onLoaded) {
				onLoaded()
				this.setState({ onLoaded: null })
			}
			if (firstTime) {
				this.setState({ firstTime: false })
			}
			else {
				setTimeout(() => {
					this.setState({ selectOpen: true })
				}, 200)
			}
		}
	}

	goToPage(page) {
		this.setState({
			page
		})
	}

	next() {
		this.setState({
			page: this.state.page + 1
		})
	}

	prev() {
		if (this.state.page === 0) return

		this.setState({
			page: this.state.page - 1
		})
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//console.log('Get derived state from props', nextProps);
		return nextProps
	}

	renderJob(key, worklist) {
		let { selected, opened } = this.state
		let { noActions, selectable, onChange } = this.props
		let extraProps = {
			noActions: noActions
		}
		if (selectable) {
			extraProps.onClick = (job) => {
				if (onChange) {
					onChange(job)
					this.setState({
						selected: job
					})
				}
			}
		}

		if (worklist.length === 0) return null

		return (
			<Job
				{...extraProps}
				onOpened={(job, open, callback) => {
					let { opened } = this.state
					//opened[job.codiceCommissione] = open;
					let scrollTop = document.getElementById(`${job.codiceCommissione}`).offsetTop - 100

					this.setState(
						{
							//opened,
							scrollTop
						},
						callback
					)
				}}
				showDetails={opened[worklist[0].codiceCommissione]}
				type={this.state.type}
				key={key}
				job={worklist[0]}
				works={worklist}
				selected={onChange && selected && selected.codiceCommissione === worklist[0].codiceCommissione}
			/>
		)
	}

	renderSelect() {
		let { jobOptions, selectedJob, selectOpen, selectSearch } = this.state
		let { onChange } = this.props

		return (
			<Form>
				<Form.Field
					control={Select}
					options={jobOptions}
					label="Commessa"
					placeholder="Commessa"
					value={selectedJob}
					search
					searchQuery={selectSearch}
					open={selectOpen}
					onSearchChange={(e, data) => {
						this.setState({
							selectSearch: data.searchQuery
						})
					}}
					clearable
					onClick={() => {
						this.setState({ selectOpen: true })
					}}
					onFocus={() => {
						this.setState({ selectOpen: true })
					}}
					onChange={(e, data) => {
						if (onChange) {
							onChange(data.value)
						}
						this.setState({
							selectSearch: data.value.codiceCommissione,
							selectedJob: data.value,
							selectOpen: false,
							firstTime: true
						})
					}}
					onBlur={() => {
						this.setState({ selectOpen: false })
					}}
					searchInput={{ id: 'form-select-control-job' }}
				/>
			</Form>
		)
	}

	render() {
		let { select: _s, direction: _d, type: _t, network, noActions, selectable, ...rest } = this.props
		let { getDetailedJobList, getJobList, direction, type, select } = this.state
		let content = <div />

		if (select) {
			content = this.renderSelect()
		}
		else if (getDetailedJobList.fetching || getJobList.fetching) {
			let placeholders = []
			for (let i = 0; i < 10; i++) {
				placeholders.push(
					<Card key={i} style={{ width: '100%', margin: 8 }}>
						<Card.Content>
							<Placeholder>
								<Placeholder.Header image>
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Header>
								<Placeholder.Paragraph>
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Paragraph>
							</Placeholder>
						</Card.Content>
					</Card>
				)
			}
			content = (
				<View fullw column>
					{placeholders}
				</View>
			)
		}
		else if (!select && (getDetailedJobList.data.data, getDetailedJobList.data.count > 0)) {
			content = Object.keys(getDetailedJobList.data.data).map((key) => {
				let value = getDetailedJobList.data.data[key]
				return this.renderJob(key, value)
			})
		}
		else {
			content = <Empty />
		}

		let dirobj = {
			[direction === 'row' ? 'overflowX' : 'overflowY']: 'scroll',
			[direction === 'row' ? 'overflowY' : 'overflowX']: 'hidden'
		}

		return (
			<View
				className="jobListContainer"
				fullw
				fullh
				{...{ column: direction === 'column', row: direction === 'row' }}
				style={{
					paddingLeft: 20,
					paddingRight: 20,
					...(select ? {} : dirobj)
				}}
				{...rest}
			>
				{content}
			</View>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let {
		getDetailedJobList,
		getRecentJobList,
		getJobList,
		deleteJob,
		jobListState,
		createJob: { data: createResult, fetching: creatingJob, status: createStatus },
		deleteCost: { data: deleteCostResult, fetching: deletingCost, status: deleteCostStatus },
		getMappingData,
		search
	} = state
	let { isEmployee } = ownProps

	return {
		getDetailedJobList: isEmployee ? getRecentJobList : getDetailedJobList,
		getJobList,
		deleteJob,
		jobListState,
		search,
		deleteCostResult,
		deletingCost,
		deleteCostStatus,
		createResult,
		creatingJob,
		createStatus,
		pageCount: getDetailedJobList.data.paging ? getDetailedJobList.data.paging.pagecount : 0
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

		storeJobListState: (result) => JOBLISTSTATE_ACTIONS.result(result, dispatch),
		clearJobListState: (result) => JOBLISTSTATE_ACTIONS.error(result, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(Jobs))))

/*
			let pageItems = [];
			currentPage = parseInt(currentPage);
			if (pageCount > 10) {
				let middle = Math.floor(pageCount / 2);
				console.log('Current page is', currentPage);
				middle = currentPage > 2 && currentPage < pageCount - 2 ? currentPage + 1 : middle;
				let pages = [
					1,
					2,
					3,
					'...',
					middle - 1,
					middle,
					middle + 1,
					'...',
					pageCount - 2,
					pageCount - 1,
					pageCount
				];

				if (middle + 1 === pageCount - 2) {
					pages.splice(6, 1);
				}

				if (middle - 1 === 3) {
					pages.splice(2, 1);
				}

				for (let page of pages) {
					let number = parseInt(page);
					let icon = '';
					if (isNaN(page)) {
						number = -1;
					}

					let item = {
						name: page,
						position: 'none',
						selected: () => {
							return currentPage === page - 1;
						},
						action: () => {
							if (number < 0) return;

							this.goToPage(number - 1);
						}
					};

					if (isNaN(page)) {
						item.icon = 'ellipsis horizontal';
					}
					pageItems.push(item);
				}
			} else if (pageCount > 0) {
				for (let i = 0; i < pageCount; i++) {
					pageItems.push({
						name: `${i + 1}`,
						position: 'none',
						selected: () => {
							return currentPage === i;
						},
						action: () => {
							this.goToPage(i);
						}
					});
				}
			}*/
