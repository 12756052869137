import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { Step, Icon, Button, Popup, Card, Table, Segment } from 'semantic-ui-react';
import View from 'lib/View';

import {} from 'containers';
import moment from 'moment-with-locales-es6';

import { timeString } from 'lib/util';

moment.locale('it');

class Work extends Component {
	constructor(props) {
		super(props);
		this.state = {
			colors: [
				'red',
				'orange',
				'yellow',
				'olive',
				'green',
				'teal',
				'blue',
				'violet',
				'purple',
				'pink',
				'brown',
				'grey',
				'black'
			]
		};
	}

	renderTableHeader() {
		let {
			hideDate,
			hideDescription,
			hideWorker,
			hideWorkerHours,
			hideMachine,
			hideMachineHours,
			hideJob
		} = this.props;

		return (
			<Table.Header fullWidth>
				<Table.Row>
					{!hideDate && <Table.HeaderCell>Data lavoro</Table.HeaderCell>}
					{!hideJob && <Table.HeaderCell>Commissione</Table.HeaderCell>}
					{!hideDescription && <Table.HeaderCell>Descrizione</Table.HeaderCell>}
					{!hideWorker && <Table.HeaderCell>Impiegato</Table.HeaderCell>}
					{!hideWorkerHours && <Table.HeaderCell>Ore Impiegato</Table.HeaderCell>}
					{!hideMachine && <Table.HeaderCell>Macchina</Table.HeaderCell>}
					{!hideMachineHours && <Table.HeaderCell>Ore Macchina</Table.HeaderCell>}
				</Table.Row>
			</Table.Header>
		);
	}

	// style={this.props.selected ? styles.rowSelected : {}}

	renderForTable() {
		let {
			hideDate,
			hideJob,
			hideDescription,
			hideWorker,
			hideWorkerHours,
			hideMachine,
			hideMachineHours,
			type,
			work
		} = this.props;

		return (
			<Table.Row
				onClick={() => {
					if (this.props.onClick) {
						this.props.onClick(this.props.work);
					}
				}}
				className={this.props.selected ? 'rowSelected' : ''}
			>
				{!hideDate && <Table.Cell>{moment(work.dataLavoro).format('ll')}</Table.Cell>}
				{!hideJob && <Table.Cell>{work.codiceCommissione}</Table.Cell>}
				{!hideDescription && <Table.Cell>{work.descrizioneLavoro ? work.descrizioneLavoro : work.descrizione}</Table.Cell>}
				{!hideWorker && <Table.Cell>{work.nomeImpiegato}</Table.Cell>}
				{!hideWorkerHours && <Table.Cell>{timeString(work.oreOperaio, work.minutiOperaio)}</Table.Cell>}
				{!hideMachine && <Table.Cell>{work.nomeMacchina}</Table.Cell>}
				{!hideMachineHours && <Table.Cell>{timeString(work.oreMacchina, work.minutiMacchina)}</Table.Cell>}
			</Table.Row>
		);
	}

	renderCompact() {
		let { work } = this.props;
		
		return (
			<View row style={{ width: '50%', alignSelf: 'center', textAlign: 'left' }}>
				<Popup
					trigger={
						<div style={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap' }}>
							<b style={{ whiteSpace: 'nowrap' }}>
								{work.codiceCommissione.length > 5 ? (
									work.codiceCommissione.substring(0, 5)
								) : (
									work.codiceCommissione
								)}
							</b>{' '}
							-{' '}
							<b>
								{work.oreOperaio}
								{work.minutiOperaio > 0 ? `:${work.minutiOperaio}` : ''}
							</b>{' '}
							|{' '}
							<b>
								{work.oreMacchina}
								{work.minutiMacchina > 0 ? `:${work.minutiMacchina}` : ''}
							</b>
						</div>
					}
				>
					<Popup.Header>{work.codiceCommissione}</Popup.Header>
					<Popup.Content>
						<div>{work.descrizioneLavoro ? work.descrizioneLavoro : work.descrizione}</div>
						<div>Ore operaio: {timeString(work.oreOperaio, work.minutiOperaio)}</div>
						<div>Ore macchina: {timeString(work.oreMacchina, work.minutiMacchina)}</div>
					</Popup.Content>
				</Popup>
			</View>
		);
	}

	render() {
		let { type, work } = this.props;
		let extraprops = {
			fluid: type === 'full' ? true : false
		};

		let col = parseInt(Math.random() * this.state.colors.length);

		switch (type) {
			case 'compact':
				return this.renderCompact();
			case 'header':
				return this.renderTableHeader();
			case 'table':
				return this.renderForTable();
			default:
				return (
					<View fullw column between>
						<Card style={styles.jobCard} {...extraprops} color={this.state.colors[col]}>
							<Card.Content style={styles.jobContent}>
								<View fullw row>
									<View fullw column>
										<span style={styles.header}>{work.descrizioneLavoro ? work.descrizioneLavoro : work.descrizione}</span>
										<Card.Description>{work.codiceCommissione}</Card.Description>
										<Card.Meta style={{ textTransform: 'capitalize' }}>
											{moment(work.dataCreazione).format('LLLL')}
										</Card.Meta>
									</View>
								</View>
							</Card.Content>
							<Card.Content extra>
								<a>
									<Icon name="cogs" />
									Dettagli
								</a>
							</Card.Content>
						</Card>
					</View>
				);
		}
	}
}

const styles = {
	header: {
		fontSize: '40pt',
		lineHeight: 'normal'
	},
	imageContainer: {
		height: 128,
		width: 128,
		marginRight: 28
	},
	jobImage: {
		height: 128,
		width: 128,
		objectFit: 'contain'
	},
	jobContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	jobCard: {
		marginTop: 8,
		marginBottom: 8
	}
};

const mapStateToProps = (state, ownProps) => {
	return {};
};
export default connect(mapStateToProps)(Radium(connectNetwork(Work)));
