import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { ImageDefault, Confirmation } from './';
import { Button, Icon, Card, Table, Input } from 'semantic-ui-react';
import View from 'lib/View';
import { timeString, floatTimeToString } from '../lib/util';

import {} from 'containers';
import Moment from 'react-moment';
import moment from 'moment-with-locales-es6';

import { route_from, routes } from 'lib/routes';
const { app, machines, machineEdit } = routes;

moment.locale('it');
Moment.globalMoment = moment;
Moment.globalLocale = 'it';

class Machine extends Component {
	constructor(props) {
		super(props);

		let { onCostChange, machine } = props;

		this.state = {
			colors: [
				'red',
				'orange',
				'yellow',
				'olive',
				'green',
				'teal',
				'blue',
				'violet',
				'purple',
				'pink',
				'brown',
				'grey',
				'black'
			],
			deleting: false,
			...machine,
			minutiMacchina: machine ? parseFloat(machine.minutiMacchina) : 0
		};

		if (onCostChange && machine) {
			onCostChange(this.totalCost(), machine.idMacchina);
		}
	}

	totalCost() {
		let { prezzoOrarioMacchina, oreMacchina, minutiMacchina } = this.state;
		let total = (oreMacchina + minutiMacchina / 60) * parseFloat(prezzoOrarioMacchina);
		if (isNaN(total)) {
			total = 0;
		}
		return total;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { onCostChange } = this.props;
		let { idMacchina, prezzoOrarioMacchina } = this.state;
		if (prezzoOrarioMacchina !== prevState.prezzoOrarioMacchina) {
			if (onCostChange) {
				onCostChange(this.totalCost(), idMacchina);
			}
		}
	}

	renderBalanceFooter() {
		let { hourtotal, total } = this.props;

		let averageHourlyCost = Math.round(total / hourtotal * 100) / 100;
		if (isNaN(averageHourlyCost)) {
			averageHourlyCost = '--';
		}

		return (
			<Table.Row>
				<Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
				<Table.Cell style={{ textAlign: 'right' }} width="4">
					Totale Ore:
				</Table.Cell>
				<Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
				<Table.Cell width="1">Totale:</Table.Cell>
				<Table.Cell width="2">{total}€</Table.Cell>
			</Table.Row>
		);
	}

	renderBalanceHeader() {
		return (
			<Table.Header fullWidth>
				<Table.Row>
					<Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
					<Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
					<Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
					<Table.HeaderCell width="1" />
					<Table.HeaderCell width="2">Totale</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
		);
	}

	renderForBalance() {
		let { nomeMacchina, prezzoOrarioMacchina, oreMacchina, minutiMacchina } = this.state;
		return (
			<Table.Row>
				<Table.Cell>{nomeMacchina}</Table.Cell>
				<Table.Cell>
					<span className="no-print">
						<Input
							type="number"
							step="0.01"
							value={prezzoOrarioMacchina}
							onChange={(e, data) => {
								let value = data.value;
								if (value < 0) value = 0;
								this.setState({
									prezzoOrarioMacchina: value
								});
							}}
						/>€/h
					</span>
					<span className="only-print">{prezzoOrarioMacchina}€/h</span>
				</Table.Cell>
				<Table.Cell>{timeString(oreMacchina, minutiMacchina)}</Table.Cell>
				<Table.Cell>=</Table.Cell>
				<Table.Cell>{this.totalCost()}€</Table.Cell>
			</Table.Row>
		);
	}

	renderTableFooter() {
		let { isBalance } = this.props;
		if (isBalance) return this.renderBalanceFooter();
		else return null;
	}

	renderTableHeader() {
		let { hideMachine, hideMachineHours, isBalance } = this.props;
		if (isBalance) {
			return this.renderBalanceHeader();
		} else {
			return (
				<Table.Header fullWidth>
					<Table.Row>
						{!hideMachine && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
						{!hideMachineHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
					</Table.Row>
				</Table.Header>
			);
		}
	}

	renderForTable() {
		let { hideMachine, hideMachineHours, isBalance } = this.props;
		let { nomeMacchina, oreMacchina, minutiMacchina } = this.state;

		if (isBalance) {
			return this.renderForBalance();
		} else {
			return (
				<Table.Row>
					{!hideMachine && <Table.Cell>{nomeMacchina}</Table.Cell>}
					{!hideMachineHours && <Table.Cell>{timeString(oreMacchina, minutiMacchina)}</Table.Cell>}
				</Table.Row>
			);
		}
	}

	openRemoveDialog() {
		this.setState({ deleting: true });
	}

	remove() {
		let { network, machine } = this.props;

		network.deleteMachine(machine);

		this.setState({ deleting: false });
	}

	render() {
		let { type, machine, onClick, selected, noActions, showNoMachine, role } = this.props;
		let {
			deleting,
			idMacchina,
			nomeMacchina,
			prezzoOrarioMacchina,
			immagineMacchina,
			oreMacchina,
			minutiMacchina
		} = this.state;

		let extraprops = {
			fluid: type === 'full' ? true : false
		};

		if (onClick) {
			extraprops.onClick = () => {
				onClick(machine);
			};
		}

		let col = parseInt(Math.random() * this.state.colors.length);

		if (machine && machine.idMacchina === 1 && !showNoMachine) {
			return null;
		}

		switch (type) {
			case 'footer':
				return this.renderTableFooter();
			case 'header':
				return this.renderTableHeader();
			case 'table':
				return this.renderForTable();
			default:
				return (
					<View fullw column between>
						<Card
							className={selected ? 'rowSelected' : ''}
							style={styles.machineCard}
							{...extraprops}
							color={this.state.colors[col]}
						>
							<Confirmation
								message="Vuoi davvero eliminare questa macchina?"
								showModal={deleting}
								onConfirm={() => {
									this.remove();
								}}
								onCancel={() => {
									this.setState({ deleting: false });
								}}
							/>
							<Card.Content style={styles.machineContent}>
								<View fullw row>
									<div style={styles.imageContainer}>
										<ImageDefault
											fromRoot
											src={immagineMacchina}
											ui={false}
											style={styles.machineImage}
										/>
									</div>
									<View fullw column>
										<span style={styles.header}>{nomeMacchina}</span>
										{!noActions &&
										(!role || role === 'admin') && (
											<Card.Description>
												Prezzo orario macchina: {prezzoOrarioMacchina}€/h
											</Card.Description>
										)}
									</View>
								</View>
							</Card.Content>
							{!noActions && (
								<Card.Content extra>
									<Button
										color="blue"
										onClick={() => {
											this.props.history.push(
												route_from(app, machines, machineEdit, `${idMacchina}`)
											);
										}}
									>
										<Icon name="edit" />
										Modifica
									</Button>
									<Button
										color="red"
										onClick={() => {
											this.openRemoveDialog();
										}}
									>
										<Icon name="delete" />
										Elimina
									</Button>
								</Card.Content>
							)}
						</Card>
					</View>
				);
		}
	}
}

const styles = {
	header: {
		fontSize: '32pt',
		lineHeight: 'normal'
	},
	imageContainer: {
		width: 200,
		height: 150,
		marginRight: 28
	},
	machineImage: {
		width: 200,
		height: 150,
		objectFit: 'contain'
	},
	machineContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	machineCard: {
		marginTop: 8,
		marginBottom: 8
	}
};

const mapStateToProps = (state, ownProps) => {
	let { login } = state;
	let ruolo = null;
	if (login && login.data && login.data.user) ruolo = login.data.user.ruolo;

	return { role: ruolo };
};

export default withRouter(connect(mapStateToProps)(Radium(connectNetwork(Machine))));
