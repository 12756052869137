import React, { Component } from 'react'
import Radium from 'radium'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'

import { Form, Input, Select, Button, Message, TextArea, Image, Checkbox } from 'semantic-ui-react'

import View from 'lib/View'

import { Job, Empty, PickImage, PickFile, ImageDefault } from '../components'
import { rainbow } from '../lib/util'

import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { SEARCH_ACTIONS } from '../actions/search'

import loadingImage from '../assets/loadingParagraph.png'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import itIT from 'date-fns/locale/it'

import moment from 'moment-with-locales-es6'
moment.locale('it')

class JobEditor extends Component {
	constructor(props) {
		super(props)
		let id = props.id

		let { job } = this.props.match.params

		if (!id) {
			id = job
		}

		this.state = {
			id,
			codiceCommissione: id,
			descrizione: '',
			attiva: 1,
			tipoCommissione: 2,
			cliente: '',
			immagine: '',
			creazioneEsterna: 0,
			errorMessage: '',
			dataUltimaFatturazione: new Date(),
			fatturato: 0.0,
			preventivato: 0.0,
			status: 'ok' // warning, error, success
		}
	}

	componentDidMount() {
		let { codiceCommissione } = this.state
		let { sub } = this.props

		if (!sub) {
			this.props.toolbarResult([
				{
					name: 'Salva',
					icon: 'save',
					position: 'right',
					action: () => {
						this.save()
					}
				}
			])
		}

		this.state.network.getJobTypeList()

		if (codiceCommissione) {
			this.state.network.getJob(codiceCommissione)
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Maybe need this if it stores state between different edits (it shouldn't)
		if (this.props.creatingJob !== prevProps.creatingJob) {
			if (!this.props.creatingJob) {
				if (this.props.createStatus.success) {
					// Navigate back, success!
					this.setState({
						status: 'success'
					})
					this.props.history.goBack()
				} else {
					if (
						this.props.createStatus.error &&
						this.props.createStatus.error.response &&
						this.props.createStatus.error.response.status === 409
					) {
						this.setState({
							status: 'error',
							errorMessage:
								'Il codice commessa inserito esiste già: modificare il codice commessa o modifica dalla lista commesse.'
						})
					} else {
						this.setState({
							status: 'error',
							errorMessage: ''
						})
					}
				}
			}
		}

		let { id } = this.state
		let { getJob } = this.props
		if (getJob && getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching) {
			if (getJob.status.success) {
				let job = getJob.data[id][0]

				let dateobj = null /*
				if (job.dataUltimaFatturazione) {
					let data = job.dataUltimaFatturazione.split('-');
					let year = parseInt(data[0]);
					let month = parseInt(data[1]);
					let day = parseInt(data[2]);
					dateobj = moment().set('year', year).set('month', month-1).set('date', day+1).toDate();
				}*/

				let momobj = moment(job.dataUltimaFatturazione, 'YYYY-MM-DD').add('days', 1)

				if (momobj.isValid()) {
					job.dataUltimaFatturazione = momobj.toDate()
				} else {
					job.dataUltimaFatturazione = new Date()
				}
				this.setState(job)
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return nextProps
	}

	save() {
		// TODO value checking
		let {
			options,
			id,
			codiceCommissione,
			descrizione,
			tipoCommissione,
			cliente,
			immagine,
			dataUltimaFatturazione,
			creazioneEsterna,
			fatturato,
			preventivato,
			programmaCommessa
		} = this.state

		if (codiceCommissione === '' || cliente === '') {
			this.setState({
				status: 'error'
			})
			return
		}

		this.props.network.createJob(id, {
			codiceCommissione,
			descrizione,
			tipoCommissione,
			cliente,
			immagine,
			programmaCommessa,
			//dataUltimaFatturazione: moment(dataUltimaFatturazione).format('YYYY-MM-DD'),
			creazioneEsterna
		})
	}

	handleInput(event) {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		this.setState(
			{
				[name]: value
			},
			() => {
				let { onChange } = this.props
				if (onChange) {
					let {
						options,
						id,
						codiceCommissione,
						descrizione,
						tipoCommissione,
						cliente,
						immagine,
						dataUltimaFatturazione,
						creazioneEsterna,
						fatturato,
						preventivato,
						programmaCommessa
					} = this.state
					onChange({
						codiceCommissione,
						descrizione,
						tipoCommissione,
						cliente,
						immagine,
						creazioneEsterna,
						fatturato,
						preventivato,
						programmaCommessa
					})
				}
			}
		)
	}

	render() {
		let {
			fetching,
			creatingJob,
			options,
			codiceCommissione,
			descrizione,
			tipoCommissione,
			cliente,
			immagine,
			dataUltimaFatturazione,
			creazioneEsterna,
			fatturato,
			preventivato,
			errorMessage,
			programmaCommessa
		} = this.state

		return (
			<View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
				<Form
					loading={fetching || creatingJob}
					success={this.state.status === 'success'}
					error={this.state.status === 'error'}
					warning={this.state.status === 'warning'}
				>
					<Form.Group widths="equal">
						<Form.Field
							id="form-input-control-job-id"
							control={Input}
							label="Codice commessa"
							name="codiceCommissione"
							value={codiceCommissione}
							onChange={(e) => this.handleInput(e)}
							placeholder="C000"
						/>
						<Form.Field
							id="form-input-control-job-description"
							control={Input}
							label="Descrizione"
							placeholder="Fresature"
							name="descrizione"
							value={descrizione}
							onChange={(e) => this.handleInput(e)}
						/>
						<Form.Field
							id="form-input-control-job-type"
							control={Select}
							options={options}
							label="Tipo commessa"
							placeholder="Tipo commessa"
							name="tipoCommissione"
							value={tipoCommissione}
							onChange={(e, data) => {
								let id = data.value
								this.setState({ tipoCommissione: id })
							}}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							id="form-textarea-control-image"
							control={Input}
							label="Immagine"
							placeholder="Immagine"
							name="immagine"
							value={immagine}
							disabled
							onChange={(e) => this.handleInput(e)}
						/>
						<PickImage
							onChange={(image) => {
								this.setState({ immagine: image })
							}}
							value={this.state.immagine}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							id="form-textarea-control-programma-commessa"
							control={Input}
							label="Programma"
							placeholder="Programma"
							name="programmaCommessa"
							value={programmaCommessa}
							disabled
							onChange={(e) => this.handleInput(e)}
						/>
						<PickFile
							onChange={(programmaCommessa) => {
								this.setState({ programmaCommessa: programmaCommessa })
							}}
							value={this.state.programmaCommessa}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							id="form-textarea-control-client"
							control={Input}
							label="Cliente"
							placeholder="Clementoni"
							name="cliente"
							value={cliente}
							onChange={(e) => this.handleInput(e)}
						/>
					</Form.Group>
					{false && (
						<Form.Group widths="equal">
							<Form.Field
								id="form-input-control-job-billed"
								control={Input}
								type="number"
								label="Fatturato"
								placeholder="0.0"
								step="0.1"
								name="fatturato"
								value={fatturato}
								className="rightTextField"
								onChange={(e) => this.handleInput(e)}
							/>
							<span style={{ overflow: 'unset', alignSelf: 'flex-end', marginBottom: 10 }}>€</span>
							<Form.Field
								id="form-input-control-job-budgeted"
								control={Input}
								type="number"
								label="Preventivato"
								placeholder="0.0"
								step="0.1"
								name="preventivato"
								value={preventivato}
								className="rightTextField"
								onChange={(e) => this.handleInput(e)}
							/>
							<span style={{ overflow: 'unset', alignSelf: 'flex-end', marginBottom: 10 }}>€</span>
							<Form.Field
								id="form-input-control-job-bill-date"
								control={DatePicker}
								label="Data ultima fatturazione"
								name="dataUltimaFatturazione"
								value={dataUltimaFatturazione}
								dateFormat="dd/MM/yyyy"
								selected={dataUltimaFatturazione}
								onChange={(data) => {
									this.setState({
										dataUltimaFatturazione: data
									})
								}}
							/>
						</Form.Group>
					)}
					<Form.Field
						control={Checkbox}
						label="Commessa da ricontrollare"
						checked={creazioneEsterna === 1}
						onChange={(e, data) => {
							this.setState({ creazioneEsterna: data.checked ? 1 : 0 }, () => {
								let { onChange } = this.props
								if (onChange) {
									let {
										options,
										id,
										codiceCommissione,
										descrizione,
										tipoCommissione,
										cliente,
										immagine,
										dataUltimaFatturazione,
										creazioneEsterna,
										fatturato,
										preventivato
									} = this.state
									onChange({
										codiceCommissione,
										descrizione,
										tipoCommissione,
										cliente,
										immagine,
										dataUltimaFatturazione,
										creazioneEsterna,
										fatturato,
										preventivato
									})
								}
							})
						}}
					/>
					<Form.Field
						id="form-button-control-save"
						control={Button}
						content="Salva"
						onClick={() => {
							this.save()
						}}
					/>
					<Message success header="Form Completed" content="Commessa inserita con successo!" />
					<Message
						error
						header="Errore"
						content={`Errore del server, \nriprova piu' tardi. ${errorMessage}`}
					/>
					<Message warning header="Errore inserimento" list={[ 'Ricontrolla i dati inseriti' ]} />
				</Form>
			</View>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let {
		getJobTypeList: { data: { data: types }, fetching },
		createJob: { data: createResult, fetching: creatingJob, status: createStatus },
		getJob
	} = state

	let options = []
	for (let i in types) {
		let type = types[i]
		options.push({
			key: type.tipoCommissione,
			text: type.nomeTipo,
			value: type.tipoCommissione
		})
	}

	return {
		options,
		types,
		fetching,
		createResult,
		creatingJob,
		createStatus,
		getJob
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(JobEditor))))
