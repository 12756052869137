export function mergeJob(fromJob, intoJob) {
	let { mergeJobRequest, mergeJobError, mergeJobResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/merge_job',
		this.createBundle(
			'api/merge_job',
			{ fromJob, intoJob },
			this.POST,
			mergeJobRequest,
			mergeJobResult,
			mergeJobError
		)
	);
}
