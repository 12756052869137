import React from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { Button, Loader } from 'semantic-ui-react';

import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const compute = function(forcedJob, forcedWorks, minus) {
	let { works: fromProps } = this.props;
	if (!fromProps) fromProps = [];
	let works = fromProps.slice(0);
	if (forcedWorks) works = forcedWorks.slice(0);

	/*
    console.log('Computing with this work list');
    console.dir(works);
    console.dir(forcedWorks);
    console.dir(fromProps);
    */

	let workers = [];
	let workerobj = {};
	let machines = [];
	let machineobj = {};
	let totalWorkerTime = [ 0, 0 ];
	let totalMachineTime = [ 0, 0 ];

	let workdict = {};
	let costdict = {};
	let costs = [];
	let notWorks = []; // Cost entries don't have a work attached to them

	let { costs: costsFromProps } = this.props;
	if (costsFromProps) {
		costs = costsFromProps;
		for (let i in costs) {
			costs[i].prezzoCosto = parseFloat(costs[i].prezzoCosto);
		}
	}

	//console.log('Job costs from props:', costsFromProps);
	for (let i in works) {
		let work = works[i];
		let {
			codiceCommissione,
			idImpiegato,
			idMacchina,
			nomeImpiegato,
			nomeMacchina,
			oreOperaio,
			oreMacchina,
			minutiOperaio,
			minutiMacchina,
			prezzoOrarioImpiegato,
			prezzoOrarioMacchina,
			idCosto,
			dataCosto,
			descrizioneCosto,
			fornitore,
			prezzoCosto,
			dataRegistrazione
		} = work;

		if (minus) {
			oreMacchina = Math.max(0, oreMacchina - oreOperaio);
			minutiMacchina = minutiMacchina - minutiOperaio;
			if (minutiMacchina < 0 && oreMacchina > 0) {
				oreMacchina = oreMacchina - 1;
				minutiMacchina = 60 + minutiMacchina;
			} else if (minutiMacchina < 0) minutiMacchina = 0;
		}

		//console.log("idCosto", idCosto, "in", costdict);
		if (!costsFromProps && idCosto !== null && idCosto && !costdict[idCosto]) {
			//console.log('Pushing work', work, 'as cost');
			costdict[idCosto] = {
				idCosto,
				dataCosto,
				codiceCommissione,
				descrizioneCosto,
				fornitore,
				prezzoCosto
			};
			costs.push(costdict[idCosto]);
		}

		if (!workdict[`${codiceCommissione}_${idImpiegato}_${dataRegistrazione}`]) {
			workdict[`${codiceCommissione}_${idImpiegato}_${dataRegistrazione}`] = true;
		} else {
			notWorks.push(parseInt(i));
			continue;
		}

		oreOperaio = parseFloat(oreOperaio);
		minutiOperaio = parseFloat(minutiOperaio);
		oreMacchina = parseFloat(oreMacchina);
		minutiMacchina = parseFloat(minutiMacchina);
		totalWorkerTime[0] += oreOperaio;
		totalWorkerTime[1] += minutiOperaio;
		totalMachineTime[0] += oreMacchina;
		totalMachineTime[1] += minutiMacchina;

		if (idImpiegato !== null) {
			if (!workerobj[idImpiegato]) {
				workerobj[idImpiegato] = [];
			}

			workerobj[idImpiegato].push({
				idImpiegato,
				nomeImpiegato,
				oreOperaio,
				minutiOperaio,
				prezzoOrarioImpiegato
			});
		}

		if (idMacchina !== null) {
			if (!machineobj[idMacchina]) {
				machineobj[idMacchina] = [];
			}

			machineobj[idMacchina].push({
				idMacchina,
				nomeMacchina,
				oreMacchina,
				minutiMacchina,
				prezzoOrarioMacchina
			});
		}
	}

	for (let i = notWorks.length - 1; i >= 0; i--) {
		let toRemove = notWorks[i];
		works.splice(toRemove, 1);
	}

	for (let i in workerobj) {
		let worklist = workerobj[i];
		let { idImpiegato, nomeImpiegato } = worklist[0];
		let oreOperaio = 0;
		let minutiOperaio = 0;
		let prezzoOrarioImpiegato = 0;

		for (let j in worklist) {
			let work = worklist[j];
			oreOperaio += work.oreOperaio;
			minutiOperaio += work.minutiOperaio;
			prezzoOrarioImpiegato = parseFloat(work.prezzoOrarioImpiegato);
		}

		oreOperaio += Math.floor(minutiOperaio / 60);
		minutiOperaio = minutiOperaio % 60;

		workers.push({
			idImpiegato,
			nomeImpiegato,
			oreOperaio,
			minutiOperaio,
			prezzoOrarioImpiegato
		});
	}

	for (let i in machineobj) {
		let worklist = machineobj[i];
		let { idMacchina, nomeMacchina } = worklist[0];
		let oreMacchina = 0;
		let minutiMacchina = 0;
		let prezzoOrarioMacchina = 0;

		for (let j in worklist) {
			let work = worklist[j];
			oreMacchina += work.oreMacchina;
			minutiMacchina += work.minutiMacchina;
			prezzoOrarioMacchina = parseFloat(work.prezzoOrarioMacchina);
		}

		oreMacchina += Math.floor(minutiMacchina / 60);
		minutiMacchina = minutiMacchina % 60;

		machines.push({
			idMacchina,
			nomeMacchina,
			oreMacchina,
			minutiMacchina,
			prezzoOrarioMacchina
		});
	}

	let colors = [
		'red',
		'orange',
		'yellow',
		'olive',
		'green',
		'teal',
		'blue',
		'violet',
		'purple',
		'pink',
		'brown',
		'grey',
		'black'
	];
	let color = this.props.color;
	if (!color) {
		color = colors[parseInt(Math.random() * colors.length)];
	}

	let { job, isBalance } = this.props;

	totalWorkerTime[0] += Math.floor(totalWorkerTime[1] / 60);
	totalWorkerTime[1] = totalWorkerTime[1] % 60;
	totalMachineTime[0] += Math.floor(totalMachineTime[1] / 60);
	totalMachineTime[1] = totalMachineTime[1] % 60;

	//console.log("Final works list", works);

	return {
		costs,
		workers,
		works,
		machines,
		totalWorkerTime,
		totalMachineTime,
		color,
		job: forcedJob ? forcedJob : job
	};
};

class Recap extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			recapDone: false,
			workerDone: false,
			machineDone: false
		};
	}

	componentDidMount() {
		let { year, month, minus, network, wait } = this.props;
		console.log('Recapping year', year, 'month', month, 'minus', minus);
		if (!wait) {
			network.recap(year, month);
			network.getWorkerList('');
			network.getMachineList('');
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { year, month, recap, getWorkerList, getMachineList, minus, wait, network, onDone } = this.props;
		let { recapDone, workerDone, machineDone } = this.state;

		if (!recap.fetching && prevProps.recap.fetching) {
			this.setState({ recapDone: true });
		}
		if (!getWorkerList.fetching && prevProps.getWorkerList.fetching) {
			this.setState({ workerDone: true });
		}
		if (!getMachineList.fetching && prevProps.getMachineList.fetching) {
			this.setState({ machineDone: true });
		}
		/*
		if (!prevProps.wait && wait) {
			this.setState({
				recapDone: false,
				workerDone: false,
				machineDone: false
			});
		}
		*/
		if (prevProps.wait && !wait) {
			console.log('Recapping year', year, 'month', month, 'minus', minus);
			network.recap(year, month);
			network.getWorkerList('');
			network.getMachineList('');
		}

		if (
			recapDone &&
			workerDone &&
			machineDone &&
			!wait &&
			(!(prevState.recapDone && prevState.workerDone && prevState.machineDone) || (!wait && prevProps.wait))
		) {
			let computefn = compute.bind(this);
			let joblist = [];
			let workers = {};
			let machines = {};
			for (let i in getWorkerList.data.data) {
				let worker = getWorkerList.data.data[i];
				workers[worker.idImpiegato] = {
					...worker,
					totalPerJob: {},
					totalTime: 0
				};
			}

			for (let i in getMachineList.data.data) {
				let machine = getMachineList.data.data[i];
				machines[machine.idMacchina] = {
					...machine,
					totalPerJob: {},
					totalTime: 0
				};
			}

			if (recap.data.data) {
				let jobs = {};
				for (let i in recap.data.data) {
					let row = recap.data.data[i];
					if (!jobs[row.codiceCommissione]) {
						jobs[row.codiceCommissione] = [];
					}
					jobs[row.codiceCommissione].push(row);
				}

				for (let codiceCommissione in jobs) {
					let job = jobs[codiceCommissione];
					joblist.push({
						...job[0],
						...computefn(codiceCommissione, job, minus)
					});
				}
			}

			for (let i in joblist) {
				let job = joblist[i];
				let { machines: ms, workers: ws, job: codiceCommissione } = job;
				for (let j in ws) {
					let worker = ws[j];
					let partial = worker.oreOperaio + worker.minutiOperaio / 60;
					workers[worker.idImpiegato].totalTime += partial;
					if (!workers[worker.idImpiegato].totalPerJob[codiceCommissione]) {
						workers[worker.idImpiegato].totalPerJob[codiceCommissione] = 0;
					}
					workers[worker.idImpiegato].totalPerJob[codiceCommissione] += partial;
				}
				for (let j in ms) {
					let machine = ms[j];
					let partial = machine.oreMacchina + machine.minutiMacchina / 60;
					machines[machine.idMacchina].totalTime += partial;
					if (!machines[machine.idMacchina].totalPerJob[codiceCommissione]) {
						machines[machine.idMacchina].totalPerJob[codiceCommissione] = 0;
					}
					machines[machine.idMacchina].totalPerJob[codiceCommissione] += partial;
				}
			}

			let excelfile = [];
			for (let i in joblist) {
				let job = joblist[i];

				let row = {
					client: job.cliente,
					description: job.descrizioneCommessa,
					job: job.job
				};

				let total = 0;
				for (let j in workers) {
					let worker = workers[j];
					let partial = worker.totalPerJob[job.job];
					row[`worker-${worker.idImpiegato}`] = partial ? partial : 0;
					total += partial ? partial : 0;
				}

				for (let j in machines) {
					let machine = machines[j];
					let partial = machine.totalPerJob[job.job];
					row[`machine-${machine.idMacchina}`] = partial ? partial : 0;
					total += partial ? partial : 0;
				}
				row.total = total;
				excelfile.push(row);
			}

			let lastrow = {
				client: `${year}`,
				description: `${month}`,
				job: (minus ? 'con-sottrazione' : 'normale')
			};
			let lastTotal = 0;

			for (let j in workers) {
				let worker = workers[j];
				let partial = worker.totalTime;
				lastrow[`worker-${worker.idImpiegato}`] = partial ? partial : 0;
				lastTotal += partial;
			}

			for (let j in machines) {
				let machine = machines[j];
				let partial = machine.totalTime;
				lastrow[`machine-${machine.idMacchina}`] = partial ? partial : 0;
				lastTotal += partial;
			}
			lastrow.total = lastTotal;
			excelfile.push(lastrow);

			let workerlist = [];
			for (let i in workers) {
				workerlist.push(workers[i]);
			}
			let machinelist = [];
			for (let i in machines) {
				if (machines[i].idMacchina === 1) continue;
				machinelist.push(machines[i]);
			}

			if (onDone) {
				onDone({
					jobs: joblist,
					workers: workerlist,
					machines: machinelist,
					excelfile
				});
			}

			this.setState({
				jobs: joblist,
				workers: workerlist,
				machines: machinelist,
				excelfile
			});
		}
	}

	render() {
		let { recap, getWorkerList, getMachineList, element, year, month, minus } = this.props;
		let { jobs, workers, machines, excelfile, recapDone, workerDone, machineDone } = this.state;

		if (recap.fetching || getWorkerList.fetching || getMachineList.fetching) {
			return <Loader active />;
		}
		if (!recapDone || !workerDone || !machineDone) {
			return null;
		} else {
			return (
				<ExcelFile filename={`riepilogo_${year}_${month}_` + (minus ? 'con-sottrazione' : 'normale')} element={element ? element : <Button>Download</Button>}>
					<ExcelSheet data={excelfile} name="Riepilogo">
						<ExcelColumn label="Cliente" value="client" />
						<ExcelColumn label="Descrizione" value="description" />
						<ExcelColumn label="Codice commessa" value="job" />
						{workers &&
							workers.map((value, index) => {
								return (
									<ExcelColumn
										key={`worker-${index}`}
										label={value.nomeImpiegato}
										value={`worker-${value.idImpiegato}`}
									/>
								);
							})}
						{machines &&
							machines.map((value, index) => {
								return (
									<ExcelColumn
										key={`machine-${index}`}
										label={value.nomeMacchina}
										value={`machine-${value.idMacchina}`}
									/>
								);
							})}
						<ExcelColumn label="Totale" value="total" />
					</ExcelSheet>
				</ExcelFile>
			);
		}
	}
	/*
    <ExcelColumn label="Name" value="name" />
    <ExcelColumn label="Wallet Money" value="amount" />
    <ExcelColumn label="Gender" value="sex" />
    <ExcelColumn label="Marital Status" value={(col) => (col.is_married ? 'Married' : 'Single')} />
    */
}

const mapStateToProps = (state, ownProps) => {
	let { login, recap, getWorkerList, getMachineList } = state;
	let ruolo = null;
	if (login && login.data && login.data.user) ruolo = login.data.user.ruolo;

	return { role: ruolo, recap, getWorkerList, getMachineList };
};

export default withRouter(connect(mapStateToProps)(Radium(connectNetwork(Recap))));
