import { combineReducers } from 'redux';
import { LOGIN_TYPES } from '../actions/login';

/* @reducers */
import createMapping from './createMapping' 
import getMappingData from './getMappingData' 
import getBabypressaDataFilter from './getBabypressaDataFilter' 
import getGT2000DataFilter from './getGT2000DataFilter' 
import getLastGT2000Data from './getLastGT2000Data' 
import getGT2000Data from './getGT2000Data' 
import getLastSWA300Data from './getLastSWA300Data' 
import getLastBabypressaData from './getLastBabypressaData' 
import getBabypressaData from './getBabypressaData' 
import getSWA300Data from './getSWA300Data' 
import writeSWA300Config from './writeSWA300Config' 
import uploadImage from './uploadImage' 
import createItem from './createItem' 
import deleteItem from './deleteItem' 
import getItemList from './getItemList' 
import mergeJob from './mergeJob' 
import recap from './recap' 
import getRecentJobList from './getRecentJobList' 
import getRecentMachineList from './getRecentMachineList' 
import jobListState from './jobListState' 
import deleteWorkList from './deleteWorkList' 
import getCost from './getCost' 
import getWorker from './getWorker' 
import getMachine from './getMachine' 
import getJob from './getJob' 
import getWorkListForDate from './getWorkListForDate';
import workday from './workday';
import assignWork from './assignWork';
import uploadFile from './uploadFile';
import getImageList from './getImageList';
import getJobTypeList from './getJobTypeList';
import search from './search';
import toolbar from './toolbar';
import getDetailedJobList from './getDetailedJobList';
import getPagedJobList from './getPagedJobList';
import getJobList from './getJobList';
import getWorkerList from './getWorkerList';
import getItemCosts from './getItemCosts';
import deleteWorker from './deleteWorker';
import updateWorker from './updateWorker';
import createWorker from './createWorker';
import getPagedCostList from './getPagedCostList';
import getCostList from './getCostList';
import deleteCost from './deleteCost';
import updateCost from './updateCost';
import createCost from './createCost';
import getMachineTypeList from './getMachineTypeList';
import deleteMachineType from './deleteMachineType';
import updateMachineType from './updateMachineType';
import createMachineType from './createMachineType';
import getMachineList from './getMachineList';
import deleteMachine from './deleteMachine';
import updateMachine from './updateMachine';
import createMachine from './createMachine';
import getWorkListFilter from './getWorkListFilter';
import getWorkListSpan from './getWorkListSpan';
import getWorkList from './getWorkList';
import getWork from './getWork';
import deleteWork from './deleteWork';
import updateWork from './updateWork';
import createWork from './createWork';
import deleteJob from './deleteJob';
import switchJob from './switchJob';
import updateJob from './updateJob';
import createJob from './createJob';
import loginEmployee from './loginEmployee';
import login from './login';

const AppReducer = combineReducers({
	/* @combine */
	createMapping, 
	getMappingData, 
	getBabypressaDataFilter, 
	getBabypressaData, 
	getGT2000DataFilter, 
	getLastGT2000Data, 
	getGT2000Data, 
	getLastSWA300Data, 
	getLastBabypressaData, 
	getBabypressaData, 
	getSWA300Data, 
	writeSWA300Config, 
	uploadImage, 
	createItem, 
	deleteItem, 
	getItemList, 
	mergeJob, 
	recap, 
	getRecentJobList, 
	getRecentMachineList, 
	jobListState, 
	deleteWorkList, 
	getCost, 
	getWorker, 
	getMachine, 
	getJob, 
	getWorkListForDate,
	workday,
	assignWork,
	uploadFile,
	getImageList,
	getJobTypeList,
	search,
	toolbar,
	getDetailedJobList,
	getDetailedJobList,
	getPagedJobList,
	getJobList,
	getItemCosts,
	getWorkerList,
	deleteWorker,
	updateWorker,
	createWorker,
	getPagedCostList,
	getCostList,
	deleteCost,
	updateCost,
	createCost,
	getMachineTypeList,
	deleteMachineType,
	updateMachineType,
	createMachineType,
	getMachineList,
	deleteMachine,
	updateMachine,
	createMachine,
	getWorkListFilter,
	getWorkListSpan,
	getWorkList,
	getWork,
	deleteWork,
	updateWork,
	createWork,
	deleteJob,
	switchJob,
	updateJob,
	createJob,
	getDetailedJobList,
	getPagedJobList,
	loginEmployee,
	login
});

const Reducer = (state, action) => {
	if (action.type === LOGIN_TYPES.logout || action.type === LOGIN_TYPES.request) state = undefined;

	let newState = AppReducer(state, action);


	return newState;
};

export default Reducer;


























