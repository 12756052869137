export function deleteMachineType(data) {
	let { deleteMachineTypeRequest, deleteMachineTypeError, deleteMachineTypeResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/delete_machine_type',
		this.createBundle(
			'api/delete_machine_type',
			{ machinetype: data },
			this.POST,
			deleteMachineTypeRequest,
			deleteMachineTypeResult,
			deleteMachineTypeError
		)
	);
}
