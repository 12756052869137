export function deleteWorkList(data, isEmployee) {
	let { deleteWorkListRequest, deleteWorkListError, deleteWorkListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/delete_work_list',
		this.createBundle(
			'api/delete_work_list',
			{ works: data },
			this.POST,
			deleteWorkListRequest,
			deleteWorkListResult,
			deleteWorkListError,
			isEmployee
		)
	);
}
