import { LOGINEMPLOYEE_TYPES } from '../actions/loginEmployee';
import reducer, { defaultState } from './requests';

const Reducer = (state = defaultState, action) => {
	let requestReducer = reducer(LOGINEMPLOYEE_TYPES)(state, action);

	switch (action.type) {
		case LOGINEMPLOYEE_TYPES.error:
			return {
				...requestReducer,
				authenticated: false
			};
		case LOGINEMPLOYEE_TYPES.result:
			return {
				...requestReducer,
				authenticated: true
			};
		case LOGINEMPLOYEE_TYPES.logout:
			return {
				...defaultState,
				authenticated: false
			};
		default:
			return state;
	}
};
export default Reducer;
