import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { } from 'semantic-ui-react';

import View from 'lib/View';
import { Machine } from '../components';

import { Placeholder, Card } from 'semantic-ui-react';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import Iframe from 'react-iframe'

import { route_from, routes } from 'lib/routes';
const { app, machines, machineEdit } = routes;

class Monitor extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		let { sub, network } = this.props
		if (!sub) {
			this.props.toolbarResult([
				
			])
		}
	}

	render() {
		return (
			<View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
				<Iframe url="http://192.168.1.201:28001"
					height="100%"
					id="stateMonitor"
					display="initial"
					position="relative" />
			</View>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(Monitor))));
