export function deleteWork(work, isEmployee) {
	let { deleteWorkRequest, deleteWorkError, deleteWorkResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/delete_work',
		this.createBundle(
			'api/delete_work',
			{ work },
			this.POST,
			deleteWorkRequest,
			deleteWorkResult,
			deleteWorkError,
			isEmployee
		)
	);
}
