export function deleteMachine(data) {
	let { deleteMachineRequest, deleteMachineError, deleteMachineResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/delete_machine',
		this.createBundle(
			'api/delete_machine',
			{ machine: data },
			this.POST,
			deleteMachineRequest,
			deleteMachineResult,
			deleteMachineError
		)
	);
}
