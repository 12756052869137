export function deleteJob(data) {
	let { deleteJobRequest, deleteJobError, deleteJobResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/delete_job',
		this.createBundle(
			'api/delete_job',
			{ job: data },
			this.POST,
			deleteJobRequest,
			deleteJobResult,
			deleteJobError
		)
	);
}
