import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withCookies } from 'react-cookie'

import * as requests from './requests'

/* @actions */
import { CREATEMAPPING_ACTIONS } from '../actions/createMapping' 
import { GETMAPPINGDATA_ACTIONS } from '../actions/getMappingData' 
import { GETBABYPRESSADATAFILTER_ACTIONS } from '../actions/getBabypressaDataFilter' 
import { GETGT2000DATAFILTER_ACTIONS } from '../actions/getGT2000DataFilter' 
import { GETLASTGT2000DATA_ACTIONS } from '../actions/getLastGT2000Data' 
import { GETGT2000DATA_ACTIONS } from '../actions/getGT2000Data' 
import { GETLASTSWA300DATA_ACTIONS } from '../actions/getLastSWA300Data' 
import { GETLASTBABYPRESSADATA_ACTIONS } from '../actions/getLastBabypressaData' 
import { GETBABYPRESSADATA_ACTIONS } from '../actions/getBabypressaData' 
import { GETSWA300DATA_ACTIONS } from '../actions/getSWA300Data' 
import { WRITESWA300CONFIG_ACTIONS } from '../actions/writeSWA300Config' 
import { UPLOADIMAGE_ACTIONS } from '../actions/uploadImage'
import { CREATEITEM_ACTIONS } from '../actions/createItem'
import { DELETEITEM_ACTIONS } from '../actions/deleteItem'
import { GETITEMLIST_ACTIONS } from '../actions/getItemList'
import { MERGEJOB_ACTIONS } from '../actions/mergeJob'
import { RECAP_ACTIONS } from '../actions/recap'
import { GETRECENTJOBLIST_ACTIONS } from '../actions/getRecentJobList'
import { GETRECENTMACHINELIST_ACTIONS } from '../actions/getRecentMachineList'
import { JOBLISTSTATE_ACTIONS } from '../actions/jobListState'
import { DELETEWORKLIST_ACTIONS } from '../actions/deleteWorkList'
import { GETCOST_ACTIONS } from '../actions/getCost'
import { GETWORKER_ACTIONS } from '../actions/getWorker'
import { GETMACHINE_ACTIONS } from '../actions/getMachine'
import { GETJOB_ACTIONS } from '../actions/getJob'
import { GETWORKLISTFORDATE_ACTIONS } from '../actions/getWorkListForDate'
import { GETWORKLISTFORDAE_ACTIONS } from '../actions/getWorkListForDae'
import { GETWORKLISTFORDAY_ACTIONS } from '../actions/getWorkListForDay'
import { WORKDAY_ACTIONS } from '../actions/workday'
import { ASSIGNWORK_ACTIONS } from '../actions/assignWork'
import { UPLOADFILE_ACTIONS } from '../actions/uploadFile'
import { GETIMAGELIST_ACTIONS } from '../actions/getImageList'
import { GETJOBTYPELIST_ACTIONS } from '../actions/getJobTypeList'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { GETDETAILEDJOBLIST_ACTIONS } from '../actions/getDetailedJobList'
import { GETPAGEDJOBLIST_ACTIONS } from '../actions/getPagedJobList'
import { GETJOBLIST_ACTIONS } from '../actions/getJobList'
import { GETWORKERLIST_ACTIONS } from '../actions/getWorkerList'
import { GETITEMCOSTS_ACTIONS } from '../actions/getItemCosts'
import { DELETEWORKER_ACTIONS } from '../actions/deleteWorker'
import { UPDATEWORKER_ACTIONS } from '../actions/updateWorker'
import { CREATEWORKER_ACTIONS } from '../actions/createWorker'
import { GETPAGEDCOSTLIST_ACTIONS } from '../actions/getPagedCostList'
import { GETCOSTLIST_ACTIONS } from '../actions/getCostList'
import { DELETECOST_ACTIONS } from '../actions/deleteCost'
import { UPDATECOST_ACTIONS } from '../actions/updateCost'
import { CREATECOST_ACTIONS } from '../actions/createCost'
import { GETMACHINETYPELIST_ACTIONS } from '../actions/getMachineTypeList'
import { DELETEMACHINETYPE_ACTIONS } from '../actions/deleteMachineType'
import { UPDATEMACHINETYPE_ACTIONS } from '../actions/updateMachineType'
import { CREATEMACHINETYPE_ACTIONS } from '../actions/createMachineType'
import { GETMACHINELIST_ACTIONS } from '../actions/getMachineList'
import { DELETEMACHINE_ACTIONS } from '../actions/deleteMachine'
import { UPDATEMACHINE_ACTIONS } from '../actions/updateMachine'
import { CREATEMACHINE_ACTIONS } from '../actions/createMachine'
import { GETWORKLISTFILTER_ACTIONS } from '../actions/getWorkListFilter'
import { GETWORKLISTSPAN_ACTIONS } from '../actions/getWorkListSpan'
import { GETWORKLIST_ACTIONS } from '../actions/getWorkList'
import { GETWORK_ACTIONS } from '../actions/getWork'
import { DELETEWORK_ACTIONS } from '../actions/deleteWork'
import { UPDATEWORK_ACTIONS } from '../actions/updateWork'
import { CREATEWORK_ACTIONS } from '../actions/createWork'
import { DELETEJOB_ACTIONS } from '../actions/deleteJob'
import { SWITCHJOB_ACTIONS } from '../actions/switchJob'
import { UPDATEJOB_ACTIONS } from '../actions/updateJob'
import { CREATEJOB_ACTIONS } from '../actions/createJob'
import { LOGINEMPLOYEE_ACTIONS } from '../actions/loginEmployee'
import { LOGIN_ACTIONS } from '../actions/login'

import { getCookie, setCookie, expireCookie } from 'redux-cookie'

import moment from 'moment-with-locales-es6'
moment.locale('it')

var requestCounter = 0

class NetworkProvider extends Component {
	get WEB_REQUEST_URL() {
		return 'http://46.101.106.217:5005/'
	}

	get GET() {
		return 'GET'
	}

	get POST() {
		return 'POST'
	}

	static childContextTypes = {
		network: PropTypes.object
	}

	constructor(props) {
		super(props)

		this.state = {
			activeRequests: {}
		}
	}

	componentDidMount() {
		let cookieuser = this.props.cookies.get('user')
		if (cookieuser) {
			this.props.loginResult({ user: cookieuser })
		}
	}

	/**
     * doAction
     * service: URI
     * bundle: {
     *      data: {... action: one of the exported constants},
     *      headers: [],
     *      *** REDUX functions ***
     *      request: ()=>{},
     *      receive: ()=>{},
     *      failure: ()=>{},
     *      ***********************
     *      method: 'get'|'post'
     *  }
     */
	doAction(service, bundle) {
		let { activeRequests } = this.state
		let currentAction = bundle.data.ACTION ? bundle.data.ACTION : service

		console.log('doAction', currentAction, 'actives:', activeRequests)

		if (activeRequests[currentAction]) {
			activeRequests[currentAction].abort()
			delete activeRequests[currentAction]
		}

		let currentRequestController = new AbortController()
		let signal = currentRequestController.signal
		signal.addEventListener('abort', () => {})
		activeRequests[currentAction] = currentRequestController

		this.setState({
			activeRequests
		})

		if (bundle.request) {
			bundle.request(bundle.data)
		}

		if (!bundle.loginType) {
			bundle.loginType = 'admin'
		}

		let tokenobj = {}
		let { login, loginEmployee } = this.props
		let loginobj = login

		switch (bundle.loginType) {
			case 'admin':
				loginobj = login
				break
			case 'employee':
				loginobj = loginEmployee
				break
			default:
				break
		}

		if (!bundle.noAuth && loginobj.data.user && loginobj.data.user.token) {
			tokenobj['Authorization'] = `Bearer ${loginobj.data.user.token}`
		}

		let website = service
		if (bundle.method === 'GET') {
			website = new URL(service)
			Object.keys(bundle.data).forEach((key) => website.searchParams.append(key, bundle.data[key]))
		}

		let body = {}
		if (bundle.noStringify) {
			body = bundle.data
		} else {
			body = JSON.stringify(bundle.data)
		}

		let formData = body
		if (formData.entries) {
			for (var key of formData.entries()) {
				console.dir(key[0])
				console.dir(key[1])
			}
		}

		let headers = {
			...bundle.headers,
			...tokenobj
		}

		if (bundle.contenttype !== 'multipart/form-data') {
			headers['Content-Type'] = bundle.contenttype ? bundle.contenttype : 'application/json'
		}

		let reqObj = {
			signal: currentRequestController.signal,
			method: bundle.method ? bundle.method : 'POST',
			headers,
			body: bundle.method === 'POST' ? body : undefined
		}

		fetch(website, reqObj)
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					return response
				} else {
					var error = new Error(response.statusText)
					error.response = response
					if (bundle.failure) {
						bundle.failure(error)
					}
					throw error
				}
			})
			.then((response) => {
				let json = response.json()
				return json
			})
			.then((json) => {
				if (bundle.receive) {
					bundle.receive(json)
				}
			})
			.catch((ex) => {
				console.error('Network provider FAILED DOACTION', ex)
				if (ex.name === 'AbortError') {
					console.warn('Request ABORTED:', bundle)
					return
				}

				if (bundle.failure) {
					bundle.failure(ex)
				}
			})
	}

	createBundle(action, data, method, request, receive, failure, isEmployee) {
		let { login, loginEmployee } = this.props
		let loginobj = login
		let loginType = isEmployee ? 'employee' : 'admin'

		switch (loginType) {
			case 'admin':
				loginobj = login
				break
			case 'employee':
				loginobj = loginEmployee
				break
			default:
				break
		}

		requestCounter += 1

		return {
			data: {
				ACTION: action,
				id_user: loginobj.user ? loginobj.email : null,
				token: loginobj.user ? loginobj.token : null,
				request_count: requestCounter,
				...data
			},
			method,
			request,
			receive,
			failure,
			loginType
		}
	}

	getChildContext() {
		const { loginEmployee, logoutEmployee, getCookie, setCookie, expireCookie } = this

		let obj = {}
		for (let [ key, value ] of Object.entries(requests)) {
			obj[key] = (...args) => value.bind(this)(...args)
		}
		return {
			network: {
				...obj,
				getCookie,
				setCookie,
				expireCookie
			}
		}
	}

	render() {
		return Children.only(this.props.children)
	}
}

const mapStateToProps = (state, ownProps) => {
	let { login, loginEmployee } = state

	return {
		login,
		loginEmployee
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		/* @dispatch */
		createMappingRequest: result => CREATEMAPPING_ACTIONS.request(result, dispatch), 
		createMappingResult: result => CREATEMAPPING_ACTIONS.result(result, dispatch), 
		createMappingError: result => CREATEMAPPING_ACTIONS.error(result, dispatch), 

		getMappingDataRequest: result => GETMAPPINGDATA_ACTIONS.request(result, dispatch), 
		getMappingDataResult: result => GETMAPPINGDATA_ACTIONS.result(result, dispatch), 
		getMappingDataError: result => GETMAPPINGDATA_ACTIONS.error(result, dispatch), 

		getBabypressaDataFilterRequest: result => GETBABYPRESSADATAFILTER_ACTIONS.request(result, dispatch), 
		getBabypressaDataFilterResult: result => GETBABYPRESSADATAFILTER_ACTIONS.result(result, dispatch), 
		getBabypressaDataFilterError: result => GETBABYPRESSADATAFILTER_ACTIONS.error(result, dispatch), 

		getBabypressaDataRequest: result => GETBABYPRESSADATA_ACTIONS.request(result, dispatch), 
		getBabypressaDataResult: result => GETBABYPRESSADATA_ACTIONS.result(result, dispatch), 
		getBabypressaDataError: result => GETBABYPRESSADATA_ACTIONS.error(result, dispatch), 

		getGT2000DataFilterRequest: result => GETGT2000DATAFILTER_ACTIONS.request(result, dispatch), 
		getGT2000DataFilterResult: result => GETGT2000DATAFILTER_ACTIONS.result(result, dispatch), 
		getGT2000DataFilterError: result => GETGT2000DATAFILTER_ACTIONS.error(result, dispatch), 

		getLastGT2000DataRequest: result => GETLASTGT2000DATA_ACTIONS.request(result, dispatch), 
		getLastGT2000DataResult: result => GETLASTGT2000DATA_ACTIONS.result(result, dispatch), 
		getLastGT2000DataError: result => GETLASTGT2000DATA_ACTIONS.error(result, dispatch), 

		getGT2000DataRequest: result => GETGT2000DATA_ACTIONS.request(result, dispatch), 
		getGT2000DataResult: result => GETGT2000DATA_ACTIONS.result(result, dispatch), 
		getGT2000DataError: result => GETGT2000DATA_ACTIONS.error(result, dispatch), 

		getLastSWA300DataRequest: result => GETLASTSWA300DATA_ACTIONS.request(result, dispatch), 
		getLastSWA300DataResult: result => GETLASTSWA300DATA_ACTIONS.result(result, dispatch), 
		getLastSWA300DataError: result => GETLASTSWA300DATA_ACTIONS.error(result, dispatch), 

		getLastBabypressaDataRequest: result => GETLASTBABYPRESSADATA_ACTIONS.request(result, dispatch), 
		getLastBabypressaDataResult: result => GETLASTBABYPRESSADATA_ACTIONS.result(result, dispatch), 
		getLastBabypressaDataError: result => GETLASTBABYPRESSADATA_ACTIONS.error(result, dispatch), 

		getBabypressaDataRequest: result => GETBABYPRESSADATA_ACTIONS.request(result, dispatch), 
		getBabypressaDataResult: result => GETBABYPRESSADATA_ACTIONS.result(result, dispatch), 
		getBabypressaDataError: result => GETBABYPRESSADATA_ACTIONS.error(result, dispatch), 

		getSWA300DataRequest: result => GETSWA300DATA_ACTIONS.request(result, dispatch), 
		getSWA300DataResult: result => GETSWA300DATA_ACTIONS.result(result, dispatch), 
		getSWA300DataError: result => GETSWA300DATA_ACTIONS.error(result, dispatch), 

		writeSWA300ConfigRequest: result => WRITESWA300CONFIG_ACTIONS.request(result, dispatch), 
		writeSWA300ConfigResult: result => WRITESWA300CONFIG_ACTIONS.result(result, dispatch), 
		writeSWA300ConfigError: result => WRITESWA300CONFIG_ACTIONS.error(result, dispatch), 

		uploadImageRequest: (result) => UPLOADIMAGE_ACTIONS.request(result, dispatch),
		uploadImageResult: (result) => UPLOADIMAGE_ACTIONS.result(result, dispatch),
		uploadImageError: (result) => UPLOADIMAGE_ACTIONS.error(result, dispatch),

		createItemRequest: (result) => CREATEITEM_ACTIONS.request(result, dispatch),
		createItemResult: (result) => CREATEITEM_ACTIONS.result(result, dispatch),
		createItemError: (result) => CREATEITEM_ACTIONS.error(result, dispatch),

		deleteItemRequest: (result) => DELETEITEM_ACTIONS.request(result, dispatch),
		deleteItemResult: (result) => DELETEITEM_ACTIONS.result(result, dispatch),
		deleteItemError: (result) => DELETEITEM_ACTIONS.error(result, dispatch),

		getItemListRequest: (result) => GETITEMLIST_ACTIONS.request(result, dispatch),
		getItemListResult: (result) => GETITEMLIST_ACTIONS.result(result, dispatch),
		getItemListError: (result) => GETITEMLIST_ACTIONS.error(result, dispatch),

		mergeJobRequest: (result) => MERGEJOB_ACTIONS.request(result, dispatch),
		mergeJobResult: (result) => MERGEJOB_ACTIONS.result(result, dispatch),
		mergeJobError: (result) => MERGEJOB_ACTIONS.error(result, dispatch),

		recapRequest: (result) => RECAP_ACTIONS.request(result, dispatch),
		recapResult: (result) => RECAP_ACTIONS.result(result, dispatch),
		recapError: (result) => RECAP_ACTIONS.error(result, dispatch),

		getRecentJobListRequest: (result) => GETRECENTJOBLIST_ACTIONS.request(result, dispatch),
		getRecentJobListResult: (result) => GETRECENTJOBLIST_ACTIONS.result(result, dispatch),
		getRecentJobListError: (result) => GETRECENTJOBLIST_ACTIONS.error(result, dispatch),

		getRecentMachineListRequest: (result) => GETRECENTMACHINELIST_ACTIONS.request(result, dispatch),
		getRecentMachineListResult: (result) => GETRECENTMACHINELIST_ACTIONS.result(result, dispatch),
		getRecentMachineListError: (result) => GETRECENTMACHINELIST_ACTIONS.error(result, dispatch),

		jobListStateRequest: (result) => JOBLISTSTATE_ACTIONS.request(result, dispatch),
		jobListStateResult: (result) => JOBLISTSTATE_ACTIONS.result(result, dispatch),
		jobListStateError: (result) => JOBLISTSTATE_ACTIONS.error(result, dispatch),

		deleteWorkListRequest: (result) => DELETEWORKLIST_ACTIONS.request(result, dispatch),
		deleteWorkListResult: (result) => DELETEWORKLIST_ACTIONS.result(result, dispatch),
		deleteWorkListError: (result) => DELETEWORKLIST_ACTIONS.error(result, dispatch),

		getCostRequest: (result) => GETCOST_ACTIONS.request(result, dispatch),
		getCostResult: (result) => GETCOST_ACTIONS.result(result, dispatch),
		getCostError: (result) => GETCOST_ACTIONS.error(result, dispatch),

		getWorkerRequest: (result) => GETWORKER_ACTIONS.request(result, dispatch),
		getWorkerResult: (result) => GETWORKER_ACTIONS.result(result, dispatch),
		getWorkerError: (result) => GETWORKER_ACTIONS.error(result, dispatch),

		getMachineRequest: (result) => GETMACHINE_ACTIONS.request(result, dispatch),
		getMachineResult: (result) => GETMACHINE_ACTIONS.result(result, dispatch),
		getMachineError: (result) => GETMACHINE_ACTIONS.error(result, dispatch),

		getJobRequest: (result) => GETJOB_ACTIONS.request(result, dispatch),
		getJobResult: (result) => GETJOB_ACTIONS.result(result, dispatch),
		getJobError: (result) => GETJOB_ACTIONS.error(result, dispatch),

		getWorkListForDateRequest: (result) => GETWORKLISTFORDATE_ACTIONS.request(result, dispatch),
		getWorkListForDateResult: (result) => GETWORKLISTFORDATE_ACTIONS.result(result, dispatch),
		getWorkListForDateError: (result) => GETWORKLISTFORDATE_ACTIONS.error(result, dispatch),

		getWorkListForDaeRequest: (result) => GETWORKLISTFORDAE_ACTIONS.request(result, dispatch),
		getWorkListForDaeResult: (result) => GETWORKLISTFORDAE_ACTIONS.result(result, dispatch),
		getWorkListForDaeError: (result) => GETWORKLISTFORDAE_ACTIONS.error(result, dispatch),

		getWorkListForDayRequest: (result) => GETWORKLISTFORDAY_ACTIONS.request(result, dispatch),
		getWorkListForDayResult: (result) => GETWORKLISTFORDAY_ACTIONS.result(result, dispatch),
		getWorkListForDayError: (result) => GETWORKLISTFORDAY_ACTIONS.error(result, dispatch),

		workdayRequest: (result) => WORKDAY_ACTIONS.request(result, dispatch),
		workdayResult: (result) => WORKDAY_ACTIONS.result(result, dispatch),
		workdayError: (result) => WORKDAY_ACTIONS.error(result, dispatch),

		assignWorkRequest: (result) => ASSIGNWORK_ACTIONS.request(result, dispatch),
		assignWorkResult: (result) => ASSIGNWORK_ACTIONS.result(result, dispatch),
		assignWorkError: (result) => ASSIGNWORK_ACTIONS.error(result, dispatch),

		uploadFileRequest: (result) => UPLOADFILE_ACTIONS.request(result, dispatch),
		uploadFileResult: (result) => UPLOADFILE_ACTIONS.result(result, dispatch),
		uploadFileError: (result) => UPLOADFILE_ACTIONS.error(result, dispatch),

		getImageListRequest: (result) => GETIMAGELIST_ACTIONS.request(result, dispatch),
		getImageListResult: (result) => GETIMAGELIST_ACTIONS.result(result, dispatch),
		getImageListError: (result) => GETIMAGELIST_ACTIONS.error(result, dispatch),

		getJobTypeListRequest: (result) => GETJOBTYPELIST_ACTIONS.request(result, dispatch),
		getJobTypeListResult: (result) => GETJOBTYPELIST_ACTIONS.result(result, dispatch),
		getJobTypeListError: (result) => GETJOBTYPELIST_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		getCookie: (result) => getCookie(result, dispatch),
		setCookie: (result) => setCookie(result, dispatch),
		expireCookie: (result) => expireCookie(result, dispatch),

		getDetailedJobListRequest: (result) => GETDETAILEDJOBLIST_ACTIONS.request(result, dispatch),
		getDetailedJobListResult: (result) => GETDETAILEDJOBLIST_ACTIONS.result(result, dispatch),
		getDetailedJobListError: (result) => GETDETAILEDJOBLIST_ACTIONS.error(result, dispatch),

		getDetailedJobListRequest: (result) => GETDETAILEDJOBLIST_ACTIONS.request(result, dispatch),
		getDetailedJobListResult: (result) => GETDETAILEDJOBLIST_ACTIONS.result(result, dispatch),
		getDetailedJobListError: (result) => GETDETAILEDJOBLIST_ACTIONS.error(result, dispatch),

		getPagedJobListRequest: (result) => GETPAGEDJOBLIST_ACTIONS.request(result, dispatch),
		getPagedJobListResult: (result) => GETPAGEDJOBLIST_ACTIONS.result(result, dispatch),
		getPagedJobListError: (result) => GETPAGEDJOBLIST_ACTIONS.error(result, dispatch),

		getJobListRequest: (result) => GETJOBLIST_ACTIONS.request(result, dispatch),
		getJobListResult: (result) => GETJOBLIST_ACTIONS.result(result, dispatch),
		getJobListError: (result) => GETJOBLIST_ACTIONS.error(result, dispatch),

		getWorkerListRequest: (result) => GETWORKERLIST_ACTIONS.request(result, dispatch),
		getWorkerListResult: (result) => GETWORKERLIST_ACTIONS.result(result, dispatch),
		getWorkerListError: (result) => GETWORKERLIST_ACTIONS.error(result, dispatch),

		getItemCostsRequest: (result) => GETITEMCOSTS_ACTIONS.request(result, dispatch),
		getItemCostsResult: (result) => GETITEMCOSTS_ACTIONS.result(result, dispatch),
		getItemCostsError: (result) => GETITEMCOSTS_ACTIONS.error(result, dispatch),

		getWorkerListRequest: (result) => GETWORKERLIST_ACTIONS.request(result, dispatch),
		getWorkerListResult: (result) => GETWORKERLIST_ACTIONS.result(result, dispatch),
		getWorkerListError: (result) => GETWORKERLIST_ACTIONS.error(result, dispatch),

		deleteWorkerRequest: (result) => DELETEWORKER_ACTIONS.request(result, dispatch),
		deleteWorkerResult: (result) => DELETEWORKER_ACTIONS.result(result, dispatch),
		deleteWorkerError: (result) => DELETEWORKER_ACTIONS.error(result, dispatch),

		updateWorkerRequest: (result) => UPDATEWORKER_ACTIONS.request(result, dispatch),
		updateWorkerResult: (result) => UPDATEWORKER_ACTIONS.result(result, dispatch),
		updateWorkerError: (result) => UPDATEWORKER_ACTIONS.error(result, dispatch),

		createWorkerRequest: (result) => CREATEWORKER_ACTIONS.request(result, dispatch),
		createWorkerResult: (result) => CREATEWORKER_ACTIONS.result(result, dispatch),
		createWorkerError: (result) => CREATEWORKER_ACTIONS.error(result, dispatch),

		getPagedCostListRequest: (result) => GETPAGEDCOSTLIST_ACTIONS.request(result, dispatch),
		getPagedCostListResult: (result) => GETPAGEDCOSTLIST_ACTIONS.result(result, dispatch),
		getPagedCostListError: (result) => GETPAGEDCOSTLIST_ACTIONS.error(result, dispatch),

		getCostListRequest: (result) => GETCOSTLIST_ACTIONS.request(result, dispatch),
		getCostListResult: (result) => GETCOSTLIST_ACTIONS.result(result, dispatch),
		getCostListError: (result) => GETCOSTLIST_ACTIONS.error(result, dispatch),

		deleteCostRequest: (result) => DELETECOST_ACTIONS.request(result, dispatch),
		deleteCostResult: (result) => DELETECOST_ACTIONS.result(result, dispatch),
		deleteCostError: (result) => DELETECOST_ACTIONS.error(result, dispatch),

		updateCostRequest: (result) => UPDATECOST_ACTIONS.request(result, dispatch),
		updateCostResult: (result) => UPDATECOST_ACTIONS.result(result, dispatch),
		updateCostError: (result) => UPDATECOST_ACTIONS.error(result, dispatch),

		createCostRequest: (result) => CREATECOST_ACTIONS.request(result, dispatch),
		createCostResult: (result) => CREATECOST_ACTIONS.result(result, dispatch),
		createCostError: (result) => CREATECOST_ACTIONS.error(result, dispatch),

		getMachineTypeListRequest: (result) => GETMACHINETYPELIST_ACTIONS.request(result, dispatch),
		getMachineTypeListResult: (result) => GETMACHINETYPELIST_ACTIONS.result(result, dispatch),
		getMachineTypeListError: (result) => GETMACHINETYPELIST_ACTIONS.error(result, dispatch),

		deleteMachineTypeRequest: (result) => DELETEMACHINETYPE_ACTIONS.request(result, dispatch),
		deleteMachineTypeResult: (result) => DELETEMACHINETYPE_ACTIONS.result(result, dispatch),
		deleteMachineTypeError: (result) => DELETEMACHINETYPE_ACTIONS.error(result, dispatch),

		updateMachineTypeRequest: (result) => UPDATEMACHINETYPE_ACTIONS.request(result, dispatch),
		updateMachineTypeResult: (result) => UPDATEMACHINETYPE_ACTIONS.result(result, dispatch),
		updateMachineTypeError: (result) => UPDATEMACHINETYPE_ACTIONS.error(result, dispatch),

		createMachineTypeRequest: (result) => CREATEMACHINETYPE_ACTIONS.request(result, dispatch),
		createMachineTypeResult: (result) => CREATEMACHINETYPE_ACTIONS.result(result, dispatch),
		createMachineTypeError: (result) => CREATEMACHINETYPE_ACTIONS.error(result, dispatch),

		getMachineListRequest: (result) => GETMACHINELIST_ACTIONS.request(result, dispatch),
		getMachineListResult: (result) => GETMACHINELIST_ACTIONS.result(result, dispatch),
		getMachineListError: (result) => GETMACHINELIST_ACTIONS.error(result, dispatch),

		deleteMachineRequest: (result) => DELETEMACHINE_ACTIONS.request(result, dispatch),
		deleteMachineResult: (result) => DELETEMACHINE_ACTIONS.result(result, dispatch),
		deleteMachineError: (result) => DELETEMACHINE_ACTIONS.error(result, dispatch),

		updateMachineRequest: (result) => UPDATEMACHINE_ACTIONS.request(result, dispatch),
		updateMachineResult: (result) => UPDATEMACHINE_ACTIONS.result(result, dispatch),
		updateMachineError: (result) => UPDATEMACHINE_ACTIONS.error(result, dispatch),

		createMachineRequest: (result) => CREATEMACHINE_ACTIONS.request(result, dispatch),
		createMachineResult: (result) => CREATEMACHINE_ACTIONS.result(result, dispatch),
		createMachineError: (result) => CREATEMACHINE_ACTIONS.error(result, dispatch),

		getWorkListFilterRequest: (result) => GETWORKLISTFILTER_ACTIONS.request(result, dispatch),
		getWorkListFilterResult: (result) => GETWORKLISTFILTER_ACTIONS.result(result, dispatch),
		getWorkListFilterError: (result) => GETWORKLISTFILTER_ACTIONS.error(result, dispatch),

		getWorkListSpanRequest: (result) => GETWORKLISTSPAN_ACTIONS.request(result, dispatch),
		getWorkListSpanResult: (result) => GETWORKLISTSPAN_ACTIONS.result(result, dispatch),
		getWorkListSpanError: (result) => GETWORKLISTSPAN_ACTIONS.error(result, dispatch),

		getWorkListRequest: (result) => GETWORKLIST_ACTIONS.request(result, dispatch),
		getWorkListResult: (result) => GETWORKLIST_ACTIONS.result(result, dispatch),
		getWorkListError: (result) => GETWORKLIST_ACTIONS.error(result, dispatch),

		getWorkRequest: (result) => GETWORK_ACTIONS.request(result, dispatch),
		getWorkResult: (result) => GETWORK_ACTIONS.result(result, dispatch),
		getWorkError: (result) => GETWORK_ACTIONS.error(result, dispatch),

		deleteWorkRequest: (result) => DELETEWORK_ACTIONS.request(result, dispatch),
		deleteWorkResult: (result) => DELETEWORK_ACTIONS.result(result, dispatch),
		deleteWorkError: (result) => DELETEWORK_ACTIONS.error(result, dispatch),

		updateWorkRequest: (result) => UPDATEWORK_ACTIONS.request(result, dispatch),
		updateWorkResult: (result) => UPDATEWORK_ACTIONS.result(result, dispatch),
		updateWorkError: (result) => UPDATEWORK_ACTIONS.error(result, dispatch),

		createWorkRequest: (result) => CREATEWORK_ACTIONS.request(result, dispatch),
		createWorkResult: (result) => CREATEWORK_ACTIONS.result(result, dispatch),
		createWorkError: (result) => CREATEWORK_ACTIONS.error(result, dispatch),

		deleteJobRequest: (result) => DELETEJOB_ACTIONS.request(result, dispatch),
		deleteJobResult: (result) => DELETEJOB_ACTIONS.result(result, dispatch),
		deleteJobError: (result) => DELETEJOB_ACTIONS.error(result, dispatch),

		switchJobRequest: (result) => SWITCHJOB_ACTIONS.request(result, dispatch),
		switchJobResult: (result) => SWITCHJOB_ACTIONS.result(result, dispatch),
		switchJobError: (result) => SWITCHJOB_ACTIONS.error(result, dispatch),

		updateJobRequest: (result) => UPDATEJOB_ACTIONS.request(result, dispatch),
		updateJobResult: (result) => UPDATEJOB_ACTIONS.result(result, dispatch),
		updateJobError: (result) => UPDATEJOB_ACTIONS.error(result, dispatch),

		createJobRequest: (result) => CREATEJOB_ACTIONS.request(result, dispatch),
		createJobResult: (result) => CREATEJOB_ACTIONS.result(result, dispatch),
		createJobError: (result) => CREATEJOB_ACTIONS.error(result, dispatch),

		getDetailedJobListRequest: (result) => GETDETAILEDJOBLIST_ACTIONS.request(result, dispatch),
		getDetailedJobListResult: (result) => GETDETAILEDJOBLIST_ACTIONS.result(result, dispatch),
		getDetailedJobListError: (result) => GETDETAILEDJOBLIST_ACTIONS.error(result, dispatch),

		getPagedJobListRequest: (result) => GETPAGEDJOBLIST_ACTIONS.request(result, dispatch),
		getPagedJobListResult: (result) => GETPAGEDJOBLIST_ACTIONS.result(result, dispatch),
		getPagedJobListError: (result) => GETPAGEDJOBLIST_ACTIONS.error(result, dispatch),

		loginEmployeeRequest: (result) => LOGINEMPLOYEE_ACTIONS.request(result, dispatch),
		loginEmployeeResult: (result) => LOGINEMPLOYEE_ACTIONS.result(result, dispatch),
		loginEmployeeError: (result) => LOGINEMPLOYEE_ACTIONS.error(result, dispatch),

		logoutEmployee: (result) => LOGINEMPLOYEE_ACTIONS.logout(result, dispatch),

		loginRequest: (result) => LOGIN_ACTIONS.request(result, dispatch),
		loginResult: (result) => LOGIN_ACTIONS.result(result, dispatch),
		loginError: (result) => LOGIN_ACTIONS.error(result, dispatch),
		logout: (result) => LOGIN_ACTIONS.logout(result, dispatch)
	}
}

export const connectNetwork = (ComponentToWrap) => {
	return class NetworkComponent extends Component {
		static contextTypes = {
			network: PropTypes.object.isRequired
		}

		render() {
			const { network } = this.context

			return <ComponentToWrap {...this.props} network={network} />
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(NetworkProvider))












