export function deleteWorker(data) {
	let { deleteWorkerRequest, deleteWorkerError, deleteWorkerResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/delete_worker',
		this.createBundle(
			'api/delete_worker',
			{ worker: data },
			this.POST,
			deleteWorkerRequest,
			deleteWorkerResult,
			deleteWorkerError
		)
	);
}
