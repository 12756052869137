export function createItem(data) {
	let { createItemRequest, createItemError, createItemResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/create_item',
		this.createBundle(
			'api/create_item',
			{ item: data },
			this.POST,
			createItemRequest,
			createItemResult,
			createItemError
		)
	);
}
