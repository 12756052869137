
import { GETMACHINELIST_TYPES } from '../actions/getMachineList'
import reducer, { defaultState } from './requests'
const Reducer = (state = defaultState, action) => {
    let requestReducer = reducer(GETMACHINELIST_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}
export default Reducer