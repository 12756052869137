import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { Form, Input, Select, Button, Message, TextArea } from 'semantic-ui-react';

import View from 'lib/View';

import { Job, Empty, PickImage } from '../components';
import { rainbow } from '../lib/util';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import loadingImage from '../assets/loadingParagraph.png';

class MachineEditor extends Component {
	constructor(props) {
		super(props);
		let id = props.id;
		let { machine } = this.props.match.params;

		if(!id) {
			id = machine;
		}

		this.state = {
			idMacchina: id,
			nomeMacchina: '',
			tipoMacchina: 0,
			prezzoOrarioMacchina: 0.0,
			immagineMacchina: ''
		};
	}

	componentDidMount() {
		let { idMacchina } = this.state;

		this.props.toolbarResult([
			{
				name: 'Salva',
				icon: 'save',
				position: 'right',
				action: () => {
					this.save();
				}
			}
		]);
		this.state.network.getMachineTypeList();

		if(idMacchina) {
			this.state.network.getMachine(idMacchina);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Maybe need this if it stores state between different edits (it shouldn't)
		console.log('JobEditor did update', this.props.creating);
		if (this.props.creating !== prevProps.creating) {
			if (!this.props.creating) {
				console.log(
					'Create result is: ',
					this.props.createResult,
					this.props.createStatus,
					this.props.createStatus.success ? 'Success!' : 'Failure!'
				);
				if (this.props.createStatus.success) {
					// Navigate back, success!
					this.setState({
						status: 'success'
					});
					this.props.history.goBack();
				} else {
					this.setState({
						status: 'error'
					});
				}
			}
		}

		let { getMachine } = this.props;
		if (getMachine && getMachine.fetching !== prevProps.getMachine.fetching && !getMachine.fetching) {
			if (getMachine.status.success) {
				let machine = getMachine.data.data;
				this.setState(machine);
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return nextProps;
	}

	save() {
		// TODO value checking
		let { idMacchina, nomeMacchina, tipoMacchina, prezzoOrarioMacchina, immagineMacchina } = this.state;

		if (nomeMacchina === '') {
			this.setState({
				status: 'error'
			});
			return;
		}

		this.props.network.createMachine({
			idMacchina,
			nomeMacchina,
			tipoMacchina,
			prezzoOrarioMacchina,
			immagineMacchina
		});
	}

	handleInput(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		console.log('Handling input', name, target, event);

		this.setState({
			[name]: value
		});
	}

	render() {
		let {
			fetching,
			creating,
			options,
			idMacchina,
			nomeMacchina,
			tipoMacchina,
			prezzoOrarioMacchina,
			immagineMacchina,
			getMachine
		} = this.state;

		console.log('JobEditor state:', this.state);
		return (
			<View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
				<Form
					loading={fetching || creating || getMachine.fetching}
					success={this.state.status === 'success'}
					error={this.state.status === 'error'}
					warning={this.state.status === 'warning'}
				>
					<Form.Group widths="equal">
						<Form.Field
							id="form-input-control-machine-name"
							control={Input}
							label="Nome Macchina"
							name="nomeMacchina"
							value={nomeMacchina}
							onChange={(e) => this.handleInput(e)}
							placeholder=""
						/>
						<Form.Field
							id="form-input-control-machine-type"
							control={Select}
							options={options}
							label="Tipo macchina"
							placeholder="Tipo macchina"
							name="tipoMacchina"
							value={tipoMacchina}
							onChange={(e, data) => {
								let id = data.value;
								console.log('Tipo selected', id);
								this.setState({ tipoMacchina: id });
							}}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							id="form-textarea-control-image"
							control={Input}
							label="Immagine"
							placeholder="Immagine"
							name="immagineMacchina"
							value={immagineMacchina}
							disabled
							onChange={(e) => this.handleInput(e)}
						/>
						<PickImage
							onChange={(image) => {
								this.setState({ immagineMacchina: image });
							}}
							value={this.state.immagineMacchina}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							id="form-input-control-machine-cost"
							control={Input}
							type="number"
							label="Prezzo orario"
							placeholder="0.0"
							step="0.1"
							name="prezzoOrarioMacchina"
							value={prezzoOrarioMacchina}
							onChange={(e) => this.handleInput(e)}
						/>
					</Form.Group>
					<Form.Field
						id="form-button-control-save"
						control={Button}
						content="Salva"
						onClick={() => {
							this.save();
						}}
					/>
					<Message success header="Form Completed" content="Macchina inserita con successo!" />
					<Message error header="Errore" content="Errore del server, riprova piu' tardi." />
					<Message warning header="Errore inserimento" list={[ 'Ricontrolla i dati inseriti' ]} />
				</Form>
			</View>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let {
		getMachineTypeList: { data: { data: types }, fetching },
		createMachine: { data: createResult, fetching: creating, status: createStatus },
		getMachine
	} = state;

	let options = [];
	for (let i in types) {
		let type = types[i];
		options.push({
			key: type.tipoMacchina,
			text: type.descrizioneTipo,
			value: type.tipoMacchina
		});
	}

	return {
		options,
		types,
		fetching,
		createResult,
		creating,
		getMachine,
		createStatus
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(MachineEditor))));
