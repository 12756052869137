export function getWorkerList(data) {
	let { getWorkerListRequest, getWorkerListError, getWorkerListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_worker_list',
		this.createBundle(
			'api/get_worker_list',
			{ data: data },
			this.GET,
			getWorkerListRequest,
			getWorkerListResult,
			getWorkerListError
		)
	);
}
