import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';
import { withRouter } from 'react-router';

import { Icon, Menu, Image, Statistic, Button } from 'semantic-ui-react';
import View from 'lib/View';

import { route_from, routes, get_section } from '../lib/routes';

import { SEARCH_ACTIONS, WORKDAY_ACTIONS } from '../actions/workday';

import moment from 'moment-with-locales-es6';
moment.locale('it');

const { app, jobs, tempJobs, monitor, works, employees, warehouse, machines, admin, employee, insertion, calendarView, saldatrice, babypressa, gt2000 } = routes;

const menu = [
	{ key: jobs, label: 'Commesse', icon: 'tasks' },
	{ key: tempJobs, label: 'Commesse dal magazzino', icon: 'tag' },
	{ key: works, label: 'Lavori non assegnati', icon: 'hourglass outline' },
	{ key: employees, label: 'Impiegati', icon: 'address card outline' },
	{ key: machines, label: 'Macchine', icon: 'hdd outline' },
	{ key: warehouse, label: 'Magazzino', icon: 'warehouse' },
	{ key: gt2000, label: 'C.B. Ferrari GT2000', icon: 'chart area' },
	{ key: monitor, label: 'C.B. Ferrari', icon: 'chart area' },
	{ key: saldatrice, label: 'SWA300', icon: 'chart area' },
	{ key: babypressa, label: 'Baby Pressa', icon: 'chart area' },
	//{ key: admin, label: 'Amministratori', icon: 'user outline' }
];

class SideBar extends Component {
	constructor(props) {
		super(props);
		let { location: { pathname } } = this.props.history;
		let { history, isEmployee } = this.props;

		this.state = {
			sidemenu: get_section(pathname),
			weekStart: moment().subtract(7, 'days'),
			weekEnd: moment(),
			selected: {},
			hideSidebar: false
		};

		if (isEmployee) {
			let m = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
			let value = {
				date: m,
				color: m.day() === 0 || m.day() === 6 ? 'red' : 'grey',
				key: m.format('YYYY-MM-DD')
			};
			this.props.workdayResult(value);
			this.state.sidemenu = value.key;
			this.state.selected = value;
		}

		if (this.checkPath(pathname)) {
			this.state.hideSidebar = true;
		}

		this.unlisten = history.listen((location, action) => {
			let { pathname } = location;
			if (this.checkPath(pathname)) {
				this.setState({
					hideSidebar: true
				});
			} else {
				this.setState({
					hideSidebar: false
				});
			}
		});
	}

	checkPath(pathname) {
		return pathname === route_from(employee, insertion, calendarView);
	}

	nextWeek() {
		this.setState({
			weekStart: this.state.weekStart.add(1, 'w'),
			weekEnd: this.state.weekEnd.add(1, 'w')
		});
	}

	prevWeek() {
		this.setState({
			weekStart: this.state.weekStart.subtract(1, 'w'),
			weekEnd: this.state.weekEnd.subtract(1, 'w')
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { workday } = this.props;

		if (workday !== prevProps.workday) {
			this.setState({
				weekStart: moment(workday.data.date).startOf('week'),
				weekEnd: moment(workday.data.date).endOf('week'),
				selected: moment(workday.data.date).format('YYYY-MM-DD'),
				sidemenu: workday.data.key
			});
		}
	}

	render() {
		let { sidemenu, week, hideSidebar } = this.state;
		let { history, isEmployee } = this.props;
		let _menu = menu;

		if (isEmployee) {
			let days = [];
			for (var m = moment(this.state.weekStart); m.isBefore(this.state.weekEnd); m.add(1, 'days')) {
				days.push({
					date: moment(m),
					color: m.day() === 0 || m.day() === 6 ? 'red' : 'grey',
					key: moment(m).format('YYYY-MM-DD')
				});
			}
			_menu = days;
		}

		if (hideSidebar) return null;

		return (
			<Menu
				compact
				icon="labeled"
				vertical
				borderless
				style={{
					zIndex: 45,
					borderRadius: 'unset',
					border: 'unset',
					boxShadow: 'unset'
				}}
			>
				<Menu.Item style={{ height: 100 }} />
				{isEmployee && (
					<View row fullw around>
						<Button
							onClick={() => {
								this.prevWeek();
							}}
						>
							<Icon name="arrow left" />
						</Button>
						<Button
							onClick={() => {
								this.nextWeek();
							}}
						>
							<Icon name="arrow right" />
						</Button>
					</View>
				)}
				{_menu.map((value, index) => {
					return (
						<Menu.Item
							key={index}
							name={`${value.key}`}
							active={value.key === sidemenu}
							onClick={() => {
								this.setState({ sidemenu: value.key, selected: value });
								if (!isEmployee) {
									history.push(route_from(app, value.key));
								} else {
									this.props.workdayResult(value);
								}
							}}
						>
							{isEmployee && (
								<Statistic color={value.color}>
									<Statistic.Value>{value.date.format('DD')}</Statistic.Value>
									<Statistic.Label>
										{value.date.format('MM')}/{value.date.format('YYYY')}
									</Statistic.Label>
								</Statistic>
							)}
							{!isEmployee && <Icon name={value.icon} />}
							{!isEmployee && value.label}
						</Menu.Item>
					);
				})}
				<Image style={{ position: 'absolute', width: 160, top: 0 }} src={require('assets/logo.png')} />

				<span style={{ position: 'absolute', bottom: 8, left: 8, fontFamily: 'monospace' }}>{`${process.env.REACT_APP_VERSION}`}</span>
			</Menu>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		workdayRequest: (result) => WORKDAY_ACTIONS.request(result, dispatch),
		workdayResult: (result) => WORKDAY_ACTIONS.result(result, dispatch),
		workdayError: (result) => WORKDAY_ACTIONS.error(result, dispatch)
	};
};

const mapStateToProps = (state, ownProps) => {
	let { workday } = state;

	return {
		workday
	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(SideBar))));
