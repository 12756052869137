export function getImageList(page, items) {
	let { getImageListRequest, getImageListError, getImageListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_image_list',
		this.createBundle(
			'api/get_image_list',
			{ page, items },
			this.GET,
			getImageListRequest,
			getImageListResult,
			getImageListError
		)
	);
}
