export function createCost(data) {
	let { createCostRequest, createCostError, createCostResult } = this.props;
	
	this.doAction(
		this.WEB_REQUEST_URL + 'api/create_cost',
		this.createBundle(
			'api/create_cost',
			{ cost: data },
			this.POST,
			createCostRequest,
			createCostResult,
			createCostError
		)
	);
}
