import moment from 'moment-with-locales-es6';

export function buildFileSelector(onChange) {
	window.fileCallback = onChange;

	const fileSelector = document.createElement('input');
	fileSelector.setAttribute('type', 'file');
	//fileSelector.setAttribute('myfunc', onChange);
	//fileSelector.setAttribute('onchange', "fileCallback(e)");
	fileSelector.addEventListener('change', onChange);
	//fileSelector.setAttribute('multiple', 'multiple');
	return fileSelector;
}

export function floatTimeToObject(float) {
	let number = Math.floor(float);
	let decimal = float - number;
	return timeObject(number, Math.round(decimal * 60));
}

export function floatTimeToString(float) {
	let number = Math.floor(float);
	let decimal = float - number;
	return timeString(number, Math.round(decimal * 60));
}

export function timeObject(hours, minutes) {
	let hstring = '';
	let mstring = '';

	hours = parseInt(hours);
	minutes = parseInt(minutes);
	if(isNaN(hours)) {
		hours = 0;
	}

	if(isNaN(minutes)) {
		minutes = 0;
	}

	if (hours === 0 && minutes === 0) {
		return {
			hstring: '--',
			mstring: ''
		};
	}

	if (hours === 0) {
		hstring = '';
	} else if (hours === 1) {
		hstring += `${hours} ora`;
	} else {
		hstring += `${hours} ore`;
	}

	if (minutes === 0) {
		mstring = '';
	} else if (minutes === 1) {
		mstring = `${minutes} minuto`;
	} else {
		mstring += `${minutes} minuti`;
	}

	return {
		hstring,
		mstring
	};
}

function stringEmpty(string) {
	return !string || string === '' || string === '--';
}

export function timeString(hours, minutes) {
	let { hstring, mstring } = timeObject(hours, minutes);
	let noHourString = stringEmpty(hstring);
	let noMinString = stringEmpty(mstring);

	if(noHourString && mstring) {
		return mstring;
	} else if(hstring && noMinString) {
		return hstring;
	} else if(noHourString && noMinString) {
		return "Tempo di lavoro non registrato";
	}

	return `${hstring} ${mstring}`;
}

export function getRandomColor() {
	var letters = '0123456789ABCDEF';
	var color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

// https://stackoverflow.com/questions/1484506/random-color-generator
export function rainbow(numOfSteps, step) {
	// This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
	// Adam Cole, 2011-Sept-14
	// HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
	var r, g, b;
	var h = step / numOfSteps;
	var i = ~~(h * 6);
	var f = h * 6 - i;
	var q = 1 - f;
	switch (i % 6) {
		case 0:
			r = 1;
			g = f;
			b = 0;
			break;
		case 1:
			r = q;
			g = 1;
			b = 0;
			break;
		case 2:
			r = 0;
			g = 1;
			b = f;
			break;
		case 3:
			r = 0;
			g = q;
			b = 1;
			break;
		case 4:
			r = f;
			g = 0;
			b = 1;
			break;
		case 5:
			r = 1;
			g = 0;
			b = q;
			break;
	}
	var c =
		'#' +
		('00' + (~~(r * 255)).toString(16)).slice(-2) +
		('00' + (~~(g * 255)).toString(16)).slice(-2) +
		('00' + (~~(b * 255)).toString(16)).slice(-2);
	return c;
}
