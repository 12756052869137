import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import View from 'lib/View';
import { Work, CustomTable, Confirmation } from '../components';
import { Jobs, JobEditor } from '../containers';

import { Placeholder, Card, Button, Statistic, Modal, Icon } from 'semantic-ui-react';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import moment from 'moment-with-locales-es6';
import { floatTimeToObject } from '../lib/util';
moment.locale('it');

class Works extends Component {
	constructor(props) {
		super(props);
		console.log("selected from props", props.selected);
		this.state = {
			page: 0,
			items: props.noPagination ? -1 : 20,
			selected: props.selected ? props.selected : {},
			job: props.job ? props.job : '',
			deleting: false,
			showCreationDialog: false,
			currentDateRange: []
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return nextProps;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { page, items, search, searchable, assignWork, network, job } = this.state;
		let {
			date,
			worker,
			fetching,
			getWorkList,
			dateRange,
			invertSelection,
			onChange,
			works,
			isEmployee,
			fixedWorks,
			minus
		} = this.props;

		let searchText = search.data;
		if (!searchable) {
			searchText = '';
		}

		let { currentDateRange } = this.state;

		if (
			dateRange &&
			(!moment(dateRange[0]).isSame(moment(currentDateRange[0])) ||
				!moment(dateRange[1]).isSame(moment(currentDateRange[1])))
		) {
			this.setState({ currentDateRange: [ moment(dateRange[0]), moment(dateRange[1]) ] });
		}

		if (
			!fixedWorks &&
			!date &&
			!worker &&
			(page !== prevState.page ||
				items !== this.state.items ||
				search !== prevState.search ||
				(assignWork !== prevState.assignWork && !assignWork.fetching && prevState.assignWork.fetching) ||
				(this.props.creatingWork !== prevProps.creatingWork &&
					!this.props.creatingWork &&
					this.props.createStatus.success) ||
				(this.props.deletingWork !== prevProps.deletingWork &&
					this.props.deleteResult !== prevProps.deleteResult &&
					this.props.deleteStatus.success) ||
				minus !== prevProps.minus ||
				(dateRange &&
					(!moment(dateRange[0]).isSame(moment(currentDateRange[0])) ||
						!moment(dateRange[1]).isSame(moment(currentDateRange[1])))))
		) {
			let dates = null;
			if (dateRange) {
				dates = [ moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD') ];
			}

			setTimeout(() => {
				network.getWorkList(page, items, searchText, job, dates, minus);
			}, 100);
		} else if (
			(!fixedWorks && date && worker && (getWorkList !== prevState.getWorkList || date !== prevProps.date)) ||
			(this.props.creatingWork !== prevProps.creatingWork &&
				!this.props.creatingWork &&
				this.props.createStatus.success) ||
			(this.props.deletingWork !== prevProps.deletingWork &&
				this.props.deleteResult !== prevProps.deleteResult &&
				this.props.deleteStatus.success) ||
			minus !== prevProps.minus ||
			dateRange !== prevProps.dateRange
		) {
			network.getWorkListForDate(date, worker, !!isEmployee);
		}

		if (fetching !== prevProps.fetching && !fetching && invertSelection && onChange) {
			onChange(works.data);
		}
	}

	next() {
		this.setState({
			page: this.state.page + 1
		});
	}

	prev() {
		if (this.state.page === 0) return;

		this.setState({
			page: this.state.page - 1
		});
	}

	componentDidMount() {
		let { network, page, items, search, job } = this.state;
		let {
			fixedWorks,
			date,
			worker,
			sub,
			noPagination,
			dateRange,
			isEmployee,
			searchable,
			deletable,
			minus
		} = this.props;

		let menuitems = [];

		let searchText = search.data;
		if (!searchable) {
			searchText = '';
		} else {
		}

		if (deletable) {
			menuitems.push({
				name: 'Elimina',
				icon: 'delete',
				position: 'left',
				disabled: () => {
					return false;
				},
				action: () => {
					this.startDelete();
				}
			});
		}
		/*
		if (this.state.job === '00') {
			menuitems.push({
				name: 'Assegna lavori selezionati',
				position: 'none',
				action: () => {
					this.doAssign();
				}
			});

			menuitems.push({
				name: 'Crea nuova commessa con lavori selezionati',
				position: 'none',
				action: () => {
					this.createJobAndAssign();
				}
			});
		}
		*/
		if (!sub && !noPagination) {
			menuitems.push({
				name: '',
				icon: 'arrow left',
				position: 'right',
				action: () => {
					this.prev();
				}
			});

			menuitems.push({
				name: '',
				icon: 'arrow right',
				position: 'none',
				action: () => {
					this.next();
				}
			});
		}

		if (!sub) {
			this.props.toolbarResult(menuitems);
		}

		if (!fixedWorks) {
			if (date) {
				network.getWorkListForDate(date, worker, isEmployee);
			} else {
				let dates = null;
				if (dateRange) {
					dates = [ moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD') ];
				}
				network.getWorkList(page, items, '', job, dates, minus);
			}
		} else {
			this.setState({
				works: fixedWorks,
				fetching: false
			});
		}
	}

	invert(selected, works) {
		let inverted = [];
		for (let i in works) {
			let work = works[i];
			let id = `${work.dataRegistrazione}_${work.codiceCommissione}_${work.idImpiegato}`;
			if (!(id in selected)) {
				inverted.push(work);
			}
		}
		return inverted;
	}

	select(work, skipChange) {
		let { onChange, invertSelection, fixedWorks } = this.props;
		let { selected, works: { data: works } } = this.state;

		if (fixedWorks) {
			works = fixedWorks;
		}

		let id = `${work.dataRegistrazione}_${work.codiceCommissione}_${work.idImpiegato}`;
		if (selected[id]) {
			delete selected[id];
		} else {
			selected[id] = work;
		}

		if (!skipChange && onChange) {
			if (invertSelection) {
				onChange(this.invert(selected, works));
			} else onChange(selected);
		}

		this.setState({ selected });
	}

	startDelete() {
		this.setState({
			deleting: true
		});
	}

	endDelete() {
		this.setState({
			deleting: false
		});
	}

	doDelete() {
		let { selected } = this.state;
		let { network, isEmployee } = this.props;
		/*
		for (let i in selected) {
			let work = selected[i];
			network.deleteWork(
				{
					idImpiegato: work.idImpiegato,
					codiceCommissione: work.codiceCommissione,
					dataRegistrazione: moment(work.dataRegistrazione).format('YYYY-MM-DD HH:mm:ss')
				},
				!!isEmployee
			);
		}*/
		let toDelete = [];
		for (let i in selected) {
			let work = selected[i];
			toDelete.push({
				idImpiegato: work.idImpiegato,
				codiceCommissione: work.codiceCommissione,
				dataRegistrazione: moment(work.dataRegistrazione).format('YYYY-MM-DD HH:mm:ss')
			});
		}

		network.deleteWorkList(toDelete, isEmployee);
	}

	createJobAndAssign() {
		this.setState({
			showCreationDialog: true
		});
	}

	doAssign() {
		let { selected, jobToAssign } = this.state;
		let { network } = this.props;
		let works = [];

		for (let i in selected) {
			works.push(selected[i]);
		}

		network.assignWork(works, jobToAssign);
	}

	closeModal() {
		this.setState({
			showCreationDialog: false
		});
	}

	render() {
		let { isEmployee, job, worker, date, fixedWorks, compact, childProps, ...rest } = this.props;
		let { works: { data: works }, deletingWork, deleting, showCreationDialog } = this.state;
		let content = <div />;

		if (fixedWorks) {
			works = fixedWorks;
		}

		let direction = {
			column: true
		};

		let totalworkerhours = 0;
		let totalmachinehours = 0;
		let workerTimeObject = {};
		let machineTimeObject = {};
		if (works && isEmployee) {
			for (let work of works) {
				totalworkerhours += work.oreOperaio + work.minutiOperaio / 60;
				totalmachinehours += work.oreMacchina + work.minutiMacchina / 60;
			}
		}
		workerTimeObject = floatTimeToObject(totalworkerhours);
		machineTimeObject = floatTimeToObject(totalmachinehours);

		/*
		if(compact) {
			direction = {
				row: true,
				wrap: true
			};
		} else {
			direction = {
				column: true
			};
		}
*/
		if (this.state.fetching || deletingWork) {
			let placeholders = [];
			for (let i = 0; i < 10; i++) {
				placeholders.push(
					<Card key={i} style={{ width: '100%', margin: 8 }}>
						<Card.Content>
							<Placeholder>
								<Placeholder.Header image>
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Header>
								<Placeholder.Paragraph>
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Paragraph>
							</Placeholder>
						</Card.Content>
					</Card>
				);
			}
			content = (
				<View fullw column>
					{placeholders}
				</View>
			);
		} else if (works) {
			return (
				<View fullw fullh column>
					<Confirmation
						showModal={deleting}
						message="Sicuro di voler eliminare i lavori? Questa azione non e' reversibile."
						onConfirm={() => {
							this.doDelete();
							this.endDelete();
						}}
						onCancel={() => {
							this.endDelete();
						}}
					/>

					<View
						style={{
							height: this.props.fullh ? '100%' : 'calc(100% - 100px)',
							overflowY: compact ? 'hidden' : 'scroll'
						}}
						fullw
						column
					>
						<CustomTable
							{...rest}
							{...{ compact, childProps }}
							nolabel={true}
							name="Lavori"
							shorthand="work"
							values={works}
							child={Work}
							required="dataRegistrazione"
							selected={(work) => {
								let id = `${work.dataRegistrazione}_${work.codiceCommissione}_${work.idImpiegato}`;
								let bool = id in this.state.selected;
								if (this.props.invertSelection) {
									bool = !bool;
								}
								return bool;
							}}
							onRowClick={(work) => {
								this.select(work);
							}}
						/>
						{isEmployee &&
						date && (
							<View style={{ minHeight: 103 }} fullw row around>
								<Statistic style={{ marginBottom: 0, alignSelf: 'center' }}>
									<Statistic.Label>Totale ore operaio</Statistic.Label>
									<Statistic.Value className="smallStatistic">
										{workerTimeObject.hstring}
									</Statistic.Value>
									<Statistic.Value className="smallStatistic">
										{workerTimeObject.mstring}
									</Statistic.Value>
								</Statistic>
								<Statistic style={{ alignSelf: 'center' }}>
									<Statistic.Label>Totale ore macchina</Statistic.Label>
									<Statistic.Value className="smallStatistic">
										{machineTimeObject.hstring}
									</Statistic.Value>
									<Statistic.Value className="smallStatistic">
										{machineTimeObject.mstring}
									</Statistic.Value>
								</Statistic>
							</View>
						)}
					</View>
				</View>
			);
		}

		return (
			<View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
				{content}
			</View>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let {
		getWorkList,
		getWorkListForDate,
		assignWork,
		search,
		createWork: { data: createResult, fetching: creatingWork, status: createStatus },
		deleteWorkList: {
			data: deleteResult,
			fetching: deletingWork,
			status: deleteStatus,
			request_data: deleteRequest
		}
	} = state;
	let { date } = ownProps;
	let works = date ? getWorkListForDate.data : getWorkList.data;

	return {
		works,
		fetching: date ? getWorkListForDate.fetching : getWorkList.fetching,
		assignWork,
		search,
		createResult,
		creatingWork,
		createStatus,
		deleteResult,
		deletingWork,
		deleteStatus,
		deleteRequest
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(Works))));
