export function getDetailedJobList(page, items, search, isEmployee, external) {
	let { getDetailedJobListRequest, getDetailedJobListError, getDetailedJobListResult } = this.props;

	let bundle = this.createBundle(
		'api/get_detailed_job_list',
		{
			page,
			items,
			search,
			external: external ? 1 : 0
		},
		this.GET,
		getDetailedJobListRequest,
		getDetailedJobListResult,
		getDetailedJobListError,
		isEmployee
	);

	this.doAction(this.WEB_REQUEST_URL + 'api/get_detailed_job_list', bundle);
}
