
import { GETLASTGT2000DATA_TYPES } from '../actions/getLastGT2000Data'
import reducer, { defaultState } from './requests'
const Reducer = (state = defaultState, action) => {
    let requestReducer = reducer(GETLASTGT2000DATA_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}
export default Reducer