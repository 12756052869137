export function updateMachine(data) {
	let { updateMachineRequest, updateMachineError, updateMachineResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/update_machine',
		this.createBundle(
			'api/update_machine',
			{ machine: data },
			this.POST,
			updateMachineRequest,
			updateMachineResult,
			updateMachineError
		)
	);
}
