export function getSWA300Data(start, end) {
	let { getSWA300DataRequest, getSWA300DataError, getSWA300DataResult } = this.props;

	console.log("Getting SWA300 data", start, end)
	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_swa300_data',
		this.createBundle(
			'api/get_swa300_data',
			{ start, end },
			this.POST,
			getSWA300DataRequest,
			getSWA300DataResult,
			getSWA300DataError
		)
	);
}
