export function createWork(data, isEmployee) {
	let { createWorkRequest, createWorkError, createWorkResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/create_work',
		this.createBundle(
			'api/create_work',
			{ work: data },
			this.POST,
			createWorkRequest,
			createWorkResult,
			createWorkError,
			isEmployee
		)
	);
}
