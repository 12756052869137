export function assignWork(works, job) {
	let { assignWorkRequest, assignWorkError, assignWorkResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/assign_work',
		this.createBundle(
			'api/assign_work',
			{ works, job },
			this.POST,
			assignWorkRequest,
			assignWorkResult,
			assignWorkError
		)
	);
}
