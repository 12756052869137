export function createJob(id, job) {
	let { createJobRequest, createJobError, createJobResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/create_job',
		this.createBundle(
			'api/create_job',
			{ id, job },
			this.POST,
			createJobRequest,
			createJobResult,
			createJobError
		)
	);
}
