export function getItemList(data) {
	let { getItemListRequest, getItemListError, getItemListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_item_list',
		this.createBundle(
			'api/get_item_list',
			{ data: data },
			this.GET,
			getItemListRequest,
			getItemListResult,
			getItemListError
		)
	);
}
