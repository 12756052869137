import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'

import { Form, Checkbox, Step, Icon, Button, Label } from 'semantic-ui-react'
import ReactToPrint from 'react-to-print'
import View from 'lib/View'

import { Job } from '../components'
import { Works, Items } from './'

import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'

import moment from 'moment-with-locales-es6'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import itIT from 'date-fns/locale/it'

moment.locale('it')

/*
console.log("it it locale", itIT);
itIT.localize = itIT.format;
registerLocale('it-IT', itIT);*/

/*
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import itLocale from 'react-semantic-ui-datepickers/dist/locales/it-IT';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
*/

class FinalBalance extends Component {
	constructor(props) {
		super(props)
		let { job } = this.props.match.params

		this.state = {
			step: 0,
			job,
			search: '',
			dateRange: [ moment().endOf('month').subtract(1, 'years').toDate(), moment().endOf('month').toDate() ],
			minus: true,
			employees: [],
			machines: [],
			costs: []
		}

		this.printRef = React.createRef()
		this.printButton = React.createRef()
	}

	componentDidMount() {
		let { network } = this.props
		let { job } = this.state
		network.getCostList(job)
		network.getJob(job)
		this.updateMenu()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.step !== prevState.step) {
			this.updateMenu()
		}

		let { job } = this.state
		let { getJob } = this.props
		if (getJob && getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching) {
			if (getJob.status.success) {
				let jobInfo = getJob.data[job][0]
				this.setState(
					{
						jobInfo,
						dateRange:
							!jobInfo || !jobInfo.dataUltimaFatturazione
								? this.state.dateRange
								: [ moment(jobInfo.dataUltimaFatturazione).toDate(), this.state.dateRange[1] ]
					},
					this.updateMenu
				)
			}
		}
	}

	updateMenu() {
		let { sub } = this.props
		if (!sub) {
			//console.log('FinalBalance didmount');
			let menuitems = [
				{
					name: 'Da ultima fatturazione',
					icon: null,
					position: 'none',
					disabled: () => {
						let { step, jobInfo } = this.state
						return step !== 0 || !jobInfo || !jobInfo.dataUltimaFatturazione
					},
					action: () => {
						let { jobInfo } = this.state

						console.log('Data ultima fatturazione commessa: ', jobInfo, jobInfo.dataUltimaFatturazione)
						this.setState({
							dateRange: [ moment(jobInfo.dataUltimaFatturazione).toDate(), this.state.dateRange[1] ]
						})
					}
				},
				{
					name: 'Fino a oggi',
					icon: null,
					position: 'none',
					disabled: () => {
						return this.state.step !== 0
					},
					action: () => {
						this.setState({
							dateRange: [ this.state.dateRange[0], moment().toDate() ]
						})
					}
				},
				{
					name: 'Ultimo mese',
					icon: null,
					position: 'none',
					disabled: () => {
						return this.state.step !== 0
					},
					action: () => {
						this.setState({
							dateRange: [
								moment().subtract(1, 'month').startOf('month').toDate(),
								moment().startOf('month').subtract(1, 'day').toDate()
							]
						})
					}
				},
				{
					name: 'Stampa',
					icon: 'print',
					position: 'none',
					disabled: () => {
						return this.state.step !== 1
					},
					action: () => {
						document.getElementById('print-button-balance').click()
						/*
						if (this.printButton.current) {
							console.log('Printing', this.printButton);
							this.printButton.handlePrint();
						}*/
					}
				}
			]
			this.props.toolbarResult(menuitems)
		}
	}

	prev() {
		let { step } = this.state

		this.clearSearch()
		this.setState({ step: Math.max(0, step - 1) })
	}

	next() {
		let { step } = this.state

		this.clearSearch()
		this.setState({ step: Math.min(2, step + 1) })
	}

	clearSearch() {
		this.setState({
			search: ''
		})
		this.props.searchResult('')
	}

	render() {
		let { step, job, dateStart, dateEnd, employees, machines, dateRange, works, minus, itemCosts } = this.state
		let { costs } = this.props

		let content = <div />

		content = [
			<Step.Group key="stepgroup">
				<Step active={step === 0} completed={step > 0}>
					<Icon name="book" />
					<Step.Content>
						<Step.Title>Lavori da considerare</Step.Title>
						<Step.Description>Quali lavori includere nel calcolo del consuntivo</Step.Description>
					</Step.Content>
				</Step>

				<Step active={step === 1} completed={step > 1} disabled={step < 1}>
					<Icon name="hdd" />
					<Step.Content>
						<Step.Title>Risultato</Step.Title>
						<Step.Description>Bilancio finale</Step.Description>
					</Step.Content>
				</Step>
			</Step.Group>,
			step < 1 ? (
				<View row fullw className="fullWidthDatePicker" key="stepheader">
					<Label>Inizio:</Label>
					<DatePicker
						style={{ width: '100%', textAlign: 'center' }}
						onChange={(data) => {
							//console.log('Date change', data);
							let { dateRange } = this.state
							dateRange[0] = data
							this.setState({ dateRange })
						}}
						selected={dateRange[0]}
						dateFormat="dd/MM/yyyy"
					/>
					<Label>Fine:</Label>
					<DatePicker
						style={{ width: '100%', textAlign: 'center' }}
						onChange={(data) => {
							console.log('Date change', data)
							let { dateRange } = this.state
							dateRange[1] = data
							this.setState({ dateRange })
						}}
						selected={dateRange[1]}
						dateFormat="dd/MM/yyyy"
					/>
					<View around>
						<Checkbox
							toggle
							value={minus}
							checked={minus}
							label="Sottrai ore macchina"
							onChange={(e, data) => {
								//console.log('Checkbox value', e, data.checked);
								this.setState({ minus: data.checked })
							}}
						/>
					</View>
				</View>
			) : null,
			<View column fullw fullh style={{ marginTop: 0 }} key="stepcontent">
				{
					[
						<View key="balance_1" around column fullw fullh style={{ height: '95%' }}>
							<Works
								sub
								noActions
								job={job}
								dateRange={dateRange}
								noPagination
								searchable
								selectable
								invertSelection
								minus={minus}
								onChange={(works) => {
									//console.log('FinalBalance got works', works);
									this.setState({ works })
								}}
								fullh
							/>
						</View>,
						<View key="balance_2" column fullw fullh style={{ height: '95%' }}>
							<ReactToPrint
								ref={(el) => (this.printButton = el)}
								trigger={() => (
									<button id="print-button-balance" style={{ display: 'none' }}>
										Stampa
									</button>
								)}
								content={() => {
									//console.log('printing content', this.printRef);
									return this.printRef.current
								}}
							/>
							<div ref={this.printRef}>
								<Job sub jobId={job} works={works} costs={costs} noActions type="full" isBalance />
							</div>
						</View>
					][step]
				}
				<View
					fullw
					style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}
				>
					<Button onClick={() => this.prev()}>Indietro</Button>
					{step < 1 && (
						<Button color="black" onClick={() => this.next()}>
							Avanti
						</Button>
					)}
					{step === 1 && (
						<Button color="green" onClick={() => this.save()}>
							Fine
						</Button>
					)}
				</View>
			</View>
		]

		return (
			<View fullw fullh column style={{ padding: 16 }}>
				{content}
			</View>
		)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	}
}

const mapStateToProps = (state, ownProps) => {
	let { getCostList: { data: { data: costs } }, getJob, getItemCosts } = state

	return {
		costs,
		getJob,
		getItemCosts
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(FinalBalance)))
