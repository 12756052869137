export function getJob(jobId) {
	let { getJobRequest, getJobError, getJobResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_job',
		this.createBundle(
			'api/get_job',
			{ jobId },
			this.GET,
			getJobRequest,
			getJobResult,
			getJobError
		)
	);
}
