export function getCost(data) {
	let { getCostRequest, getCostError, getCostResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_cost',
		this.createBundle(
			'api/get_cost',
			{ costId: data },
			this.GET,
			getCostRequest,
			getCostResult,
			getCostError
		)
	);
}
