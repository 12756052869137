import { LOGIN_TYPES } from '../actions/login';
import reducer, { defaultState } from './requests';

const Reducer = (state = { ...defaultState, authenticated: false }, action) => {
	let requestReducer = reducer(LOGIN_TYPES)(state, action);

	switch (action.type) {
		case LOGIN_TYPES.error:
			return {
				...requestReducer,
				authenticated: false
			};
		case LOGIN_TYPES.result:
			return {
				...requestReducer,
				authenticated: true
			};
		case LOGIN_TYPES.logout:
			return {
				...defaultState,
				authenticated: false
			};
		default:
			return state;
	}
};
export default Reducer;
