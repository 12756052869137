import React, { Component } from 'react'
import Radium from 'radium'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'

import {} from 'semantic-ui-react'

import View from 'lib/View'

class Admin extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return <View fullw>Amministratori</View>
	}
}

const mapStateToProps = (state, ownProps) => ({})
export default withRouter(connect(mapStateToProps)(Radium(connectNetwork(Admin))))
