import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { Form, Input, Select, Button, Message, TextArea } from 'semantic-ui-react';

import View from 'lib/View';

import { Job, Empty, PickImage } from '../components';
import { rainbow } from '../lib/util';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import loadingImage from '../assets/loadingParagraph.png';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import itIT from 'date-fns/locale/it';

import moment from 'moment-with-locales-es6';

moment.locale('it');

class CostEditor extends Component {
	constructor(props) {
		super(props);
		let id = props.id;
		
		let { job, cost } = this.props.match.params;

		if (!id) {
			id = cost;
		}

		this.state = {
			id,
			idCosto: id,
			codiceCommissione: job ? job : '',
			dataCosto: new Date(),
			descrizioneCosto: '',
			fornitore: '',
			prezzoCosto: 0.0
		};
	}

	componentDidMount() {
		let { id } = this.state;

		this.props.toolbarResult([
			{
				name: 'Salva',
				icon: 'save',
				position: 'right',
				action: () => {
					this.save();
				}
			}
		]);

		if (id) {
			this.state.network.getCost(id);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.creatingCost !== prevProps.creatingCost) {
			if (!this.props.creatingCost) {
				if (this.props.createStatus.success) {
					// Navigate back, success!
					this.setState({
						status: 'success'
					});
					this.props.history.goBack();
				} else {
					this.setState({
						status: 'error',
						errorMessage: ''
					});
				}
			}
		}

		let { getCost } = this.props;
		if (getCost && getCost.fetching !== prevProps.getCost.fetching && !getCost.fetching) {
			if (getCost.status.success) {
				let cost = getCost.data.data;
				
				let momobj = moment(cost.dataCosto, 'YYYY-MM-DD').add('days', 1);
				
				if (momobj.isValid()) {
					cost.dataCosto = momobj.toDate();
				} else {
					cost.dataCosto = new Date();
				}

				this.setState(cost);
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return nextProps;
	}

	save() {
		// TODO value checking
		let { options, id, codiceCommissione, dataCosto, descrizioneCosto, fornitore, prezzoCosto } = this.state;

		if (codiceCommissione === '' || fornitore === '') {
			this.setState({
				status: 'error'
			});
			return;
		}

		let parsedDate = moment(dataCosto).format('YYYY-MM-DD HH:mm:ss');

		this.props.network.createCost({
			idCosto: id,
			codiceCommissione,
			dataCosto: parsedDate,
			descrizioneCosto,
			fornitore,
			prezzoCosto
		});
	}

	handleInput(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	render() {
		let {
			creatingCost,
			codiceCommissione,
			dataCosto,
			descrizioneCosto,
			fornitore,
			prezzoCosto,
			errorMessage
		} = this.state;

		return (
			<View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
				<Form
					loading={creatingCost}
					success={this.state.status === 'success'}
					error={this.state.status === 'error'}
					warning={this.state.status === 'warning'}
				>
					<Form.Group widths="equal">
						<Form.Field
							id="form-input-control-cost-id"
							control={Input}
							label="Codice commessa"
							name="codiceCommissione"
							value={codiceCommissione}
							onChange={(e) => this.handleInput(e)}
							placeholder="C000"
						/>
						<Form.Field
							id="form-input-control-cost-description"
							control={Input}
							label="Descrizione"
							placeholder="Fresature"
							name="descrizioneCosto"
							value={descrizioneCosto}
							onChange={(e) => this.handleInput(e)}
						/>
						<Form.Field
							id="form-input-control-job-bill-date"
							control={DatePicker}
							label="Data costo"
							name="dataCosto"
							value={dataCosto}
							dateFormat="dd/MM/yyyy"
							selected={dataCosto}
							onChange={(data) => {
								this.setState({
									dataCosto: data
								});
							}}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							id="form-textarea-control-provider"
							control={Input}
							label="Fornitore"
							placeholder="Clementoni"
							name="fornitore"
							value={fornitore}
							onChange={(e) => this.handleInput(e)}
						/>
						<Form.Field
							id="form-input-control-cost-cost"
							control={Input}
							type="number"
							label="Prezzo"
							placeholder="0.0"
							step="0.1"
							name="prezzoCosto"
							value={prezzoCosto}
							onChange={(e) => this.handleInput(e)}
						/>
					</Form.Group>
					<Form.Field
						id="form-button-control-save"
						control={Button}
						content="Salva"
						onClick={() => {
							this.save();
						}}
					/>
					<Message success header="Form Completed" content="Costo inserito con successo!" />
					<Message
						error
						header="Errore"
						content={`Errore del server, \nriprova piu' tardi. ${errorMessage}`}
					/>
					<Message warning header="Errore inserimento" list={[ 'Ricontrolla i dati inseriti' ]} />
				</Form>
			</View>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let { createCost: { data: createResult, fetching: creatingCost, status: createStatus }, getCost } = state;

	return {
		createResult,
		creatingCost,
		createStatus,
		getCost
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(CostEditor))));
