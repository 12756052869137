import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { Button, Input, Label } from 'semantic-ui-react';
import View from 'lib/View';

import {} from 'containers';
import Moment from 'react-moment';
import moment from 'moment-with-locales-es6';

moment.locale('it');
Moment.globalMoment = moment;
Moment.globalLocale = 'it';

class Numpad extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
			digits: [ '7', '8', '9', '4', '5', '6', '1', '2', '3', '0', 'C', 'CE' ]
		};
	}

	pressDigit(digit) {
		let { onChange } = this.props;
		let value = 0;
		if (digit === 'C') {
			value = this.removeDigit();
		} else if (digit === 'CE') {
			this.setState({
				value: 0
			});
		} else {
			let num = parseInt(digit);
			value = this.addDigit(num);
		}

		if (onChange) {
			onChange(value);
		}
	}

	removeDigit() {
		let newvalue = Math.floor(this.state.value / 10);
		this.setState({
			value: newvalue
		});
		return newvalue;
	}

	addDigit(num) {
		let newvalue = this.state.value * 10 + num;
		this.setState({
			value: newvalue
		});
		return newvalue;
	}

	renderDigits(digits) {
		return digits.map((value, index) => {
			return (
				<Button
					style={{
						flex: 1,
						height: 100,
						margin: 8
                    }}
                    color={value === 'C' || value === 'CE' ? 'red' : 'grey'}
					onClick={() => {
						this.pressDigit(value);
					}}
				>
					{value}
				</Button>
			);
		});
	}

	renderDigitRow(number) {
		let { digits } = this.state;
		let digrow = digits.slice(number*3, number*3 + 3);
		
		return <View fullw row>{this.renderDigits(digrow)}</View>
	}

	render() {
		let { onChange, value, label, placeholder } = this.props;

		this.renderDigitRow(0);
		this.renderDigitRow(1);
		this.renderDigitRow(2);
		this.renderDigitRow(3);
		return (
			<View fullw column around style={{ padding: 16 }}>
				<View fullw around column>
					<Label style={{ borderRadius: 0 }}>{label}</Label>
					<Input
						style={{ width: '100%' }}
						value={value}
						type="number"
						placeholder={placeholder}
						onChange={(e, data) => {
							if (data.value < 0) data.value = 0;
							if (onChange) {
								onChange(data.value);
							}
							this.setState({ value: data.value });
						}}
					/>
				</View>
				<View style={styles.numpad_container} column fullw fullh around>
					{this.renderDigitRow(0)}
					{this.renderDigitRow(1)}
					{this.renderDigitRow(2)}
					{this.renderDigitRow(3)}
				</View>
			</View>
		);
	}
}

const styles = {
	numpad_container: {
        margin: "0px auto"
	}
};

const mapStateToProps = (state, ownProps) => {
	return {};
};
export default connect(mapStateToProps)(Radium(connectNetwork(Numpad)));
