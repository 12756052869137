const path = require('path');

//vfstampi/commesse/d032/consuntivo

export const routes = {
	login: 'login',

	app: 'app',
	jobs: 'jobs',
	job: 'job',
	works: 'works',
	employees: 'employees',
	machines: 'machines',
	warehouse: 'warehouse',
	monitor: 'monitor',
	admin: 'admin',
	jobEdit: 'jobEdit',
	jobMerge: 'jobMerge',
	newEl: 'newEl',
	workerEdit: 'workerEdit',
	machineEdit: 'machineEdit',
	costEdit: 'costEdit',
	costUpdate: 'costUpdate',
	finalBalance: 'finalBalance',
	tempJobs: 'tempJobs',
	saldatrice: 'saldatrice',
	babypressa: 'babypressa',
	gt2000: 'gt2000',
	saldatriceeditor: 'saldatriceeditor',

	vfconsole: 'vfconsole',
	pin: 'pin',
	employee: 'employee',
	insertion: 'insertion',
	calendarView: 'calendarView'
};

export const routeNames = {
	login: '/',
	app: '/VFStampi',
	vfconsole: '/VFConsole',
	employee: '/Impiegato',
	insertion: '/Inserimento',
	finalBalance: '/Consuntivo',
	tempJobs: 'Commesse dal magazzino',
	pin: 'Pin',
	jobs: 'Commesse',
	job: 'Commessa',
	works: 'Lavori',
	employees: 'Impiegati',
	machines: 'Macchine',
	warehouse: 'Magazzino',
	admin: 'Amministratori',
	jobEdit: 'Editor commessa',
	jobMerge: 'Unione commesse',
	saldatriceeditor: 'Invio dati a saldatrice',
	newEl: 'Nuovo',
	workerEdit: 'Editor impiegato',
	machineEdit: 'Editor macchina',
	costEdit: 'Editor costi',
	costUpdate: 'Modifica costo',
	calendarView: '/Calendario',
	gt2000: 'C.B. Ferrari GT2000',
	monitor: 'C.B. Ferrari',
	saldatrice: 'Saldatrice SWA300',
	babypressa: 'Baby Pressa',
};

export const route_from = (...args) => {
	let toJoin = args.map((value) => {
		let name = routeNames[value];
		if (!name) {
			name = value;
		}
		return name;
	});
	return path.join(...toJoin);
};

export const is_in = (path, route) => {
	return path.includes(routeNames[route]);
};

export const get_section = (path) => {
	for (let [ key, value ] of Object.entries(routeNames)) {
		if (path.includes(value)) return key;
	}
	return Object.keys(routes)[0];
};
