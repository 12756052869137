export function getJobTypeList(data) {
	let { getJobTypeListRequest, getJobTypeListError, getJobTypeListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_job_type_list',
		this.createBundle(
			'api/get_job_type_list',
			{ data: data },
			this.GET,
			getJobTypeListRequest,
			getJobTypeListResult,
			getJobTypeListError
		)
	);
}
