import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';
import { debounce } from 'throttle-debounce';

import { Segment, Header, Step, Icon, Button, Modal } from 'semantic-ui-react';
import View from 'lib/View';

import { Job } from '../components/';
import { Jobs, Works, JobEditor } from './';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import moment from 'moment-with-locales-es6';
moment.locale('it');

class WorkAssignment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.defaultState()
		};
	}

	defaultState() {
		return {
			showCreationDialog: false,
			step: 0,
			job: {},
			tempJob: {},
			works: {}
		};
	}

	componentDidMount() {
		let { sub } = this.props;
		if (!sub) {
			this.props.toolbarResult([]);
		}
	}

	prev() {
		let { step } = this.state;

		this.clearSearch();
		this.setState({ step: Math.max(0, step - 1) });
	}

	next() {
		let { step } = this.state;

		this.clearSearch();
		this.setState({ step: Math.min(2, step + 1) });
	}

	clearSearch() {
		this.props.searchResult('');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { assigningWork, assignResult, assignStatus, creatingJob, createStatus, createResult } = this.props;

		if (assigningWork !== prevProps.assigningWork) {
			if (!assigningWork) {
				if (assignStatus.success) {
					// Navigate back, success!
					console.log("Done assigning works, navigating back!");
					this.setState({
						status: 'success',
						...this.defaultState()
					});
				} else {
					if (assignStatus.error && assignStatus.error.response.status === 409) {
						this.setState({
							status: 'error',
							errorMessage: 'Dati inseriti errati'
						});
					} else {
						this.setState({
							status: 'error',
							errorMessage: ''
						});
					}
				}
			}
		}

		if (creatingJob !== prevProps.creatingJob) {
			if (!creatingJob) {
				if (createStatus.success) {
					// Navigate back, success!
					console.log("Done creating job, stepping forward!");
					this.setState({
						job: this.state.tempJob,
						step: this.state.step + 1
					});
				} else {
					if (createStatus.error && createStatus.error.response.status === 409) {
						this.setState({
							status: 'error',
							errorMessage: 'Dati inseriti errati'
						});
					} else {
						this.setState({
							status: 'error',
							errorMessage: ''
						});
					}
				}
			}
		}
	}

	createJobAndAssign() {
		this.setState({
			showCreationDialog: true
		});
	}

	doAssign() {
		let { job, works } = this.state;
		let { network } = this.props;
		let workArray = [];

		for (let i in works) {
			workArray.push(works[i]);
		}

		network.assignWork(workArray, job);
	}

	closeModal() {
		this.setState({
			showCreationDialog: false
		});
	}

	render() {
		let { step, job, works, showCreationDialog } = this.state;
		let { worker } = this.props;

		let content = <div />;

		let workCount = 0;
		let workArray = [];
		for (let i in works) {
			workCount++;
			workArray.push(works[i]);
		}

		//if (this.state.getDetailedJobList.fetching) {
		//	content = <div>Loading</div>;
		//} else if (this.state.getDetailedJobList.data.data) {
		content = [
			<Step.Group key="stepgroup">
				<Step active={step === 0} completed={step > 0}>
					<Icon name="hdd" />
					<Step.Content>
						<Step.Title>Lavori da assegnare</Step.Title>
						<Step.Description>
							{workCount > 0 ? `Selezionati ${workCount} lavori` : 'Quali lavori?'}
						</Step.Description>
					</Step.Content>
				</Step>

				<Step active={step === 1} completed={step > 1} disabled={step < 1}>
					<Icon name="book" />
					<Step.Content>
						<Step.Title>Commissione</Step.Title>
						<Step.Description>
							{job.codiceCommissione ? job.codiceCommissione : 'Quale commessa?'}
						</Step.Description>
					</Step.Content>
				</Step>

				<Step active={step === 2} completed={step > 2} disabled={step < 2}>
					<Icon name="clock" />
					<Step.Content>
						<Step.Title>Ore</Step.Title>
						<Step.Description>Quante ore lavorate?</Step.Description>
					</Step.Content>
				</Step>
			</Step.Group>,
			<Modal
				onClose={() => {
					this.closeModal();
				}}
				open={showCreationDialog}
			>
				<Modal.Header>Creazione commessa</Modal.Header>
				<JobEditor
					sub
					onChange={(job) => {
						console.log('Creating job', job);
						this.setState({ tempJob: job });
					}}
				/>
				<Modal.Actions>
					<Button
						primary
						onClick={() => {
							this.closeModal();
						}}
					>
						Annulla <Icon name="check" />
					</Button>
					<Button
						primary
						onClick={() => {
							this.props.network.createJob(null, this.state.tempJob);

							this.closeModal();
						}}
					>
						Conferma <Icon name="check" />
					</Button>
				</Modal.Actions>
			</Modal>,
			<View column fullw fullh style={{ overflow: 'hidden', marginTop: 0 }} key="stepcontent">
				{
					{
						[0]: (
							<View column fullw fullh style={{ height: '95%' }}>
								<Works
									job="00"
									sub
									searchable
									selectable
									selected={works}
									noPagination
									fullh
									onChange={(works) => {
										this.setState({ works });
									}}
								/>
							</View>
						),
						[1]: (
							<View column fullw fullh style={{ height: '95%' }}>
								<Jobs
									noActions
									sub
									searchable
									selectable
									selected={job}
									style={{ height: '100%', overflowY: 'scroll' }}
									onChange={(job) => {
										console.log('Steps chose job', job);
										this.next();
										this.setState({ job });
									}}
								/>
							</View>
						),
						[2]: (
							<View column fullw fullh style={{ overflowY: 'scroll' }}>
								<Segment.Group>
									<Segment>
										<Header>Assegnando</Header>
										<Works sub noPagination fixedWorks={workArray} />
									</Segment>
									<Segment>
										<Header>A questa commessa</Header>
										<Job type="full" noActions jobId={job.codiceCommissione} />
									</Segment>
								</Segment.Group>
							</View>
						)
					}[step]
				}
				<View
					fullw
					style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}
				>
					<Button onClick={() => this.prev()}>Indietro</Button>
					{step === 1 && (
						<Button color="orange" onClick={() => this.createJobAndAssign()}>
							Crea nuova commessa con lavori selezionati
						</Button>
					)}
					{step < 2 && (
						<Button
							color="black"
							onClick={() => this.next()}
							disabled={(step === 1 && !job.codiceCommissione) || (step === 0 && workCount === 0)}
						>
							Avanti
						</Button>
					)}
					{step === 2 && (
						<Button color="green" onClick={() => this.doAssign()}>
							Conferma
						</Button>
					)}
				</View>
			</View>
		];

		return (
			<View fullw fullh column style={{ padding: 16 }}>
				{content}
			</View>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

const mapStateToProps = (state, ownProps) => {
	let {
		createJob: { data: createResult, fetching: creatingJob, status: createStatus },
		assignWork: { data: assignResult, fetching: assigningWork, status: assignStatus }
	} = state;

	return { assignResult, assigningWork, assignStatus, createResult, creatingJob, createStatus };
};

export default connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(WorkAssignment)));
