export function loginEmployee(pin) {
	let { loginEmployeeRequest, loginEmployeeError, loginEmployeeResult } = this.props;

	let user = {
		pin
	};

	this.doAction(
		this.WEB_REQUEST_URL + 'api/login_worker',
		this.createBundle(
			'api/login_worker',
			{ user: user },
			this.POST,
			loginEmployeeRequest,
			loginEmployeeResult,
			loginEmployeeError
		)
	);
}

export function logoutEmployee() {
	const { logoutEmployee } = this.props;

	logoutEmployee();
}
