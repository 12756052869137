import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { Step, Icon, Button, Image, Card } from 'semantic-ui-react';

import defaultImage from '../assets/default.png';

class ImageDefault extends Component {
	
	get WEB_REQUEST_URL() {
		//return 'http://127.0.0.1/';
		return 'http://46.101.106.217/';
	}

	constructor(props) {
		super(props);
	}

	render() {
		let { fromRoot, src, network, ...rest } = this.props;

		let image = src && src !== '' ? src : defaultImage;

		if (fromRoot && !image.startsWith('http')) {
			//console.log("Image from root network:", network);
			image = `${this.WEB_REQUEST_URL}/${image}`;
		}

		return src && src !== '' ? <Image src={image} {...rest} /> : <Image {...rest} src={defaultImage} />;
	}
}

const mapStateToProps = (state, ownProps) => {
	return {};
};

export default withRouter(connect(mapStateToProps)(Radium(connectNetwork(ImageDefault))));
