export function updateCost(data) {
	let { updateCostRequest, updateCostError, updateCostResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/update_cost',
		this.createBundle(
			'api/update_cost',
			{ cost: data },
			this.POST,
			updateCostRequest,
			updateCostResult,
			updateCostError
		)
	);
}
