import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { connectNetwork } from 'lib/NetworkProvider';
import { route_from, routes } from 'lib/routes';

import View from 'lib/View';
import { Button, Form } from 'semantic-ui-react';

const bg_image = require('assets/login.png');
const bg_pin_image = require('assets/pin.png');

const { vfconsole, app, employee, insertion, jobs } = routes;

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			pin: ''
		};
	}

	componentDidUpdate() {
		if (this.state.pin.length === 4) {
			this.doWorkerLogin();
		}

		let { login, loginEmployee, history, network, isEmployee } = this.props;
		let { email, password, pin } = this.state;

		let { fetching, status } = isEmployee ? loginEmployee : login;

		if (!fetching && status.success) {
		}
		if (fetching) console.log('fetching');
		if (!fetching && status.error) console.log('error');
	}

	doWorkerLogin() {
		let { pin } = this.state;
		let { network } = this.props;

		network.loginEmployee(pin);
		this.setState({
			pin: ''
		});
	}

	render() {
		let { login, loginEmployee, history, network, isEmployee } = this.props;
		let { email, password, pin } = this.state;

		let { fetching, status } = isEmployee ? loginEmployee : login;

		return (
			<View fullh style={{ ...styles.container }}>
				<View style={isEmployee ? styles.bgEmployee : styles.bg} />
				<Form style={styles.form}>
					{isEmployee ? (
						<div>
							<Form.Input
								style={{
									fontSize: '42pt'
								}}
								label="Pin"
								type="password"
								value={this.state.pin}
								onChange={(e, { value }) => this.setState({ pin: value })}
							/>
							<Button
								loading={fetching}
								color="black"
								floated="right"
								onClick={this.doWorkerLogin.bind(this)}
							>
								Accedi
							</Button>
						</div>
					) : (
						<div>
							<Form.Input
								label="Utente"
								name="email"
								placeholder="Inserire il nome utente"
								onChange={(e, { value }) => this.setState({ email: value })}
							/>
							<Form.Input
								label="Password"
								type="password"
								onChange={(e, { value }) => this.setState({ password: value })}
							/>
							<Button
								loading={fetching}
								color="black"
								floated="right"
								onClick={() => network.login(email, password)}
							>
								Accedi
							</Button>
						</div>
					)}
				</Form>
			</View>
		);
	}
}

const mapStateToProps = (state) => {
	let { login, loginEmployee } = state;

	return {
		login,
		loginEmployee
	};
};
export default withRouter(connect(mapStateToProps)(Radium(connectNetwork(Login))));

const styles = {
	container: {
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	form: {
		marginRight: 300,
		marginTop: -200
	},
	bg: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundImage: `url(${bg_image})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		filter: 'blur(5px)',
		WebkitFilter: 'blur(5px)'
	},
	bgEmployee: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundImage: `url(${bg_pin_image})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		filter: 'blur(5px)',
		WebkitFilter: 'blur(5px)'
	}
};
