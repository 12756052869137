export function recap(year, month) {
	let { recapRequest, recapError, recapResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/recap',
		this.createBundle(
			'api/recap',
			{ year, month },
			this.GET,
			recapRequest,
			recapResult,
			recapError,
			false
		)
	);
}
