import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { debounce } from 'throttle-debounce';

import { Icon, Menu, Breadcrumb, Input, Header, Form, Modal, Button, Select, Checkbox } from 'semantic-ui-react';
import { route_from, routes, routeNames } from 'lib/routes';
import Calendar from 'react-calendar';
import { SEARCH_ACTIONS } from '../actions/search';
import { Recap } from './';

import 'moment/locale/it';
const moment = require('moment-timezone');
moment.tz.setDefault('Europe/Rome');
moment.locale('it');

const {
	vfconsole,
	login,
	pin,
	app,
	finalBalance,
	jobEdit,
	jobMerge,
	workerEdit,
	machineEdit,
	costEdit,
	costUpdate,
	employees,
	calendarView,
	employee,
	insertion,
	job,
	newEl
} = routes;

const months = [
	{ key: 0, text: 'Gennaio', value: 0 },
	{ key: 1, text: 'Febbraio', value: 1 },
	{ key: 2, text: 'Marzo', value: 2 },
	{ key: 3, text: 'Aprile', value: 3 },
	{ key: 4, text: 'Maggio', value: 4 },
	{ key: 5, text: 'Giugno', value: 5 },
	{ key: 6, text: 'Luglio', value: 6 },
	{ key: 7, text: 'Agosto', value: 7 },
	{ key: 8, text: 'Settembre', value: 8 },
	{ key: 9, text: 'Ottobre', value: 9 },
	{ key: 10, text: 'Novembre', value: 10 },
	{ key: 11, text: 'Dicembre', value: 11 }
];

const getWeek = (day) => {
	let weekday = day.isoWeekday();
	let week = [];
	for (let index = 7; index > 0; index--) {
		let current = day.clone().add(index - weekday, 'days');
		week.push(current.clone());
	}
	return week;
};

class ToolBar extends Component {
	constructor(props) {
		super(props);

		let updateSearch = debounce(1000, false, (search) => {
			this.props.searchResult(search);
		});

		this.state = {
			search: '',
			updateSearch,
			date: new Date(),
			recapModal: false,
			minus: true,
			wait: true,
			year: moment().get('year'),
			month: moment().get('month')
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { searchData } = this.props;

		if (searchData !== prevProps.searchData && searchData !== this.state.search) {
			this.setState({
				search: searchData
			});
		}
	}

	componentDidMount() {
		this.props.searchResult('');
	}

	clearSearch() {
		this.setState({
			search: ''
		});
		this.props.searchResult('');
	}

	skipCheck(toCheck) {
		let skip = false;
		let skipList = [
			routeNames[app],
			routeNames[finalBalance],
			routeNames[jobEdit],
			routeNames[jobMerge],
			routeNames[workerEdit],
			routeNames[machineEdit],
			routeNames[costEdit],
			routeNames[costUpdate],
			routeNames[employee],
			routeNames[job],
			routeNames[newEl]
		];
		toCheck = toCheck.replace('/', '');

		for (let i in skipList) {
			let route = skipList[i].replace('/', '');
			if (route === toCheck) {
				skip = true;
				break;
			}
		}

		return skip;
	}

	handleInput(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	render() {
		let { search, recapModal, year, month, minus, wait } = this.state;
		let { network, history, location: { pathname }, isEmployee, loginEmployee } = this.props;
		let bcs = pathname.split('/');
		let breadcrumbs = [];
		let curpath = '';
		for (let i in bcs) {
			curpath += bcs[i];
			if (i < bcs.length - 1) {
				curpath += '/';
			}
			let skip = false;

			if (this.skipCheck(bcs[i])) {
				skip = true;
			}

			breadcrumbs.push({
				path: curpath,
				name: bcs[i],
				skip
			});
		}

		return (
			<Menu
				stackable
				borderless
				size="large"
				style={{
					zIndex: 43,
					borderRadius: 'unset',
					borderLeft: 'unset',
					borderTop: 'unset',
					borderRight: 'unset',
					margin: 0
				}}
			>
				<Menu.Item
					style={{ marginLeft: 60 }}
					name="arrow left"
					onClick={() => {
						history.goBack();
					}}
				>
					<Icon size="large" name="arrow left" />
				</Menu.Item>
				<Menu.Item
					content={
						<Breadcrumb size={'large'}>
							{breadcrumbs.map((value, index) => {
								let extraProps = {};
								if (!value.skip) {
									extraProps = {
										link: true,
										as: NavLink,
										to: value.path
									};
								}
								return (
									<span key={index}>
										{index !== 0 && <Breadcrumb.Divider />}
										<Breadcrumb.Section
											style={{ color: 'black' }}
											{...extraProps}
											active={index === breadcrumbs.length - 1}
										>
											{value.name}
										</Breadcrumb.Section>
									</span>
								);
							})}
							<Breadcrumb.Divider icon="right angle" />

							{search && <Breadcrumb.Divider icon="random" />}
							{search && <Breadcrumb.Section active>Ricerca: {search}</Breadcrumb.Section>}
						</Breadcrumb>
					}
				/>
				<Menu.Menu position="right">
					{!isEmployee && (
						<Menu.Item>
							<Input
								icon={
									this.state.search.length > 0 ? (
										<Icon name="delete" link onClick={() => this.clearSearch()} />
									) : (
										<Icon name="search" />
									)
								}
								placeholder="Cerca..."
								value={this.state.search}
								onChange={(e, data) => {
									let text = data.value;
									this.state.updateSearch(text);
									this.setState({ search: text });
								}}
							/>
						</Menu.Item>
					)}
					{!isEmployee && (
						<Menu.Item
							name="pdf"
							onClick={() => {
								this.setState({ recapModal: true });
							}}
						>
							<Icon size="large" name="file pdf outline" />
							<span>Riepilogo</span>
						</Menu.Item>
					)}
					{isEmployee && (
						<Menu.Item>
							<Header>
								{loginEmployee && loginEmployee.data.user ? loginEmployee.data.user.name : ''}
							</Header>
						</Menu.Item>
					)}
					{isEmployee && (
						<Menu.Item
							name="calendar"
							onClick={() => {
								this.props.history.replace(route_from(employee, insertion, calendarView));
							}}
						>
							<Icon size="large" name="calendar alternate outline" />
							<span>Calendario</span>
						</Menu.Item>
					)}
					<Menu.Item
						name="logout"
						onClick={() => {
							if (isEmployee) {
								network.logoutEmployee();
								history.replace(route_from(employee));
								return;
							}

							network.logout();
							history.replace(route_from(login));
						}}
					>
						<Icon size="large" name="sign out" />
					</Menu.Item>
				</Menu.Menu>

				<Modal open={recapModal}>
					<Modal.Header>Creazione Riepilogo</Modal.Header>
					<Modal.Content>
						<Form>
							<Form.Group widths="equal">
								<Form.Field
									id="form-input-control-recap-year"
									control={Input}
									type="number"
									label="Anno"
									placeholder="2019"
									step="1"
									name="year"
									value={year}
									onChange={(e) => this.handleInput(e)}
								/>
								<Form.Field
									id="form-input-control-job-type"
									control={Select}
									options={months}
									label="Mese"
									placeholder="Mese"
									name="month"
									value={month}
									onChange={(e, data) => {
										this.setState({ month: data.value });
									}}
								/>
							</Form.Group>
							<Form.Field
								control={Checkbox}
								label="Con sottrazione ore operaio dalle ore macchina"
								checked={minus}
								onChange={(e, data) => {
									console.log("Setting minus to", data.checked);
									this.setState({ minus: data.checked });
								}}
							/>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Button
							primary
							icon
							onClick={() => {
								this.setState({ wait: true, recapModal: false });
							}}
						>
							Annulla <Icon name="cancel" />
						</Button>
						<Button
							primary
							icon
							onClick={() => {
								this.setState({ wait: false });
							}}
						>
							Calcola <Icon name="computer" />
						</Button>
						<Recap
							year={year}
							month={month + 1}
							minus={minus}
							wait={wait}
							onDone={(data) => {
								console.log('On done called', data);
								this.setState({ wait: true });
							}}
							element={
								<Button
									primary
									icon
									onClick={() => {
										this.setState({ wait: true, recapModal: false });
									}}
								>
									Scarica <Icon name="download" />
								</Button>
							}
						/>
					</Modal.Actions>
				</Modal>
			</Menu>
		);
	}
}

/*
{!isEmployee && (
							<Popup trigger={<Icon size="large" name="calendar alternate outline" />} flowing hoverable>
								<Calendar onChange={(date) => this.setState({ date })} value={this.state.date} />
							</Popup>
	<Menu.Item
		size="large"
		name="emp_login"
		onClick={() => {
			network.logout()
			history.replace(route_from(vfconsole, pin))
		}}>
		<Icon name="terminal" />
		<span>Console</span>
	</Menu.Item>
)}
*/

const mapDispatchToProps = (dispatch) => {
	return {
		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

const mapStateToProps = (state, ownProps) => {
	let { login, loginEmployee, search: { data: searchData } } = state;

	return {
		login,
		loginEmployee,
		searchData
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(ToolBar))));
