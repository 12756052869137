import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { Button, Modal, Loader, Menu, Icon, Card, Segment, Dimmer } from 'semantic-ui-react';

import View from 'lib/View';

import { ImageDefault } from './';
import { buildFileSelector } from '../lib/util';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import loadingImage from '../assets/loadingParagraph.png';

class PickImage extends Component {
	constructor(props) {
		super(props);
		let id = props.id;

		this.state = {
			page: 0,
			items: 12,
			image: this.props.value ? this.props.value : '',
			fetching: true,
			images: [],
			showModal: false,
			fileSelector: buildFileSelector(this.handleUpload)
		};
	}

	componentDidMount() {
		let { page, items } = this.state;
		this.state.network.getImageList(page, items);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//console.log('PickImage checking', this.props.value, 'with', prevProps.value);
		if (this.props.value !== prevProps.value) {
			this.setState({
				image: this.props.value
			});
		}

		//console.log('Pick image updated', this.props.uploadImage);
		if (this.state.uploadImage !== prevState.uploadImage) {
			if (this.state.uploadImage.status.success) {
				this.pickImage(this.state.uploadImage.data.file);
				this.closeModal();
				return;
			}
		}

		if (this.state.page != prevState.page || this.state.items != this.state.items) {
			this.state.network.getImageList(this.state.page, this.state.items);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return nextProps;
	}

	pickImage(image) {
		let { onChange } = this.props;
		if (onChange) {
			onChange(image);
		}

		this.setState({
			image
		});
	}

	next() {
		this.setState({
			page: this.state.page + 1
		});
	}

	prev() {
		if (this.state.page === 0) return;

		this.setState({
			page: this.state.page - 1
		});
	}

	closeModal = () => {
		this.setState({ showModal: false });
	};

	uploadImage = () => {
		this.state.fileSelector.click();
	};

	handleUpload = (event, name) => {
		//console.log('Handle upload called');
		let obj = { loaded: 0 };
		obj[name] = event.target.files[0];
		this.setState(obj);
		//console.log('Selected file', event.target.files[0]);

		this.props.network.uploadImage(event.target.files[0]);
	};

	render() {
		let { fetching, images, image } = this.state;
		//console.log('Picking image from', images);
		return (
			<Modal
				onClose={this.closeModal}
				open={this.state.showModal}
				trigger={
					<View style={{ margin: 16 }} fullw between>
						<Button style={{ height: 64 }} onClick={() => this.setState({ showModal: true })}>
							Scegli Immagine
						</Button>
						<ImageDefault
							fromRoot
							style={{
								width: 200,
								height: 200,
								objectFit: 'cover'
							}}
							src={image}
						/>
					</View>
				}
				closeIcon
			>
				<Modal.Header>Scelta immagine</Modal.Header>
				<Modal.Content image scrolling style={{ padding: 0 }}>
					<View fullw column>
						<Menu horizontal style={{ borderRadius: '0px' }}>
							<Menu.Item
								as="a"
								icon="upload"
								name="Carica immagine"
								onClick={this.uploadImage}
								position={'left'}
							/>
							<Menu.Item
								as="a"
								icon="arrow left"
								onClick={() => {
									this.prev();
								}}
								position={'right'}
							/>
							<Menu.Item
								as="a"
								icon="arrow right"
								onClick={() => {
									this.next();
								}}
								position={'none'}
							/>
						</Menu>
						<Modal.Description style={{ maxWidth: '100%' }}>
							{!fetching && (
								<View fullw wrap row around style={{ padding: 16 }}>
									{images &&
										images.map((value, index) => (
											<Card
												key={index}
												onClick={() => {
													this.pickImage(value.immagine);
												}}
												style={{
													width: '200px',
													height: '200px',
													marginTop: 16,
													marginBottom: 16
												}}
											>
												<ImageDefault
													fromRoot
													style={{ width: '100%', height: '100%', objectFit: 'cover' }}
													src={value.immagine}
													ui={false}
												/>
												{this.state.image === value.immagine && (
													<div
														style={{
															position: 'absolute',
															top: 0,
															bottom: 0,
															left: 0,
															right: 0,
															background: 'rgba(99, 177, 255, 0.60)',
															zIndex: 2
														}}
													/>
												)}
											</Card>
										))}
								</View>
							)}
							{fetching && (
								<Segment style={{ minHeight: '600px' }}>
									<Dimmer active inverted>
										<Loader inverted />
									</Dimmer>
								</Segment>
							)}
						</Modal.Description>
					</View>
				</Modal.Content>
				<Modal.Actions>
					<Button primary onClick={this.closeModal}>
						Ok <Icon name="check" />
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let { getImageList: { data: { data }, fetching }, uploadImage } = state;

	return {
		images: data,
		fetching,
		uploadImage
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(PickImage))));
