import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import {} from 'semantic-ui-react';

import View from 'lib/View';
import { Machine } from '../components';

import { Placeholder, Card } from 'semantic-ui-react';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import { route_from, routes } from 'lib/routes';
const { app, machines, machineEdit } = routes;

class Machines extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			items: 10,
			selected: this.props.selected ? this.props.selected : {}
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return nextProps;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { deleteMachine } = this.state;
		let { isEmployee, workerId } = this.props;
		if (
			this.state.page != prevState.page ||
			this.state.items != this.state.items ||
			(deleteMachine.fetching !== prevState.deleteMachine.fetching && !deleteMachine.fetching)
		) {
			if (isEmployee) {
				this.state.network.getRecentMachineList(this.state.search.data, workerId, isEmployee);
			} else {
				this.state.network.getMachineList(this.state.search.data, isEmployee);
			}
		} else if (this.state.search != prevState.search) {
			if (isEmployee) {
				this.state.network.getRecentMachineList(this.state.search.data, workerId, isEmployee);
			} else {
				this.state.network.getMachineList(this.state.search.data, isEmployee);
			}
		}
	}

	next() {
		this.setState({
			page: this.state.page + 1
		});
	}

	prev() {
		if (this.state.page === 0) return;

		this.setState({
			page: this.state.page - 1
		});
	}

	componentDidMount() {
		let { isEmployee, sub, workerId } = this.props;
		if (!sub) {
			this.props.toolbarResult([
				{
					name: 'Crea nuova macchina',
					icon: 'add',
					action: () => {
						this.props.history.push(route_from(app, machines, machineEdit));
					}
				},
				{
					name: '',
					icon: 'arrow left',
					position: 'right',
					action: () => {
						this.prev();
					}
				},
				{
					name: '',
					icon: 'arrow right',
					position: 'none',
					action: () => {
						this.next();
					}
				}
			]);
		}

		if (isEmployee) {
			this.state.network.getRecentMachineList(this.state.search.data, workerId, isEmployee);
		} else {
			this.state.network.getMachineList(this.state.search.data, isEmployee);
		}
	}

	renderMachine(key, machine) {
		let { selected } = this.state;
		let { onChange, selectable, noActions, showNoMachine } = this.props;

		let extraProps = {};
		if (selectable) {
			extraProps.onClick = (machine) => {
				if (onChange) {
					onChange(machine);
				}
				this.setState({ selected: machine });
			};
		}

		if (noActions) {
			extraProps.noActions = true;
		}

		if (showNoMachine) {
			extraProps.showNoMachine = true;
		}

		return (
			<Machine
				{...extraProps}
				selected={machine.idMacchina === selected.idMacchina}
				type="full"
				key={`machine_${key}`}
				machine={machine}
			/>
		);
	}

	render() {
		let { machines, fetching } = this.state;
		let { onChange, selectable, selected, noActions, ...rest } = this.props;
		let content = <div />;

		if (fetching) {
			let placeholders = [];
			for (let i = 0; i < 10; i++) {
				placeholders.push(
					<Card key={i} style={{ width: '100%', margin: 8 }}>
						<Card.Content>
							<Placeholder>
								<Placeholder.Header image>
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Header>
								<Placeholder.Paragraph>
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Paragraph>
							</Placeholder>
						</Card.Content>
					</Card>
				);
			}
			content = (
				<View fullw column>
					{placeholders}
				</View>
			);
		} else if (machines.data) {
			content = Object.keys(machines.data).map((key) => {
				let value = machines.data[key];
				return this.renderMachine(key, value);
			});
		}

		return (
			<View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
				{content}
			</View>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let { getMachineList, getRecentMachineList, deleteMachine, search } = state;
	let { isEmployee } = ownProps;

	let machineList = getMachineList;
	if (isEmployee) {
		machineList = getRecentMachineList;
	}
	return {
		machines: machineList.data,
		fetching: machineList.fetching,
		deleteMachine,
		search
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(Machines))));
