import { types, actions } from './requests'
export const LOGIN_TYPES = {
	...types('LOGIN'),
	logout: 'LOGIN_LOGOUT'
}
export const LOGIN_ACTIONS = {
	...actions('LOGIN'),
	logout: (result, dispatch) =>
		dispatch({
			type: LOGIN_TYPES.logout
		})
}
