export function getMappingData(isEmployee) {
	let { getMappingDataRequest, getMappingDataError, getMappingDataResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_mapping_list',
		this.createBundle(
			'api/get_mapping_list',
			{ },
			this.GET,
			getMappingDataRequest,
			getMappingDataResult,
			getMappingDataError,
			!!isEmployee
		)
	);
}
