export function getWorkList(page, items, search, jobId, dateRange, minus) {
	let { getWorkListRequest, getWorkListError, getWorkListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_work_list',
		this.createBundle(
			'api/get_work_list',
			{ page, items, search, jobId, dateRange, minus },
			this.GET,
			getWorkListRequest,
			getWorkListResult,
			getWorkListError
		)
	);
}
