export function getGT2000Data(start, end) {
	let { getGT2000DataRequest, getGT2000DataError, getGT2000DataResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_gt2000_data',
		this.createBundle(
			'api/get_gt2000_data',
			{ start, end },
			this.POST,
			getGT2000DataRequest,
			getGT2000DataResult,
			getGT2000DataError
		)
	);
}
