import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { connectNetwork } from 'lib/NetworkProvider';

import { } from 'semantic-ui-react';

import View from 'lib/View';
import { Item } from '../components';

import { Placeholder, Card } from 'semantic-ui-react';

import { TOOLBAR_ACTIONS } from '../actions/toolbar';
import { SEARCH_ACTIONS } from '../actions/search';

import { route_from, routes } from 'lib/routes';
const { app, warehouse, warehouseEdit } = routes;

class Warehouse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			items: 10,
			cached: false,
			selected: this.props.selected ? this.props.selected : {}
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let { page, items, cached } = this.state;
		let { deleteItem, search, itemList, fetching, isEmployee, workerId } = this.props;
		let list = []
		if (cached ||
			(this.state.page !== prevState.page ||
				this.state.items !== prevState.items) ||
			((fetching !== prevProps.fetching) && !fetching && itemList.data)
			// (deleteItem.fetching !== prevState.deleteItem.fetching && !deleteItem.fetching)
		) {
			// console.log("DID UPDATE", this.state.page, prevState.page, this.state.items, prevState.items, fetching, prevState.fetching
			// network.getItemList(this.state.search.data, isEmployee);
			list = itemList.data
			let result = list.filter(o => {
				if (search.data == "") {
					return true
				} else {
					let found = false
					for (let key in o) {
						if (o[key] && o[key].includes) {
							found = found || o[key].toLowerCase().includes(search.data.toLowerCase())
						}
					}
					return found
				}
			});
			list = result.slice(page * items, page * items + items)
			let resulting = {}
			for (let i in list) {
				resulting[list[i].itemcode] = list[i]
			}
			this.setState({
				list: resulting,
				cached: false
			})
		} else if (search.data !== prevProps.search.data && itemList.data) {
			// network.getItemList(this.state.search.data, isEmployee);
			list = itemList.data
			let result = list.filter(o => {
				if (search.data == "") {
					return true
				} else {
					let found = false
					for (let key in o) {
						if (o[key] && o[key].includes) {
							found = found || o[key].toLowerCase().includes(search.data.toLowerCase())
						}
					}
					return found
				}
			});
			list = result.slice(0, items)
			let resulting = {}
			for (let i in list) {
				resulting[list[i].itemcode] = list[i]
			}
			this.setState({
				page: 0,
				list: resulting
			})
		}
	}

	next() {
		this.setState({
			page: this.state.page + 1
		});
	}

	prev() {
		if (this.state.page === 0) return;

		this.setState({
			page: this.state.page - 1
		});
	}

	componentDidMount() {
		let { isEmployee, sub, network, search, itemList } = this.props;
		if (!sub) {
			this.props.toolbarResult([
				// {
				// 	name: 'Crea nuovo oggetto',
				// 	icon: 'add',
				// 	action: () => {
				// 		this.props.history.push(route_from(app, warehouse, warehouseEdit));
				// 	}
				// },
				{
					name: '',
					icon: 'arrow left',
					position: 'right',
					action: () => {
						this.prev();
					}
				},
				{
					name: '',
					icon: 'arrow right',
					position: 'none',
					action: () => {
						this.next();
					}
				}
			]);
		}

		console.log("Warehouse componentDidMount", itemList)
		if (!itemList.data) {
			network.getItemList(search.data, isEmployee);
		} else {
			this.setState({ cached: true })
		}
	}

	renderItem(key, item) {
		let { selected } = this.state;
		let { onChange, selectable, noActions, showNoItem } = this.props;

		let extraProps = {};
		if (selectable) {
			extraProps.onClick = (item) => {
				if (onChange) {
					onChange(item);
				}
				this.setState({ selected: item });
			};
		}

		if (noActions) {
			extraProps.noActions = true;
		}

		if (showNoItem) {
			extraProps.showNoItem = true;
		}

		return (
			<Item
				{...extraProps}
				selected={item.itemcode === selected.itemcode}
				type="full"
				key={`item_${key}`}
				item={item}
			/>
		);
	}

	render() {
		let { items, page, list } = this.state;
		let { fetching, onChange, selectable, selected, noActions, ...rest } = this.props;
		let content = <div />;

		if (fetching) {
			let placeholders = [];
			for (let i = 0; i < 10; i++) {
				placeholders.push(
					<Card key={i} style={{ width: '100%', margin: 8 }}>
						<Card.Content>
							<Placeholder>
								<Placeholder.Header image>
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Header>
								<Placeholder.Paragraph>
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Paragraph>
							</Placeholder>
						</Card.Content>
					</Card>
				);
			}
			content = (
				<View fullw column>
					{placeholders}
				</View>
			);
		} else if (list) {
			content = Object.keys(list).map((key) => {
				let value = list[key];
				return this.renderItem(key, value);
			});
		}

		return (
			<View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
				{content}
			</View>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let { getItemList, deleteItem, search } = state;
	let itemList = getItemList;

	return {
		list: [],
		itemList: itemList.data,
		fetching: itemList.fetching,
		deleteItem,
		search
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
		toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
		toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

		searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
		searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
		searchError: (result) => SEARCH_ACTIONS.error(result, dispatch)
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radium(connectNetwork(Warehouse))));
