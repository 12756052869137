export function getPagedJobList(data) {
	let { getPagedJobListRequest, getPagedJobListError, getPagedJobListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'api/get_paged_job_list',
		this.createBundle(
			'api/get_paged_job_list',
			{ data: data },
			this.GET,
			getPagedJobListRequest,
			getPagedJobListResult,
			getPagedJobListError
		)
	);
}
